import * as React from "react";

const CrispChat: React.FC = () => {
  React.useEffect(() => {
    (window as any).$crisp = [];
    (window as any).CRISP_WEBSITE_ID = "1b12aa40-97e5-44cc-beea-5702cea70b2f";
    (function () {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);
  return null;
};
CrispChat.displayName = "CrispChat";
export default CrispChat;
