/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import React from "react";

import Typography from "@material-ui/core/Typography";
import { Box, Link } from "@material-ui/core";
import { ToSDialog } from "components/general/ToS";

const Hero: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <Box sx={{ pl: [30, , 0], pr: [30, 0] }}>
      <Typography
        component="h1"
        variant="h2"
        sx={{
          color: "#EF7B2E",
        }}
      >
        Connect your AWS Account
      </Typography>
      <Typography sx={{ mt: 3 }}>
        Please review
        <Link
          component="button"
          variant="body1"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setOpen(true);
          }}
          sx={{ ml: 2 }}
        >
          Terms of Service.
        </Link>
      </Typography>
      <ToSDialog open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};
Hero.displayName = "Signup Hero";
export default Hero;
