/** @jsxImportSource theme-ui */
import Grid from "@material-ui/core/Grid";
import SigninForm from "components/signinup/SigninForm";
import SignupForm from "components/signinup/SignupForm";
import { TabBar, TabPanel } from "components/signinup/TabBar";
import React, { useState } from "react";

interface Props {
  handleForgotPassword: () => void;
  variant?: string;
}
const SignInUpWrapper: React.FC<Props> = ({
  handleForgotPassword,
  variant,
}) => {
  const computeVariant = variant === "signup" ? 2 : 0;
  const [tabValue, setTabValue] = useState<number>(computeVariant);
  const onTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid item sm={6}>
        <TabBar tabValue={tabValue} onTabChange={onTabChange} />
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          <SigninForm handleForgotPassword={handleForgotPassword} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <SignupForm />
        </TabPanel>
      </Grid>
    </>
  );
};
SignInUpWrapper.displayName = `SignInUpWrapper`;
export default SignInUpWrapper;
