/** @jsxImportSource theme-ui */
import { Button, Grid } from "@material-ui/core";
import * as React from "react";
import FilterInput from "./FilterInput";

type Props = {
  className?: string;
};

const DashboardFilters: React.FC<Props> = () => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: [, , , "flex-end"],
      }}
    >
      <Grid item>
        <FilterInput
          label="AWS Account ID"
          options={[{ value: "account-id-1", label: "account-id-1" }]}
          sx={{ width: 185 }}
        />
      </Grid>
      <Grid item>
        <FilterInput
          label="Playbook ID"
          options={[{ value: "playbook-id-1", label: "playbook-id-1" }]}
          sx={{ width: 155 }}
        />
      </Grid>
      <Grid item>
        <FilterInput
          label="Business Unit"
          options={[
            { value: "business-unit-id-1", label: "business-unit-id-1" },
          ]}
          sx={{ width: 165 }}
        />
      </Grid>
      <Grid item>
        <FilterInput
          label="Level"
          options={[{ value: "level-id-1", label: "level-id-1" }]}
          sx={{ width: 95 }}
        />
      </Grid>
      <Grid item>
        <FilterInput
          label="Status"
          options={[{ value: "status-id-1", label: "status-id-1" }]}
          sx={{ width: 105 }}
        />
      </Grid>
      <Grid item>
        <FilterInput
          label="Environment"
          options={[{ value: "level-id-1", label: "level-id-1" }]}
          sx={{ width: 160 }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#2F80ED" }}
          size="large"
          sx={{ height: 56, letterSpacing: "0.46px" }}
        >
          Apply Filters
        </Button>
      </Grid>
      <Grid item>
        <Button
          // variant="contained"
          color="primary"
          style={{ color: "#0990CB" }}
          size="large"
          sx={{ height: 56, letterSpacing: "0.46px" }}
        >
          Clear All Filters
        </Button>
      </Grid>
    </Grid>
  );
};
DashboardFilters.displayName = "DashboardFilters";
export default DashboardFilters;
