/** @jsxImportSource theme-ui */
import Title from "components/general/Title";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Block from "components/general/Block";
import { Divider, Grid, Typography } from "@material-ui/core";
import ListBlock from "components/general/ListBlock";
import Banner from "components/opportunity/Banner";
import AssigneeBlock from "components/opportunity/Assignee";

import ProgressStepperWrapper from "components/opportunity/ProgressStepperWrapper";
import { OpportunityStatus } from "components/opportunity/opportunityConstants";

interface MatchParams {
  id: string;
}
type Props = RouteComponentProps<MatchParams>;
const AccountInformation = [
  {
    key: "Business Unit",
    value: "Ipsum Company",
  },
  {
    key: "AWS Account Name",
    value: "Company Dec Account 1",
  },
  {
    key: "CO Level",
    value: "L2 - With Downtime",
  },
  {
    key: "Environment",
    value: "NON PROD",
  },
  {
    key: "AWS Region",
    value: "eu-west-1",
  },
  {
    key: "Service Type",
    value: "RDS",
  },
];
const Dates = [
  {
    key: "Created",
    value: "Yesterday 3:57 PM",
  },
  {
    key: "Updated",
    value: "Yesterday 3:57 PM",
  },
  {
    key: "Scheduled Change Start Date",
    value: "Yesterday 3:57 PM",
  },
  {
    key: "Environment",
    value: "NON PROD",
  },
];
const BeforeOptimization = [
  { key: "Current Type:", value: "g4dn.2xlarge" },
  {
    key: "Current Cost (Annualized):",
    value: "$3,623",
  },
];
const DatesUsedForOptimization = [
  {
    key: "Measure Period",
    value: "14 Days",
  },
  {
    key: "CPU Buffer Percentage",
    value: "10%",
  },
  {
    key: "IO Buffer Percentage",
    value: "10%",
  },
  {
    key: "Memory Buffer Percentage",
    value: "10%",
  },
  {
    key: "Statistics Period",
    value: "10%",
  },
  {
    key: "Statistics Measure Type",
    value: "Maximum",
  },
];
const Faqs = [
  {
    key: "Worst case scenario customer impact upon task execution?",
    value:
      "This playbook necessitates a small downtime. In addition, there is a small possibility that following a successful launch of the instance there will be service degradation due to a change in utilization patterns occurring after the playbook was run.",
  },
  {
    key: "What are the chances of this worst-case scenario?",
    value: "Downtime - High (100%) Degradation - Low (<5%)",
  },
  {
    key: "How much time on average will the impact be felt?",
    value: "5 min for restart.",
  },
];
const ResourceDetails = [
  {
    key: "Measure Period",
    value: "14 Days",
  },
  {
    key: "CPU Buffer Percentage",
    value: "10%",
  },
  {
    key: "IO Buffer Percentage",
    value: "10%",
  },
  {
    key: "Memory Buffer Percentage",
    value: "10%",
  },
  {
    key: "Statistics Period",
    value: "10%",
  },
  {
    key: "Statistics Measure Type",
    value: "Maximum",
  },
];
const aggregatedItems = [
  {
    label: "Cost (annualized)",
    value: 3623,
    variant: "money",
  },
  {
    label: "Cost Savings",
    value: 2850,
    variant: "money",
  },
  {
    label: "Resource ID",
    value: "i-0f13fa798d70223cc",
    variant: "text",
  },
  {
    label: "Account ID",
    value: "JivesITOps (011880796339)",
    variant: "text",
  },
];
const users = [
  { id: "1", name: "Tomas Caraccia" },
  {
    id: "2",
    name: "Abdullah Hilson",
  },
  {
    id: "3",
    name: "Janko Micic",
  },
];
const OpportunityPage: React.FC<Props> = ({ match }) => {
  const {
    params: { id },
  } = match;
  const opportunity = {
    created: "",
    upated: "",
    schedule_start: "",
    schedule_end: "",
    description:
      "Checks for non-spot EC2 instances and tries to find the cheapest alternative which can handle the workload while providing a buffer for usage spikes.  Downsizing underutilized EC2 instances to meet the capacity needs at the lowest cost represents an efficient strategy to reduce your monthly AWS costs.  This change will require downtime.",
    account: {
      bussiness_unit: "Ipsum Company",
      name: "Company Dec Account 1",
      co_level: "L2 - With Downtime",
      environment: "NON PROD",
      region: "eu-west-1",
      service_type: "RDS",
    },
  };

  const handleAssigneeSelect = () => null;
  const opportunityStatus = OpportunityStatus.Pending;
  const opportunityError = undefined;
  const isAdmin = false;
  return (
    <>
      <Title>Underutilized ECS Instances</Title>
      <Banner items={aggregatedItems} sx={{ my: 2 }} />
      <ProgressStepperWrapper
        sx={{ py: 10 }}
        error={opportunityError}
        isAdmin={isAdmin}
        status={opportunityStatus}
      />
      <Grid container spacing={2} sx={{ display: "flex" }}>
        <Grid item xs={8}>
          <Block title="Opportunity Description">
            <Typography sx={{ m: 4, mr: 6, mt: 2 }}>
              {opportunity.description}
            </Typography>
          </Block>
        </Grid>
        <Grid item xs={4}>
          <Block title="Assignee" sx={{ display: "flex" }}>
            <AssigneeBlock
              users={users}
              onAcceptAssignee={handleAssigneeSelect}
            />
          </Block>
        </Grid>
        <Grid item xs={8}>
          <Block title="Account Information">
            <ListBlock items={AccountInformation} />
          </Block>
        </Grid>
        <Grid item xs={4}>
          <Block title="Dates">
            <ListBlock
              keyProps={{
                xs: 4,
                sx: {
                  fontWeight: 400,
                  color: "#525252",
                  fontSize: "14px",
                },
              }}
              valueProps={{ xs: 8, sx: { fontSize: "16px" } }}
              items={Dates}
            />
          </Block>
        </Grid>
        <Grid item xs={8}>
          <Block title="Opportunity Summary">
            <Grid container sx={{ py: 4 }}>
              <Grid item xs={6} sx={{ pl: 3, pr: 2 }}>
                <Block
                  title="Before Optimization"
                  titleProps={{
                    sx: {
                      py: 3,
                      color: "#333333",
                      fontSize: 16,
                      fontWeight: 500,
                    },
                  }}
                >
                  <ListBlock
                    keyProps={{
                      xs: 6,
                      sx: {
                        fontWeight: 400,
                        color: "#525252",
                        fontSize: "14px",
                      },
                    }}
                    valueProps={{
                      xs: 6,
                      sx: {
                        color: "0B1117",
                        fontWeight: 500,
                      },
                    }}
                    items={BeforeOptimization}
                  />
                </Block>
              </Grid>
              <Grid item xs={6} sx={{ pl: 2, pr: 3 }}>
                <Block
                  title="After Optimization"
                  titleProps={{
                    sx: {
                      py: 3,
                      color: "#333333",
                      fontSize: 16,
                      fontWeight: 500,
                    },
                  }}
                >
                  <Grid container sx={{ px: 4, py: 3 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        fontWeight: 400,
                        color: "#525252",
                        fontSize: "14px",
                      }}
                    >
                      New Type:
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      r5.xlarge
                    </Grid>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        fontWeight: 400,
                        color: "#525252",
                        fontSize: "14px",
                      }}
                    >
                      Current Cost (Annualized):
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        fontWeight: 500,
                        color: "#369D47",
                        fontSize: "24px",
                      }}
                    >
                      $773
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Block>
              </Grid>
            </Grid>
          </Block>
        </Grid>
        <Grid item xs={4}>
          <Block title="Dates used for Optimization" accordion>
            <ListBlock
              label={{
                key: "Name",
                value: "Value",
              }}
              keyProps={{ xs: 8, sx: { fontWeight: 500, fontsize: "14px" } }}
              valueProps={{ xs: 4, sx: { fontWeight: 500, fontsize: "14px" } }}
              divider
              items={DatesUsedForOptimization}
            />
          </Block>
        </Grid>
        <Grid item xs={8}>
          <Block title="FAQs" accordion>
            <ListBlock
              label={{
                key: "Question",
                value: "Answer",
              }}
              keyProps={{
                xs: 6,
                sx: {
                  fontWeight: 400,
                  fontsize: "14px",
                  color: "525252",
                },
              }}
              valueProps={{ xs: 6, sx: { fontWeight: 500, fontsize: "14px" } }}
              divider
              items={Faqs}
            />
          </Block>
        </Grid>
        <Grid item xs={4}>
          <Block title="Resource Details" accordion>
            <ListBlock
              label={{
                key: "Name",
                value: "Value",
              }}
              keyProps={{ xs: 8, sx: { fontWeight: 500, fontsize: "14px" } }}
              valueProps={{ xs: 4, sx: { fontWeight: 500, fontsize: "14px" } }}
              divider
              items={ResourceDetails}
            />
          </Block>
        </Grid>
        <Grid item xs={8}>
          <Block title="Activity History">
            <Typography sx={{ m: 4, mr: 6, mt: 2 }}>
              No Activity History on this issue.
            </Typography>
          </Block>
        </Grid>
      </Grid>
    </>
  );
};
OpportunityPage.displayName = "Opportunity Page";
export default OpportunityPage;
