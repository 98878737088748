import { Pagination } from "@material-ui/lab";
import React from "react";

interface Props {
  onPaginate: (event: React.ChangeEvent<unknown>, page: number) => void;
  total: number;
  page: number;
}
const CustomPagination: React.FC<Props> = ({ total, page, onPaginate }) => {
  return (
    <Pagination
      color="primary"
      count={total}
      page={page + 1}
      onChange={onPaginate}
    />
  );
};
export default CustomPagination;
