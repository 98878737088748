/** @jsxImportSource theme-ui */
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { User } from "generated/graphql";
import React from "react";

interface Props {
  onAcceptAssignee: (user: Partial<User>) => void;
  users: Partial<User>[];
}
const AssigneeBlock: React.FC<Props> = ({ onAcceptAssignee, users }) => {
  const [selected, setSelected] = React.useState<Partial<User> | null>(null);
  const [inputValue, setInputValue] = React.useState<string | undefined>(
    "Unknown"
  );
  const handleChange = (
    event: React.ChangeEvent<any>,
    value: Partial<User> | null
  ) => {
    setSelected(value);
  };
  return (
    <Grid container sx={{ mx: 4, my: 2 }}>
      <Grid item xs={4}>
        <Typography
          sx={{
            mb: 3,
            mt: 3,
            fontSize: "14px",
            fontWeight: 400,
            color: "#525252",
          }}
        >
          Assignee:
        </Typography>
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          color="primary"
          onClick={() => onAcceptAssignee(selected as Partial<User>)}
        >
          Assign
        </Button>
      </Grid>
      <Grid item xs={8} sx={{ pr: 5 }}>
        <Box display="flex" flexDirection="column">
          <Autocomplete
            id="assignee-select"
            data-testid="assignee-select"
            value={selected}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newValue) => {
              setInputValue(newValue);
            }}
            autoHighlight
            options={users}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Unassigned"
              />
            )}
            getOptionLabel={(user: Partial<User>) => user.name as string}
          />
          <Button variant="text" color="primary" sx={{ mb: 3 }}>
            Assign to me
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
AssigneeBlock.displayName = "Assignee Block";
export default AssigneeBlock;
