/** @jsxImportSource theme-ui */
import { Box, CircularProgress } from "@material-ui/core";
import * as React from "react";

type Props = {
  className?: string;
};

const FullScreenLoader: React.FC<Props> = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <CircularProgress size={40} color="primary" />
    </Box>
  );
};
FullScreenLoader.displayName = "FullScreenLoader";
export default FullScreenLoader;
