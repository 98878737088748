/** @jsxImportSource theme-ui */

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import React from "react";
import { CompanyFFragment } from "generated/graphql";

interface FormValues {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  contact: string;
}
interface Props {
  // Should be company fragment but it doesn't has required props
  company: any;
  closeModal: () => void;
}
const CompanyForm: React.FC<Props> = ({ company, closeModal }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      address1: company?.address1 || "",
      address2: company?.address2 || "",
      city: company?.city || "",
      state: company?.state || "",
      zip: company?.zip || "",
      contact: company?.contact || "",
    },
  });
  const onSubmit = () => {
    // call mutation
  };
  return (
    <Box component="form" onSubmit={onSubmit} sx={{ px: 2, pb: 3 }}>
      <TextField
        id="company-form-address1"
        name="address1"
        label="Address 1"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />
      <TextField
        id="company-form-address2"
        name="address2"
        label="Address 2"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />
      <TextField
        id="company-form-city"
        name="city"
        label="City"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />
      <TextField
        id="company-form-state"
        name="state"
        label="State"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />
      <TextField
        id="company-form-zip"
        name="zip"
        label="Zip"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />
      <TextField
        id="company-form-contact"
        name="contact"
        label="Primary Contact"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />

      <Divider sx={{ mt: 4, mx: -4 }} />
      <Box sx={{ mt: 4 }}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          sx={{ width: 90 }}
        >
          Update
        </Button>
        <Button
          color="default"
          variant="contained"
          sx={{ ml: 3, width: 90 }}
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
CompanyForm.displayName = "CompanyForm";
export default CompanyForm;
