import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Controller } from "react-hook-form";
import * as React from "react";

type ReactHookFormSelectProps = {
  name: string;
  label: string;
  labelId?: string;
  control: any;
  defaultValue: string;
  required?: boolean;
  [key: string]: any;
};

const ReactHookFormSelect: React.FC<ReactHookFormSelectProps> = ({
  name,
  labelId = `${name}-label`,
  label,
  control,
  defaultValue,
  children,
  required,
  ...props
}) => {
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select labelId={labelId} label={label}>
            {children}
          </Select>
        }
        required={required}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
