const mapStatus = (status: string | undefined | null): string => {
  switch (status) {
    case "Pending Approval":
    case "pending":
      return "pending";
    case "Scheduled":
    case "scheduled":
      return "scheduled";
    case "in progress":
      return "in progress";
    case "Failure":
    case "failed":
      return "failed";
    case "PCA Rejected":
    case "EX Rejected":
    case "rejected":
      return "rejected";
    case "Success":
    case "success":
      return "success";
    default:
      return "unknown";
  }
};

export default mapStatus;
