/** @jsxImportSource theme-ui */
import { Box, Button, Typography } from "@material-ui/core";
import * as React from "react";
import diagnosticCtaBg from "assets/diagnostic-cta-bg.png";
import ContactModal from "../general/ContactModal";

type Props = {
  className?: string;
  opCount?: number;
};

const DiagnosticCTA: React.FC<Props> = ({ className, opCount }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${diagnosticCtaBg})`,
        pt: 125,
        pb: 137,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          mb: 80,
          maxWidth: ["95%", 1000],
          mx: "auto",
        }}
        className={className}
      >
        <Typography
          variant="h2"
          sx={{
            mb: 32,
            fontWeight: 600,
            fontSize: 42,
            lineHeight: "40px",
            color: "#EF7B2E",
          }}
        >
          Ready to automatically optimize your cloud?
        </Typography>
        <Typography
          sx={{
            mb: 24,
            color: "#414D56",
            fontSize: 24,
            lineHeight: "32px",
          }}
        >
          {`This is just a small sample of the ${opCount} opportunities that we found.
          Our team is available to discuss the full set of recommendations in detail
          and get you started on the journey to an optimized cloud.`}
        </Typography>
        <Typography
          sx={{
            mb: 60,
            color: "#414D56",
            fontSize: 24,
            lineHeight: "32px",
          }}
        >
          <b>Still have questions?</b> Connect with a member from our team now
          with our Live Chat or click the button below.
        </Typography>
        <ContactModal
          renderButton={({ onClick, success }) => {
            return (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#EF7B2E",
                  color: "white",
                }}
                onClick={onClick}
                disabled={success}
              >
                Please contact me
              </Button>
            );
          }}
        />
      </Box>
    </Box>
  );
};
DiagnosticCTA.displayName = "DiagnosticCTA";
export default DiagnosticCTA;
