/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import parse from "date-fns/parse";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  onApprove: (props: {
    startDate: string;
    endDate: string;
    comment: string;
  }) => void;
}
const AcceptModal: React.FC<Props> = ({ open, onClose, onApprove }) => {
  const now = React.useMemo(() => new Date(), []);
  const dtFormat = "yyyy-MM-dd'T'HH:mm";

  const [startDate, setStartDate] = React.useState<string>(
    format(now, dtFormat)
  );
  const [endDate, setEndDate] = React.useState<string>(
    format(addMinutes(now, 30), dtFormat)
  );

  const [comment, setComment] = React.useState<string>("");
  const handleApprove = () => {
    const props = { startDate, endDate, comment };
    onApprove(props);
  };
  const minEndDate = React.useMemo(() => {
    return format(addMinutes(parse(startDate, dtFormat, now), 30), dtFormat);
  }, [now, startDate]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h5">Approval</Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mx: -3 }} />
      </DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Grid container direction="row" spacing={5}>
          <Grid item container>
            <Grid item xs={4}>
              <Typography
                sx={{
                  color: "#525252",
                }}
              >
                Scheduled Change
                <br /> Start Date
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="startDate"
                id="startdatetime-local"
                type="datetime-local"
                variant="outlined"
                color="primary"
                defaultValue={format(now, dtFormat)}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  inputProps: {
                    min: format(now, dtFormat),
                  },
                }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setStartDate(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={4}>
              <Typography
                sx={{
                  color: "#525252",
                }}
              >
                Scheduled Change
                <br /> End Date
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                id="enddatetime-local"
                type="datetime-local"
                color="primary"
                defaultValue={format(addMinutes(now, 30), dtFormat)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setEndDate(event.target.value)}
                InputProps={{
                  inputProps: {
                    min: minEndDate,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={4}>
              <Typography
                sx={{
                  color: "#525252",
                }}
              >
                Comment
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={comment}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setComment(event.target.value)}
                aria-label="empty textarea"
                placeholder=""
                multiline
                variant="outlined"
                rows={12}
                sx={{ width: "400px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Box>
        <Divider sx={{ mx: 1 }} />
        <Button
          color="primary"
          variant="contained"
          sx={{ my: 3, mx: 3, textTransform: "none" }}
          onClick={handleApprove}
        >
          Approve with Downtime
        </Button>
        <Button
          color="primary"
          variant="outlined"
          sx={{ my: 3, mx: 3 }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};

AcceptModal.displayName = "Accept Modal";
export default AcceptModal;
