import { red } from "@material-ui/core/colors";

export default {
  breakpoints: ["960px", "1280px", "1600px", "1920px"],
  fonts: {
    body: "Montserrat, sans-serif",
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#2F80ED",
    secondary: "#AE2673",
    error: red.A400,
    errorBackground: "#F8D0C9",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};
