export interface GraphqlError {
  locations: Array<{ column: string; line: string }>;
  message: string;
  path: string[];
}

export type ResponseErrorData =
  | {
      networkError: string;
    }
  | { serverError: Response }
  | { graphqlErrors: GraphqlError[] };

// https://stackoverflow.com/a/41429145/1673761
export class ResponseError extends Error {
  constructor(data: ResponseErrorData) {
    let message;
    if ("networkError" in data) {
      message = data.networkError;
      super(message);
      this.networkError = message;
    } else if ("serverError" in data) {
      message = data.serverError.statusText;
      super(message);
      this.serverError = data.serverError;
    } else {
      message = data.graphqlErrors[0]?.message;
      super(message);
      this.graphqlErrors = data.graphqlErrors;
    }

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ResponseError.prototype);
  }

  graphqlErrors?: GraphqlError[];

  serverError?: Response;

  networkError?: string;
}
