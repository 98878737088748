/** @jsxImportSource theme-ui */
import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";

type Props = {
  className?: string;
  title: string;
  action?: ReactNode;
};

const DashboardCard: React.FC<Props> = ({
  className,
  title,
  children,
  action,
}) => {
  return (
    <Box
      className={className}
      sx={{
        border: "1px solid #D9D9D9",
        borderRadius: 8,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          pl: 4,
          pr: action ? 0 : 4,
          borderBottom: "1px solid #D9D9D9",
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
        }}
        justifyContent="space-between"
        display="flex"
      >
        <Typography
          variant="h3"
          sx={{
            py: 3,
            fontSize: 16,
            fontWeight: 600,
            color: "#003E7C",
            letterSpacing: "0.15px",
          }}
        >
          {title}
        </Typography>
        <Box>{action || null}</Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
DashboardCard.displayName = "DashboardCard";
export default DashboardCard;
