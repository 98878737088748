/** @jsxImportSource theme-ui */
import * as React from "react";
import {
  Cell,
  Customized,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import CATEGORIES_COLORS from "utils/categoriesColors";

export type PieChartData = Array<{ name: string; value: number }>;

const RADIAN = Math.PI / 180;

type Props = {
  className?: string;
  data: PieChartData;
};
interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}
const renderCustomizedLabel: React.FC<CustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{
        fontWeight: 600,
        fontSize: 12,
      }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const renderLegendText = (value: string) => {
  return (
    <span
      key={value}
      sx={{
        color: "white",
        fontWeight: 500,
        ml: 2,
        fontFamily: "Montserrat",
      }}
    >
      {value}
    </span>
  );
};
const DiagnosticPieChartComponent: React.FC<Props> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={360}>
      <PieChart>
        <Customized
          key="customized-text"
          component={() => {
            return (
              <text
                x="145"
                y="20"
                fill="#E1F2F9"
                sx={{
                  fontSize: 16,
                  transform: ["translate(-80px, 50px)", "unset"],
                }}
              >
                Savings by Resource Type
              </text>
            );
          }}
        />
        <Pie
          stroke="none"
          data={data}
          labelLine={false}
          fill="#8884d8"
          dataKey="value"
          legendType="rect"
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={CATEGORIES_COLORS[index % CATEGORIES_COLORS.length]}
            />
          ))}
        </Pie>
        <Customized
          key="customized-text"
          component={() => {
            return (
              <text
                x="70"
                y="355"
                fill="#E1F2F9"
                sx={{
                  fontSize: 16,
                  transform: ["translate(-80px, 50px)", "unset"],
                }}
              >
                Costs and savings are based on standard pricing.{" "}
              </text>
            );
          }}
        />
        <Legend
          formatter={renderLegendText}
          layout="vertical"
          verticalAlign="middle"
          align="right"
          wrapperStyle={{
            paddingLeft: "10px",
          }}
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
DiagnosticPieChartComponent.displayName = "DiagnosticPieChartComponent";
export default React.memo(DiagnosticPieChartComponent);
