/** @jsxImportSource theme-ui */
import { Box, Button, Typography } from "@material-ui/core";
import { GridCellParams, GridColDef } from "@material-ui/x-grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TableGrid from "components/general/TableGrid";
import * as React from "react";
import Block from "components/general/Block";
import { useAuth } from "hooks/use-auth";
import ThreeDotsIcon from "@material-ui/icons/MoreHoriz";
import Modal from "../../components/general/Modal";
import UserCreationForm from "../../components/admin/UserCreationForm";

type Props = Record<string, never>;
const userRows = [
  {
    id: 1,
    fullName: "Andrej Erika",
    email: "andrej.eriak@virtasant.com",
    login: {
      count: 12,
      last_login: "02/02/2022T08:24",
    },
    business_units: [
      { id: 1, name: "BU ONE" },
      { id: 2, name: "BU TWO" },
    ],
    roles: [
      { id: 1, name: "User" },
      { id: 2, name: "Manager" },
    ],
  },
];
const userColumns: GridColDef[] = [
  {
    field: "fullName",
    headerName: "Name",
    flex: 1,
    renderCell: (params: GridCellParams) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AccountCircleIcon fontSize="small" color="disabled" sx={{ mr: 2 }} />
        <Typography variant="body1" color="primary">
          {params.value}
        </Typography>
      </Box>
    ),
    headerClassName: "header",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      const parsedEmail = (params.value as string).split("@")[0] || "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Typography variant="body1">{parsedEmail}</Typography>
          <Typography variant="body1" color="primary">
            {params.value}
          </Typography>
        </Box>
      );
    },
    headerClassName: "header",
  },
  {
    field: "login",
    headerName: "Login Details",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Typography variant="body1">
            Count: {(params.value as any).count}
          </Typography>
          <Typography variant="body1">
            Last: {(params.value as any).last_login}
          </Typography>
        </Box>
      );
    },
    headerClassName: "header",
  },
  {
    field: "business_units",
    headerName: "Business Units",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          {(params?.value as Array<any>).map((item) => (
            <Typography key={item.id} variant="body1" color="primary">
              {item.name}
            </Typography>
          ))}
        </Box>
      );
    },
    headerClassName: "header",
  },
  {
    field: "roles",
    headerName: "Roles",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          {(params?.value as Array<any>).map((item) => (
            <Typography key={item.id} variant="body1" color="primary">
              {item.name}
            </Typography>
          ))}
        </Box>
      );
    },
    headerClassName: "header",
  },
];

const adminColumns = userColumns.concat({
  field: "actions",
  headerName: "Actions",
  sortable: false,
  filterable: false,
  editable: false,
  disableColumnMenu: true,
  align: "right",
  // width: 100,
  renderCell: () => {
    return (
      <Button
        sx={{
          px: 1,
          minWidth: "unset",
          ml: "auto",
        }}
        aria-label="Actions"
      >
        <ThreeDotsIcon />
      </Button>
    );
  },
  headerClassName: "header",
});

const businessUnitOptions = [
  {
    name: "Business unit one",
    id: "bu-1",
  },
  {
    name: "Business unit two",
    id: "bu-2",
  },
  {
    name: "Business unit three",
    id: "bu-3",
  },
];

const UsersPage: React.FC<Props> = () => {
  const onPaginate = (page: number) => null;
  const { isAdmin, user } = useAuth();
  const columns = isAdmin ? adminColumns : userColumns;
  return (
    <>
      {isAdmin && (
        <Box sx={{ mb: 3 }}>
          <Modal
            title={`Add User to ${user?.company?.name}`}
            renderButton={({ onClick }) => (
              <Button variant="contained" color="primary" onClick={onClick}>
                Add User
              </Button>
            )}
            renderChildren={({ closeModal }) => (
              <UserCreationForm
                businessUnitOptions={businessUnitOptions}
                closeModal={closeModal}
              />
            )}
          />

          <Button variant="outlined" color="primary" sx={{ ml: [, 3] }}>
            Upload CSV
          </Button>
        </Box>
      )}
      <Block title="User List">
        <TableGrid
          columns={columns}
          data={userRows}
          loading={false}
          currentPage={0}
          totalPages={2}
          onPaginate={onPaginate}
          hasItemCount
        />
      </Block>
    </>
  );
};

UsersPage.displayName = "Admin Users Page";
export default UsersPage;
