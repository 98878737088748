/** @jsxImportSource theme-ui */
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

export type RenderButtonProps = {
  onClick: () => void;
};
export type RenderChildrenProps = {
  closeModal: () => void;
};
export type RenderButton = React.FC<RenderButtonProps>;
export type RenderChildren = React.FC<RenderChildrenProps>;
type ModalProps = {
  title: string;
  renderButton: RenderButton;
  renderChildren: RenderChildren;
};

const Modal: React.FC<ModalProps> = ({
  renderButton: RenderButton,
  renderChildren: RenderChildren,
  title,
}) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <RenderButton onClick={openModal} />
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mt: -2,
              pb: 2,
              px: 2,
            }}
          >
            <Typography variant="h5">{title}</Typography>
            <IconButton onClick={closeModal} sx={{ mr: -3 }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mx: -3 }} />
        </DialogTitle>
        <DialogContent sx={{ overflow: "auto" }}>
          <RenderChildren closeModal={closeModal} />
        </DialogContent>
      </Dialog>
    </>
  );
};
Modal.displayName = "Modal";
export default Modal;
