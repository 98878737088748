import env from "utils/env";
import format from "date-fns/format";

export const generateUrl = (clientUuid: string, region: string) => {
  return env("REACT_APP_AWS_SETUP_URL")
    .replace("{{region}}", region)
    .replace("{{client_uuid}}", clientUuid)
    .replace(
      "{{template_url}}",
      encodeURIComponent(env("REACT_APP_AWS_SCRIPT_BUCKET"))
    )
    .replace(
      "{{role_name}}",
      `virtasant-read-only-${format(new Date(), "MM-dd-yy")}`
    )
    .replace(
      "{{infrastructure_account}}",
      env("REACT_APP_INFRASTRUCTURE_ACCOUNT")
    );
};
