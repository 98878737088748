/** @jsxImportSource theme-ui */
import {
  Container,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const ToS: React.FC = () => {
  return (
    <Container>
      <Box>
        <Typography variant="h2" sx={{ mb: 4 }}>
          Terms of Service
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          You have elected to subscribe to the platform and related services
          offered by either Virtasant, Inc. or a subsidiary of Virtasant, Inc.
          (such platform and services, collectively, the “Virtasant Services”)
          made available at{" "}
          <Link href="https://www.virtasant.com">
            https://www.virtasant.com
          </Link>{" "}
          (or any successor URL controlled by Virtasant, Inc.) (the “Virtasant
          Website”).
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Depending on your selection at the time of subscription, all
          references hereinafter to “Selected Services” shall refer to the
          specific Virtasant Services provided by either Virtasant, Inc. or a
          subsidiary of Virtasant, Inc. All references hereinafter to
          “Virtasant” shall refer solely to the specific provider of the
          Selected Services, be it Virtasant, Inc. or a Virtasant, Inc.
          subsidiary.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          These terms of service (“Terms of Service” or “Agreement”) govern the
          provision to you (if you are accepting these Terms of Service in your
          individual capacity) or the company of which you are a duly authorized
          employee or agent (“Customer”) of the Selected Services, and
          Customer’s usage of the same. By using the Selected Services, Customer
          agrees to be bound by these Terms of Service to the exclusion of all
          other terms. If Customer does not unconditionally agree to all of the
          terms and conditions of this Agreement, then Customer should not use
          (and has no right to use) the Selected Services or the Virtasant
          Website. Virtasant may update these Terms of Service from time to
          time, and will notify Customer of such updates either via email or by
          posting such changes on the Virtasant Website, or other reasonable
          means. Customer’s continued use of the Selected Services following
          notification of an updated version of the Terms of Service shall
          constitute Customer’s consent thereto. Virtasant may make
          modifications to the Selected Services in its discretion, provided
          that such modifications do not materially adversely affect the
          features or functionality of the Selected Services. This Agreement
          applies solely to the Selected Services and no other.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          1. Selected Services
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Virtasant shall use commercially reasonable efforts to provide the
          Selected Services. The Selected Services may incorporate or link to
          components or services provided by one or more third parties (such
          services, “Third Party Services”, and such providers, “Third Party
          Service Providers”). Customer agrees that its use of those Third Party
          Services may be subject to terms and conditions and policies
          (including any amendments or modifications to those terms and
          conditions and policies) that are specific to such Third Party
          Services, and that it shall be bound by such terms and conditions and
          policies.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          2. Payments
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (a) Fees. Customer shall pay Virtasant’s then current pricing for the
          Selected Services. Such fees shall accrue based on Customer’s usage of
          the Selected Services. Virtasant shall automatically bill Customer’s
          credit card based on Customer’s usage of the Selected Services during
          each month and shall send a sales receipt to Customer for such
          Selected Services via email. Unless otherwise set forth in a Service
          Order Form, all payments from Customer to Virtasant in connection with
          this Agreement must be made in United States dollars. Payment shall be
          due within 20 days (NET20) of Customer’s receipt of each invoice.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (b) Late Payment; Collections. Virtasant may charge Customer a late
          fee on any amount that is not paid when due equal to the lesser of
          1.5% per month or the maximum rate permitted by law, plus all
          reasonable expenses and costs of collection (including collection
          agency fees, attorney fees and court costs). Without limiting the
          foregoing, Virtasant may without liability immediately suspend any or
          all Selected Services, or immediately terminate this Agreement, if
          payment for any Service is rejected or declined or becomes the subject
          of a credit card chargeback. As a condition to any resumption of
          suspended or terminated Selected Services, Customer shall pay
          Virtasant’s then current reinstatement fee.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (c) Termination. Without limiting any other remedy available to
          Virtasant, if this Agreement expires or terminates for any reason,
          Customer shall pay all unpaid fees for Selected Services related to
          periods up to and including the date of expiration or termination.
          Customer acknowledges and consents to Virtasant automatically charging
          Customer’s credit card for such amounts.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (d) Taxes. Prices do not include any sales, use, value-added, excise,
          personal property, customs fees, import duties, stamp duties,
          withholding, or any other similar tax, fee or assessment, including
          penalties and interest, imposed by any United States federal, state,
          provincial or local government entity, or any non-US government entity
          on any of the Selected Services under this Agreement (“Taxes”).
          Customer shall be liable for and shall pay any and all Taxes and
          related charges, however designated, imposed on the provision of the
          Selected Services (excluding taxes based solely on Virtasant’s net
          income) regardless of whether Virtasant fails to collect the tax at
          the time the Selected Services are provided. When Virtasant has the
          legal obligation to pay or collect such Taxes, the appropriate amount
          shall be paid by Customer, and Customer acknowledges and consents to
          Virtasant automatically charging Customer’s credit card for such
          amount, unless Customer provides Virtasant with a valid tax exemption
          certificate authorized by the appropriate taxing authority. Customer
          shall provide to Virtasant original or certified copies of all Tax
          payments or other evidence of payment of Taxes by Customer with
          respect to transactions or payments under this Agreement.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          3. Restrictions and Compliance with Laws and Other Matters
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (a) Prohibited Behavior. Customer shall not, and shall not permit any
          users of Customer’s applications hosted by Virtasant as part of the
          Selected Services (such applications, “Customer Applications”, and
          such users, “Users”) or any other party to, engage in, solicit, or
          promote any activity that is illegal, violates the rights of others,
          or could subject Virtasant to liability to third parties, including:
          (i) unauthorized access, monitoring, interference with, or use of
          Virtasant or third party accounts, data, computers, systems or
          networks, including the introduction of viruses or similar harmful
          code via the Customer Application or Selected Services; (ii)
          interference with others’ usage of the Selected Services or any system
          or network, including mail bombing, flooding, broadcast or denial of
          service attacks; (iii) unauthorized collection or use of personal or
          confidential information, including phishing, pharming, scamming,
          spidering, and harvesting; (iv) use of any false, misleading or
          deceptive TCP-IP packet header information in an e-mail or a newsgroup
          posting; (v) distribution of advertisement delivery software unless
          (1) the User affirmatively consents to the download and installation
          of such software based on a clear and conspicuous notice of the nature
          of the software, and (2) the software is easily removable by use of
          standard tools for such purpose included on major operating systems
          (such as Microsoft’s “add/remove” tool); (vi) infringement,
          misappropriation or other violation of any patent, trademark,
          copyright or other intellectual property or proprietary right; (vii)
          collection, storage, publication, transmission, viewing or other use
          of any content that, in Virtasant’s opinion, (1) is obscene, (2)
          constitutes or promotes child pornography, (3) is excessively violent,
          incites or threatens violence, or contains harassing content or hate
          speech, (4) is illegally unfair or deceptive, (5) is defamatory or
          violates a person’s privacy, or (6) creates a safety or health risk to
          an individual or the public; (viii) any other activity that places
          Virtasant in the position of fostering, or having liability for,
          illegal activity in any jurisdiction; (ix) any activity that violates
          the acceptable use policy or similar set of terms of any Third Party
          Service Providers for Third Party Services that have been made
          available to Customer; or (x) attempting to probe, scan, penetrate or
          test the vulnerability of an Virtasant system or network or to breach
          Virtasant’s security or authentication measures, whether by passive or
          intrusive techniques. If any IP address or responding to any Customer
          Application becomes listed on Spamhaus, Spews, NJABL or any other
          reputable abuse database, then Customer will be deemed to be in breach
          of this Section 3(a), regardless of whether or not the IP numbers were
          listed as a result of Customer’s actions. Virtasant may suspend the
          provision of Selected Services to Customer if Customer or a User
          engages in any of the foregoing activities.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (b) Security. Customer shall take all reasonable security precautions
          in connection with its use of the Selected Services. Customer shall
          protect the confidentiality of all usernames, passwords, and other
          information it uses to access the Selected Services and shall change
          its passwords periodically. If the Customer Application is hacked or
          otherwise accessed by a third party without authorization, then
          Virtasant may take the Customer Application offline until Virtasant
          determines that the intrusion is finally resolved.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (c) Compliance with Laws. Customer shall comply with all laws
          applicable to the use and operation of the Customer Application and
          Selected Services including, as applicable, the CAN-SPAM Act of 2003
          and all laws and regulations applicable to bulk or commercial email.
          Customer shall establish a process to respond to notices of alleged
          infringement or illegal content that complies with applicable law.
          Customer acknowledges and agrees that (i) Virtasant may, but is not
          obligated, to monitor Customer’s compliance; and (ii) Virtasant may,
          without liability to Customer, block or take down the transmission of
          email or other content in Customer’s Application or otherwise that in
          Virtasant’s opinion violates any such law or regulation or otherwise
          imposes any liability on Virtasant. Virtasant reserves the right to
          suspend or terminate Customer’s accounts or Application if Virtasant
          determines, in its reasonable opinion, that the content in Customer’s
          Application or otherwise is in breach of applicable law.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (d) Cooperation with Investigations and Legal Proceedings. Virtasant
          may, without notice to Customer: (i) report to the appropriate
          authorities any conduct by Customer or Users that Virtasant believes
          violates applicable criminal law; and (ii) provide any information
          that it has about Customer or its Users in response to a request from
          a law enforcement or government agency, or in response to a request in
          a civil action that on its face meets the requirements for such a
          request.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          4. Customer Application and Customer Account
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Customer is solely responsible for its Customer Application, including
          for (i) controlling the access to, and use and security of, the
          Customer Application and the data residing in or processed via the
          Customer Application, (ii) maintaining the security of the passwords
          and other measures used to protect access to Customer’s account, and
          (iii) all instructions provided to Virtasant through Customer’s
          account, whether or not authorized by Customer. Customer must have
          valid and current information on file with its domain name registrar
          and may only use IP addresses which Virtasant assigns to it.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          5. Suspension of Selected Services
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Virtasant may suspend the provision of Selected Services to Customer
          or remove any data or content transmitted via the Selected Services
          without liability (i) if Virtasant reasonably believes that any
          Selected Service is being used in violation of this Agreement or
          applicable law, (ii) if requested by a law enforcement or government
          agency or otherwise to comply with applicable law, provided that
          Virtasant shall use commercially reasonable efforts to notify Customer
          prior to suspending Selected Services as permitted under this
          Agreement, or (iii) as otherwise specified in this Agreement.
          Information on Virtasant’s servers may be unavailable to Customer
          during a suspension of Selected Services.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          6. Customer Warranties
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Customer represents and warrants to Virtasant that (i) the information
          Customer has provided for the purpose of establishing an account with
          Virtasant is complete and accurate, and (ii) it has the requisite
          power and authority to enter into this Agreement and to perform all of
          its obligations hereunder. Customer shall update its account
          information to maintain the accuracy of such information during the
          term of this Agreement
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          7. Indemnification
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Customer shall indemnify and hold harmless Virtasant from and against
          any and all claims, demands, liabilities, losses, damages, penalties,
          fines and expenses (including reasonable attorneys’ fees and expenses)
          arising out of or related to any of the following: (i) actual or
          alleged use of the Selected Services (including the Customer
          Application) in violation of this Agreement or applicable law by
          Customer or any Users (including any actual or alleged infringement or
          misappropriation of third party intellectual property rights by the
          Customer Application or arising from data or other content posted or
          stored on the Selected Services or the Customer Application by
          Customer or its Users); (ii) any dispute between Customer and its
          customers, contractors or Users; (iii) the operation or use of any
          Customer Application or any data or content contained therein or
          transmitted thereby; or (iv) any violation by Customer, its customers,
          contractors or Users, of any terms, conditions, agreements or policies
          of any Third Party Service Provider. Customer shall not settle any
          claim under this Section 7 without the prior written consent of
          Virtasant.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          8. Disclaimer of Warranties
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          EXCEPT AS EXPRESSLY SPECIFIED IN THIS AGREEMENT, ALL SELECTED SERVICES
          ARE PROVIDED “AS IS” AND VIRTASANT (FOR ITSELF, ITS PARENT, AND ALL OF
          VIRTASANT’S SUBSIDIARIES, AFFILIATES, SUPPLIERS AND LICENSORS)
          DISCLAIMS ANY AND ALL OTHER WARRANTIES, INCLUDING ANY IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, AND NONINFRINGEMENT. NEITHER VIRTASANT (NOR ANY OF ITS PARENT,
          SUBSIDIARIES, AFFILIATES, SUPPLIERS OR LICENSORS) WARRANTS OR
          REPRESENTS THAT THE SELECTED SERVICES WILL BE UNINTERRUPTED,
          ERROR-FREE, OR SECURE. CUSTOMER ACKNOWLEDGES THAT THERE ARE RISKS
          INHERENT IN INTERNET CONNECTIVITY THAT COULD RESULT IN THE LOSS OF
          CUSTOMER’S PRIVACY, DATA, CONFIDENTIAL INFORMATION, AND PROPERTY.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          9. Limitation of Liability
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          NOTWITHSTANDING ANYTHING ELSE IN THIS AGREEMENT TO THE CONTRARY, IN NO
          EVENT SHALL VIRTASANT OR ANY OF ITS PARENT, SUBSIDIARIES, AFFILIATES,
          OR ANY OF THEIR EMPLOYEES, OFFICERS, AGENTS, LICENSORS OR SUPPLIERS,
          BE LIABLE UNDER ANY THEORY OF LAW (INCLUDING BREACH OF CONTRACT, TORT,
          STRICT LIABILITY, AND INFRINGEMENT) FOR: I) PROCUREMENT OF SUBSTITUTE
          GOODS OR SERVICES, LOSS OF USE, DATA, OR PROFITS, OR BUSINESS
          INTERRUPTION OR FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR
          CONSEQUENTIAL DAMAGES (HOWEVER CAUSED AND UNDER ANY THEORY OF LAW
          INCLUDING BREACH OF CONTRACT, STATUTE, TORT, STRICT LIABILITY, AND
          INFRINGEMENT), EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; OR
          II) ANY AMOUNTS IN EXCESS, IN THE AGGREGATE, OF THE FEES ACTUALLY
          RECEIVED BY VIRTASANT FROM CUSTOMER IN THE THREE (3) MONTHS
          IMMEDIATELY PRECEDING THE FIRST OCCURRENCE OF AN EVENT GIVING RISE TO
          A CLAIM. THIS IS AN AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE
          CLAIM SHALL NOT ENLARGE THIS LIMIT. CUSTOMER ACKNOWLEDGES THAT EACH OF
          THE FOREGOING LIMITATIONS OF LIABILITY ARE AN ESSENTIAL PART OF THIS
          AGREEMENT AND THAT ABSENT SUCH LIMITATIONS VIRTASANT WOULD NOT PROVIDE
          THE SELECTED SERVICES TO CUSTOMER OR ENTER INTO THIS AGREEMENT.
          CUSTOMER ACKNOWLEDGES THAT THE SELECTED SERVICES DO NOT INCLUDE DATA
          BACKUP OR DATA STORAGE SERVICES, AND CUSTOMER HEREBY RELEASES
          VIRTASANT AND ITS CONTRACTORS FROM ANY LIABILITY FOR LOSS OF DATA.
          NOTHING IN THIS AGREEMENT SHALL EXCLUDE OR LIMIT EITHER PARTY’S
          LIABILITY FOR DEATH OR PERSONAL INJURY ARISING FROM ITS NEGLIGENCE, OR
          FOR FRAUD. Nothing in this Agreement SHALL exclude or limit either
          party’s liability for death or personal injury arising from its
          negligence, or for fraud.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          10. Termination
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (a) Customer. Customer may terminate this Agreement upon ten (10) days
          written notice to Virtasant and shall pay all amounts due for Selected
          Services through the effective date of termination in accordance with
          Section 2(c). (b) Virtasant. Virtasant may terminate this Agreement
          without liability as follows: (i) in accordance with Section 2(b) or
          Section 3(c); (ii) without limiting the foregoing subsection (i), upon
          notice if Customer materially violates any provision of this Agreement
          and fails to cure the violation within thirty (30) days of a written
          notice from Virtasant; (iii) upon one (1) Business Day’s notice if
          Customer uses the Selected Services in violation of a material term of
          this Agreement more than once (after having received notice from
          Virtasant regarding the initial violation); or (iv) upon five (5)
          Business Day’s notice if Virtasant is threatened with a legal claim
          for intellectual property infringement related to the provision of a
          Selected Service and is unable, through commercially reasonable
          efforts, to obtain a license or modify such Selected Service in a way
          that avoids an ongoing risk of liability. A “Business Day” shall mean
          Monday through Friday, 8:00 a.m. to 5:00 p.m., Pacific Standard Time
          time, excluding any day that banks in the United States are required
          or permitted to be closed. (c) Effect of Termination. Notwithstanding
          the foregoing, Customer acknowledges and agrees that Virtasant may, in
          its sole discretion and without obligation, delete all data or content
          in Virtasant’s possession or control, including but not limited to
          “Customer Data” as defined in Section 11(a) hereinbelow, after thirty
          (30) days following any termination or expiration of this Agreement
          for any reason, without liability to Customer. Sections 2, 7, 8, 9,
          10, 12, 13, and 14 will survive any expiration or termination of this
          Agreement.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          11. Use of Data, EU Data Subjects and Security
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (a) Customer Data. Virtasant may use, process, transfer and disclose
          information or data of Customer and its Users that is stored in a
          Customer Application or otherwise provided by Customer or its Users as
          part of the Selected Services (collectively, “Customer Data”) only to
          the extent necessary to provide the Selected Services or as otherwise
          may be permitted under applicable law; provided that Virtasant may
          also disclose Customer Data (i) to third parties in connection with
          the provision of Selected Services (provided such third parties are
          under a duty to use, process, transfer and disclose such Customer Data
          only as necessary to provide the Selected Services), and (ii) in
          response to requests from law enforcement officials, government
          agencies, or as required in connection with legal proceedings.
          Customer Data does not include “Direct Customer Information” (as
          defined below). (b) Direct Customer Information. Virtasant’s use and
          disclosure of Customer contact, payment, financial and similar
          information collected by Virtasant directly from Customer (“Direct
          Customer Information”) is governed by Virtasant’s Privacy Policy
          located at{" "}
          <Link href="https://www.virtasant.com/privacy">
            https://www.virtasant.com/privacy
          </Link>
          . In addition, Virtasant may transfer Customer Data and Direct
          Customer Information to a third party in connection with a merger or
          sale of Virtasant’s business or assets, in which case the transferee
          will be permitted to obtain and use such information subject to the
          terms of this Agreement and Virtasant’s Privacy Policy. The Customer
          warrants that it has the appropriate and necessary consents to permit
          the transfer by the Virtasant of Customer Data and Direct Customer
          Information to a third party in accordance with and for the purposes
          set out in this Section 11(b). (c) EU Data Subjects. If Virtasant
          receives any Customer Data constituting “personal data” as defined in
          the EU Data Protection Directive 95/46/EC (as may be amended from time
          to time) from Customer or its Users in connection with the Selected
          Services, including any such Customer personal data that may be
          collected by or stored in the Customer Application or otherwise hosted
          on servers owned or controlled by Virtasant, then Customer agrees
          that: (i) Customer is the data controller with respect to such
          Customer Data; and (ii) Customer will comply with all applicable laws,
          including the EU Data Protection Directive, applicable to such
          Customer Data, including obtaining all necessary consents from
          relevant EU Data Subjects and taking actions necessary to enable the
          Data Subjects to exercise their rights of notice, disclosure, access,
          accountability and other rights under the relevant data protection
          law. Virtasant will act as the data processor of such Customer Data
          and in that capacity will use commercially reasonable efforts to
          assist Customer in enabling it to comply with the applicable data
          protection law. Customer acknowledges that Virtasant utilizes servers
          located in the United States, and consents to the transfer of Customer
          Data to the United States. Customer further acknowledges and agrees
          that any data or information stored by Customer on such servers is
          subject to the laws of the United States, including copyright, privacy
          and defamation laws. (d) Data Security. Virtasant will use
          commercially reasonable efforts to prevent the unauthorized access,
          use or disclosure of Customer Data and Direct Customer Information
          located on Virtasant servers, including the implementation of
          industry-standard measures designed to maintain the security of such
          data. Customer acknowledges, however, that Virtasant cannot guarantee
          the security of such data given the nature of the Internet.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          12. Third Party Services and Separate Products
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (a) Third Party Terms; Disclaimer. Certain components of the Selected
          Services are comprised of Third Party Services, and Virtasant may
          perform its obligations hereunder through Third Party Providers of
          such Third Party Services, which may include without limitation
          Amazon.com, Inc. or one of its affiliates (“Amazon”). In addition,
          Virtasant may from time to time make available to Customer certain
          third party software, services, or other products that are not
          included as part of the Selected Services (“Separate Products”) and/or
          may provide support to Customer in relation to those Separate
          Products. For all Third Party Services and Separate Products, Customer
          shall agree to terms of use or service, or license or other agreements
          or policies applicable thereto (e.g., the Amazon Web Services Customer
          Agreement located at{" "}
          <Link href="http://aws.amazon.com/agreement/">
            http://aws.amazon.com/agreement/
          </Link>
          , including its associated privacy notice (the “AWS Agreement”),
          including any modifications thereto mandated by the applicable Third
          Party Provider. Customer shall be responsible for strict compliance
          with, and any liability arising from, any violation of any such terms
          of use or service, or license or other agreements or policies. Without
          limiting the foregoing, Customer acknowledges and agrees to the
          section of the AWS Agreement titled “Downtime and Service Suspensions;
          Security.” NOTWITHSTANDING ANYTHING TO THE CONTRARY, VIRTASANT MAKES
          NO REPRESENTATIONS OR WARRANTIES WHATSOEVER REGARDING THIRD PARTY
          SERVICES OR SEPARATE PRODUCTS OR RELATED SUPPORT SERVICES AND, AS
          BETWEEN CUSTOMER AND VIRTASANT, SUCH THIRD PARTY SERVICES AND SEPARATE
          PRODUCTS AND RELATED SUPPORT SERVICES ARE PROVIDED “AS IS” WITHOUT
          WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, VIRTASANT SHALL
          HAVE NO LIABILITY FOR LOSS, DESTRUCTION, ALTERATION OR UNAUTHORIZED
          ACCESS TO DATA OR OTHER CONTENT STORED OR TRANSMITTED USING THIRD
          PARTY SERVICES OR SEPARATE PRODUCTS. (b) Third Party Services Changes.
          Virtasant may suspend or terminate a Third Party Service upon written
          notice to Customer if the Third Party Service is suspended or ceases
          to be provided for any reason (whether generally or through
          Virtasant), and Virtasant may modify the pricing charged to Customer
          or features available with respect to such Third Party Service if the
          Third Party Service Provider modifies the pricing or features of such
          Third Party Service. Virtasant shall use reasonable efforts to provide
          Customer a reasonable notice period with respect to any such
          suspension, termination or price or feature modification, which period
          Customer acknowledges may be determined in part by the amount of
          notice the Third Party Service Provider provides to Virtasant of such
          events.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          13. Notices and Communications
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (a) Notices. All Customer notices to Virtasant under this Agreement
          (including notices required to be in writing) may be sent to Virtasant
          via e-mail through Customer’s Virtasant online support account.
          Virtasant notices to Customer under this Agreement shall be given (i)
          via email to the individual designated as Customer’s “Customer
          Contact” when signing up for the Selected Services online through the
          Virtasant Website, (ii) as specified in these Terms and Conditions, or
          (iii) by other means reasonable under the circumstances. Notices are
          deemed received on the day delivered, or if that day is not a Business
          Day, as of the beginning of the first Business Day following the day
          delivered. (b) Communications with Customer. Virtasant shall
          communicate with Customer regarding day-to-day operational issues
          using email, the Virtasant Website, and the product user interface, as
          relevant. (c) Customer Responsibilities. Customer shall be responsible
          for (i) ensuring that Customer email addresses used in communicating
          with Virtasant are kept current and that spam and other filters for
          such email accounts are configured to accept emails from Virtasant,
          and (ii) routinely checking the Virtasant Website and product user
          interface for additional information relevant to Customer’s use of the
          Selected Services.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          14. Miscellaneous
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          (a) Non-Solicitation. During the term of this Agreement and for 12
          months thereafter, Customer agrees that it shall not solicit for
          employment any employee of Virtasant with whom it has had direct
          contact in connection with this Agreement. Notwithstanding the
          foregoing, Customer shall not be precluded from (i) hiring an employee
          of Virtasant who independently approaches Customer, or (ii) conducting
          general recruiting activities, such as participation in job fairs or
          publishing advertisements in publications or on websites for general
          circulation. If Customer violates this provision, in addition to any
          other right Virtasant may have at law or in equity, Customer shall
          make a one-time payment to Virtasant in the amount of thirty percent
          (30%) of the employee’s then-current annual base salary. (b)
          Ownership; Licenses; Customer Data. As between the parties, Virtasant
          owns and shall retain all rights in the Selected Services (including
          all related infrastructure), Virtasant’s trademarks and service marks,
          and all modifications to any of the foregoing and all related
          intellectual property rights. Customer grants Virtasant (and its
          subcontractors) during the term of this Agreement a non-exclusive,
          worldwide, royalty-free, sublicensable, license to use, reproduce,
          modify, publicly perform, publicly display and distribute Customer
          Data for purposes of providing the Selected Services hereunder. In
          addition, Virtasant shall have the right, but not the obligation, to
          retain copies of Customer Data for legal compliance purposes. (c)
          Governing Law, Jurisdiction, Venue. This Agreement shall be governed
          by the laws of the State of California, USA, excluding its conflicts
          of law rules. This Agreement shall not be governed by the United
          Nations Convention on the International Sale of Goods. Except for
          disputes involving the assertion of Virtasant’s intellectual property
          rights and claims for injunctive relief, any dispute arising from or
          relating to the subject matter of this Agreement shall be finally
          settled by binding arbitration in San Francisco County, California,
          using the English language, in accordance with the Arbitration Rules
          and Procedures of Judicial arbitration and Mediation Services, Inc.
          (“JAMS”) then in effect, by one commercial arbitrator with substantial
          experience in resolving intellectual property and commercial contract
          disputes. Such arbitrator shall be selected from the appropriate list
          of JAMS arbitrators in accordance with the JAMS Arbitration Rules and
          Procedures. The prevailing party in the arbitration shall be entitled
          to receive reimbursement of its reasonable expenses (including
          reasonable attorneys’ fees, expert witness fees and all other
          expenses) incurred in connection therewith. Judgment upon the award so
          rendered may be entered in a court having jurisdiction, or application
          may be made to such court for judicial acceptance of any award and an
          order of enforcement, as the case may be. Notwithstanding the
          foregoing, each party shall have the right to institute an action in a
          court of proper jurisdiction for injunctive or other equitable relief
          pending a final decision by the arbitrator. For all purposes of this
          Agreement, the parties consent to exclusive jurisdiction and venue in
          the state and federal courts located in San Francisco County,
          California. Use of the Selected Services is not authorized in any
          jurisdiction that does not give effect to all provisions of this
          Agreement, including without limitation, this Section. (d) Non-Waiver.
          A party’s failure or delay in enforcing any provision of this
          Agreement will not be deemed a waiver of that party’s rights with
          respect to that provision or any other provision of this Agreement. A
          party’s waiver of any of its rights under this Agreement is not a
          waiver of any of its other rights with respect to a prior,
          contemporaneous or future occurrence, whether similar in nature or
          not. (e) Construction. The headings in this Agreement are not part of
          this Agreement but are solely for the convenience of the parties. As
          used herein, the words “include” and “including,” and variations
          thereof, will not be deemed to be terms of limitation, but rather,
          will be deemed to be followed by the words “without limitation.” All
          references in this Agreement to “Sections” refer to sections herein.
          (f) Force Majeure. Neither party shall be in default of any obligation
          under this Agreement if the failure to perform the obligation is due
          to any event beyond that party’s control, including significant
          failure of a portion of the power grid, significant failure of the
          Internet, natural disaster, war, riot, insurrection, epidemic, strikes
          or other organized labor action, terrorist activity, or other events
          of a magnitude or type for which precautions are not generally taken
          in the industry. (g) No Third Party Beneficiaries. Except with respect
          to Sections 8 and 9 as they apply to Virtasant’s parent, subsidiaries,
          affiliates, and Virtasant’s and its parent’s, subsidiaries’ and
          affiliates’ respective employees, officers, agents, licensors and
          suppliers, there are no third party beneficiaries to this Agreement.
          (h) Severability. In the event any term of this Agreement is held
          unenforceable, the remaining portion of this Agreement will remain in
          full force and effect. (i) Marketing. Customer grants Virtasant the
          right during the term of this Agreement to use Customer’s name, mark
          and logo on the Virtasant Website and in Virtasant’s promotional
          materials solely to identify Customer as a customer of Virtasant, in
          accordance with any usage guidelines delivered by Customer to
          Virtasant. (j) Relationship Between the Parties. The parties are
          independent contractors and not partners or joint venturers. Neither
          party is the agent of the other, and neither party may represent to
          any person that it has the power to bind the other on any agreement.
          This Agreement is non-exclusive. Virtasant may provide the Selected
          Services to any person, including a competitor of Customer. (k)
          Assignment. Neither party may assign this Agreement to a third party
          without the written consent of the other party, provided that
          Virtasant may upon written notice assign this Agreement to an
          affiliate or to a successor in interest upon any merger, acquisition,
          change of control, reorganization or sale of all or substantially all
          of its stock or its assets that are related to this Agreement. An
          attempted assignment in contravention of the terms and conditions
          hereof shall be null and void. (l) Agreement. This Agreement is the
          complete and exclusive agreement between the parties regarding its
          subject matter and supersedes and replaces any other agreement,
          understanding or communication, written or oral regarding such subject
          matter.
        </Typography>
      </Box>
    </Container>
  );
};
export const ToSDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h5">Terms of Service</Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mx: -3 }} />
      </DialogTitle>
      <DialogContent>
        <ToS />
      </DialogContent>
    </Dialog>
  );
};
