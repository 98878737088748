/** @jsxImportSource theme-ui */
import { capitalize, Chip } from "@material-ui/core";
import React from "react";
import getStatusColor from "utils/get-status-color";
import mapStatus from "utils/map-status";

interface Props {
  status: string | undefined | null;
}
const StatusChip: React.FC<Props> = ({ status }) => {
  const color = React.useMemo(() => getStatusColor(status), [status]);
  const mappedStatus = React.useMemo(() => mapStatus(status), [status]);
  return (
    <Chip
      label={capitalize(mappedStatus || "")}
      size="medium"
      sx={{
        color: "white",
        fontWeight: 400,
        fontSize: "16px",
        backgroundColor: color,
      }}
    />
  );
};
export default StatusChip;
