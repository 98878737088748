/** @jsxImportSource theme-ui */
import { Grid, ListItem, Typography } from "@material-ui/core";
import StatusChip from "components/general/StatusChip";
import numbro from "numbro";
import * as React from "react";

type ListItemProps = StatusItem;

const StatusListItem: React.FC<ListItemProps> = ({ status, amount, count }) => {
  return (
    <ListItem sx={{ my: 12 }}>
      <Grid spacing={1} container justify="space-between">
        <Grid xs={3} item>
          <StatusChip status={status} />
        </Grid>

        <Grid xs={5} item>
          <Typography
            align="right"
            sx={{
              color: "#1053A4",
            }}
          >
            {numbro(amount).formatCurrency({ thousandSeparated: true })}
          </Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography
            align="right"
            sx={{
              color: "#1053A4",
            }}
          >
            {numbro(count).format({ thousandSeparated: true })}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};
StatusListItem.displayName = "StatusListItem";
export default StatusListItem;
