/** @jsxImportSource theme-ui */
import numbro from "numbro";
import * as React from "react";
import {
  Cell,
  Pie,
  PieChart,
  PieProps,
  ResponsiveContainer,
  Sector,
} from "recharts";
import getStatusColor from "utils/get-status-color";

const renderActiveShape: PieProps["activeShape"] = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}) => {
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 1.5}
        outerRadius={outerRadius + 1.5}
        startAngle={startAngle - 1.5}
        endAngle={endAngle + 1.5}
        fill={fill}
      />
    </g>
  );
};

type Props = {
  className?: string;
  items: StatusItem[] | undefined;
  totalAmount: number;
  totalIssues: number;
};

const StatusChart: React.FC<Props> = ({
  className,
  items = [],
  totalAmount,
  totalIssues,
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number | undefined>();
  const data = React.useMemo(() => {
    return items.map(({ status, amount }) => {
      return {
        name: status,
        value: amount,
      };
    });
  }, [items]);
  const handlePieEnter = React.useCallback((_data: unknown, index: number) => {
    setActiveIndex(index);
  }, []);
  const handlePieLeave = React.useCallback((_data: unknown, index: number) => {
    setActiveIndex((prev) => {
      if (prev !== index) {
        // Fix when we switch from one cell to another one directly
        return prev;
      }
      return undefined;
    });
  }, []);

  return (
    <ResponsiveContainer width="100%" height={330} className={className}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={100}
          outerRadius={140}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={handlePieEnter}
          onMouseLeave={handlePieLeave}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getStatusColor(entry.name)} />
          ))}
        </Pie>
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontFamily="Montserrat"
          color="#636C74"
          fontWeight={500}
          fontSize="24px"
        >
          {numbro(totalAmount).formatCurrency({ thousandSeparated: true })}
        </text>
        <text
          x="50%"
          y="50%"
          dy={32}
          textAnchor="middle"
          dominantBaseline="middle"
          fontFamily="Montserrat"
          color="#636C74"
          fontWeight={400}
          fontSize="16px"
        >
          {numbro(totalIssues).format({ thousandSeparated: true })} Issues
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};
StatusChart.displayName = "StatusChart";
export default StatusChart;
