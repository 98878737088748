/** @jsxImportSource theme-ui */
import { Box, Button, Grid, Link, Typography } from "@material-ui/core";
import AccountNavigationForm from "components/admin/AccountNavigationForm";
import Block from "components/general/Block";
import Title from "components/general/Title";
import { adminPaths } from "components/router/routes";
import * as React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

type Props = {
  className?: string;
};

const BusinessUnitDetailsPage: React.FC<Props> = () => {
  const params = useParams<{
    businessUnit: string;
    account: string;
  }>();

  const accountName = "AWS_Prod_Account1";
  const items = [
    {
      name: "Account Number",
      value: "0987654321",
    },
    {
      name: "Company Name",
      value: "Companty ABC",
    },
    {
      name: "Business Unit",
      value: "BU Acme",
    },
    {
      name: "Cloud Provider",
      value: "AWS",
    },
    {
      name: "Cross Account Role for Finder",
      value: "co_virtasant_read_only",
      valueStyle: { color: "#0071E3" },
    },
    {
      name: "Cross Account Role for Fixer",
      value: "co_virtasanta_write_only",
      valueStyle: { color: "#0071E3" },
    },
  ];
  const nextAccountId = "next-account-id";
  return (
    <>
      <Link
        to={adminPaths.BUSINESS_UNITS}
        sx={{ textDecoration: "none" }}
        component={RouterLink}
      >
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: 14,
            lineHeight: "20px",
          }}
        >
          Back to Business Units
        </Typography>
      </Link>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title
          sx={{
            mt: 3,
            mb: 3,
          }}
        >
          {accountName}
        </Title>
        <AccountNavigationForm />
      </Box>
      <Block
        title="Account Details"
        headerRight={
          <Button variant="text" color="primary">
            Edit
          </Button>
        }
      >
        <Grid container spacing={3} sx={{ p: 20 }}>
          {items.map(({ name, value, valueStyle }) => {
            return (
              <React.Fragment key={name}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ color: "#525252", fontSize: 14 }}>
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography
                    sx={{ color: "#161616", fontSize: 14 }}
                    style={valueStyle}
                  >
                    {value}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Block>
      <Button
        component={RouterLink}
        to={`${adminPaths.BUSINESS_UNITS}/${params.businessUnit}/${nextAccountId}`}
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
      >
        Next Account
      </Button>
    </>
  );
};
BusinessUnitDetailsPage.displayName = "BusinessUnitDetailsPage";
export default BusinessUnitDetailsPage;
