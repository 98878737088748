import ReactGA from "react-ga";
import env from "./env";

const TRACKING_ID = "UA-164069146-2";

function init() {
  // Enable debug mode on the local development environment
  const isDev = env("NODE_ENV") !== "production";
  ReactGA.initialize(TRACKING_ID, { debug: isDev, testMode: isDev });
}

function sendEvent(payload: any) {
  ReactGA.event(payload);
}

function sendPageview(path: string) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
};
