/** @jsxImportSource theme-ui */
import { Box, Button, Grid, Typography } from "@material-ui/core";
import OnboardingBar from "components/navigation/OnboardingBar";
import { authPaths } from "components/router/routes";
import VerifyAWSSetupForm from "components/verify-aws-setup/VerifyAWSSetupForm";
import useRouter from "hooks/use-router";
import * as React from "react";

const VerifyAWSSetupPage: React.FC = () => {
  const { history } = useRouter();
  const goBack = () => history.push(authPaths.CONNECT);

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
          px: [30, 0],
        }}
      />
      <Grid
        container
        sx={{
          bg: "white",
          borderRadius: 10,
          minHeight: 515,
          maxWidth: 1240,
          mx: "auto",
        }}
      >
        <Grid
          item
          sm={4}
          md={6}
          container
          justify="center"
          alignItems="stretch"
        >
          <Grid
            item
            container
            alignItems="center"
            sx={{
              flexGrow: 0,
              flexBasis: [, "460px"],
              position: "relative",
            }}
          >
            <Grid item sx={{ pl: [30, , 0] }}>
              <Typography
                component="h1"
                variant="h2"
                sx={{
                  color: "#EF7B2E",
                }}
              >
                Verify your AWS Setup
              </Typography>
            </Grid>

            <Button
              type="button"
              color="primary"
              onClick={goBack}
              sx={{
                display: ["none", "block"],
                px: 4,
                position: "absolute",
                bottom: 20,
                left: -32,
                minWidth: 250,
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={8} md={6}>
          <VerifyAWSSetupForm />
        </Grid>
      </Grid>
    </Box>
  );
};
VerifyAWSSetupPage.displayName = "VerifyAWSSetupPage";
export default VerifyAWSSetupPage;
