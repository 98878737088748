/** @jsxImportSource theme-ui */
import React from "react";
import { Typography, Tabs, Tab } from "@material-ui/core";

interface TabLabelProps {
  tabValue: number;
  index: number;
  text: string;
}
interface TabBarProps {
  tabValue: number;
  onTabChange: (event: React.ChangeEvent<unknown>, newValue: number) => void;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: unknown;
  value: unknown;
}
const TabLabel: React.FC<TabLabelProps> = ({ tabValue, index, text }) => (
  <Typography
    variant="h4"
    sx={{
      textAlign: "center",
      fontWeight: 600,
      fontSize: 28,
      color: tabValue === index ? "#EF7B2E" : "#90969C",
    }}
  >
    {text}
  </Typography>
);
export const TabBar: React.FC<TabBarProps> = ({ tabValue, onTabChange }) => {
  const a11yProps = (index: number) => {
    return {
      id: `signupin-tab-${index}`,
      "aria-controls": `signupin-tabpanel-${index}`,
    };
  };
  return (
    <Tabs
      indicatorColor="primary"
      value={tabValue}
      onChange={onTabChange}
      aria-label="signupin-tabs"
      variant="standard"
      sx={{
        width: "100%",
        ".MuiTabs-indicator": {
          backgroundColor: "transparent",
          "::before": {
            content: "''",
            width: ["100%", "80%", "70%"],
            height: "1px",
            backgroundColor: "#1B70BB",
            position: "absolute",
            top: 0,
            right: "50%",
            transform: "translateX(50%)",
          },
        },
      }}
    >
      <Tab
        label={<TabLabel index={0} tabValue={tabValue} text="Sign in" />}
        sx={{ px: [0, , , 12], minWidth: [, "140px !important"] }}
        {...a11yProps(0)}
      />
      <Tab
        disabled
        label={<Typography>or</Typography>}
        sx={{ minWidth: [, "20px !important", , "40px !important"], px: 0 }}
      />
      <Tab
        label={<TabLabel index={2} tabValue={tabValue} text="Sign Up" />}
        sx={{ px: [0, , , 12], minWidth: [, "140px !important"] }}
        {...a11yProps(2)}
      />
    </Tabs>
  );
};
export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ width: "100%" }}
    >
      {value === index && <>{children}</>}
    </div>
  );
};
