const getStatusColor = (status: string | undefined | null) => {
  switch (status) {
    case "Pending Approval":
    case "pending":
      return "#1AB3C6";
    case "Scheduled":
    case "scheduled":
      return "#C33B90";
    case "in progress":
      return "#1053A4";
    case "failed":
      return "#FFBC01";
    case "PCA Rejected":
    case "rejected":
      return "#DF4532";
    case "success":
      return "#369D47";
    default:
      return "black";
  }
};

export default getStatusColor;
