/** @jsxImportSource theme-ui */
import { ColumnMenuProps, GridColumnMenu } from "@material-ui/x-grid";
import * as React from "react";

const ColumnMenu = (props: ColumnMenuProps) => {
  return (
    <div
      sx={{
        backgroundColor: "#E9E9EA",
        "li:hover": {
          color: "#FCFCFC",
          backgroundColor: "#1B70BB",
        },
      }}
    >
      <GridColumnMenu {...props} />
    </div>
  );
};
export default ColumnMenu;
