/** @jsxImportSource theme-ui */
import React from "react";
import {
  Box,
  AppBar,
  Grid,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import useRouter from "hooks/use-router";
import { Link } from "react-router-dom";
import { NAVBAR_HEIGHT } from "app-constants";
import { useAuth } from "hooks/use-auth";
import { ReactComponent as LogoutIcon } from "assets/icons/icon-logout.svg";
import useScrollPosition from "@react-hook/window-scroll";
import { ADMIN_ROOT } from "components/router/routes";

const routes = [
  {
    label: "Dashboard",
    path: "/",
  },
  {
    label: "Opportunities",
    path: "/opportunities",
  },
  {
    label: "Administration",
    path: ADMIN_ROOT,
    admin: true,
  },
];

type Props = {
  hasNav?: boolean;
  theme?: "dark" | "light";
};

const NavBar: React.FC<Props> = ({ hasNav, theme }) => {
  const { pathname, push } = useRouter();
  const { signout, user, token } = useAuth();
  const isSignedIn = !!user;
  const isAdmin = true; // replace by user?.admin
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: string
  ) => {
    push(newValue);
  };
  const handleSignout = () => {
    if (!token) {
      throw new Error(`The token should be defined`);
    }

    signout?.mutate({
      token,
    });
  };
  const scrollY = useScrollPosition(60);
  const strippedPathname = `/${pathname.split("/")[1]}`;
  const hasBackground = theme === "dark" || scrollY > 0;
  return (
    <AppBar
      color={hasBackground ? "primary" : "transparent"}
      elevation={0}
      sx={{
        transition: "background-color ease .3s",
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          minHeight: NAVBAR_HEIGHT,
        }}
      >
        <Grid container alignItems="center" justify="space-between">
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            sx={{ flex: 1 }}
          >
            <Grid item xs={6} sm={4} md={2}>
              <Link
                to={{
                  pathname: "/",
                }}
              >
                <Box>
                  <img src="/assets/logotype.svg" alt="logo" />
                </Box>
              </Link>
            </Grid>
            {hasNav && (
              <Grid item>
                <Tabs
                  value={strippedPathname}
                  onChange={handleChange}
                  aria-label="nav tabs"
                  TabIndicatorProps={{
                    style: {
                      height: 6,
                    },
                  }}
                >
                  {routes
                    .filter((route) => (isAdmin ? true : !route.admin))
                    .map(({ label, path }) => (
                      <Tab
                        key={label}
                        value={path}
                        component={Link}
                        to={path}
                        label={<Typography variant="body2">{label}</Typography>}
                        sx={{
                          minHeight: NAVBAR_HEIGHT,
                        }}
                      />
                    ))}
                </Tabs>
              </Grid>
            )}
          </Grid>
          {isSignedIn && (
            <Grid item>
              <Button
                onClick={handleSignout}
                sx={{
                  fontSize: 12,
                  color: "white",
                  lineHeight: "12px",
                }}
                endIcon={<LogoutIcon />}
              >
                Logout
              </Button>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
