/** @jsxImportSource theme-ui */
import {
  Checkbox,
  FormControl,
  Popper,
  PopperProps,
  TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete";
import React from "react";

export interface FilterItem {
  value: string;
  label: string;
}

type Props = {
  className?: string;
  label: string;
  options: FilterItem[];
  selectedOptions?: FilterItem[];
  onFilterChange?: (newSelectedOptions: FilterItem[]) => void;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ color: "primary" }} />;

const RenderOption = (
  option: FilterItem,
  { selected }: AutocompleteRenderOptionState
) => (
  <>
    <Checkbox
      icon={icon}
      checkedIcon={checkedIcon}
      style={{ marginRight: 8 }}
      checked={selected}
    />
    {option.label}
  </>
);

const PopperComponent: React.FC<PopperProps> = (props) => {
  const { style } = props;
  return (
    <Popper
      {...props}
      style={{ ...style, width: "fit-content" }}
      placement="bottom-start"
    />
  );
};

const FilterInput: React.FC<Props> = ({
  className,
  label,
  options,
  selectedOptions,
  onFilterChange,
}) => {
  const handleChange: (
    event: React.ChangeEvent<unknown>,
    value: FilterItem[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<FilterItem> | undefined
  ) => void = (event, value) => {
    onFilterChange?.(value);
  };
  return (
    <FormControl variant="outlined" className={className}>
      <Autocomplete
        data-testid="region-select"
        multiple
        disableCloseOnSelect
        options={options}
        getOptionLabel={(option) => option.value}
        value={selectedOptions}
        onChange={handleChange}
        limitTags={2}
        renderOption={RenderOption}
        renderInput={(params) => {
          return <TextField {...params} variant="outlined" label={label} />;
        }}
        PopperComponent={PopperComponent}
      />
    </FormControl>
  );
};
FilterInput.displayName = "FilterInput";
export default FilterInput;
