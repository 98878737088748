/** @jsxImportSource theme-ui */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type Props = {
  className?: string;
  defaultExpanded?: boolean;
  title: string;
  accordion?: boolean;
  titleProps?: any;
  headerRight?: React.ReactElement;
};

const Block: React.FC<Props> = ({
  className,
  defaultExpanded = true,
  title,
  children,
  accordion,
  titleProps,
  headerRight,
}) => {
  if (accordion) {
    return (
      <Accordion
        defaultExpanded={defaultExpanded}
        className={className}
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          border: "1px solid #D9D9D9",
          borderRadius: 8,
          height: "100%",
          width: "100%",
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            borderBottom: "1px solid #D9D9D9",
            minHeight: "55px",
            backgroundColor: "#FCFCFC",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: "#003E7C",
              letterSpacing: "0.15px",
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ width: "100%" }}>{children}</AccordionDetails>
      </Accordion>
    );
  }
  return (
    <Box
      sx={{
        border: "1px solid #D9D9D9",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      className={className}
    >
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          px: 3,
          borderBottom: "1px solid #D9D9D9",
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            py: 3,
            fontSize: 18,
            fontWeight: 600,
            color: "#003E7C",
            letterSpacing: "0.15px",
          }}
          {...titleProps}
        >
          {title}
        </Typography>
        {headerRight}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
Block.displayName = "Block";
export default Block;
