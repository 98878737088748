/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import OnboardingBar from "components/navigation/OnboardingBar";
import SelectRegions from "components/regions/SelectRegions";
import * as React from "react";

const ChooseRegionsPage: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
        height: "100%",
        minHeight: "100vh",
        px: 20,
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
        }}
      />
      <Grid
        container
        sx={{
          bg: "white",
          borderRadius: 10,
          minHeight: [, 515],
          maxWidth: 1240,
          mx: "auto",
          pt: [30, 0],
        }}
      >
        <Grid item sm={4} md={6} container justify="center" alignItems="center">
          <Grid
            item
            sx={{
              flexBasis: [, "400px"],
              flexGrow: 0,
              pl: [30, , 0],
            }}
          >
            <Box>
              <Typography
                component="h1"
                variant="h2"
                sx={{
                  color: "#EF7B2E",
                }}
              >
                Select the regions
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          sm={8}
          md={6}
          item
          container
          justify="center"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <Grid
            item
            sx={{
              flexGrow: 0,
            }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  ml: 30,
                  mr: [30, 6],
                  mt: 4,
                }}
              >
                Please select the regions hosting the majority of the instances
                and one you want to optimize.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <SelectRegions />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
ChooseRegionsPage.displayName = "ChooseRegionsPage";
export default ChooseRegionsPage;
