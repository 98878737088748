import { useMemo } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import queryString from "query-string";

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
      },
      params,
      useRouteMatch,
      location,
      history,
    };
  }, [params, location, history]);
};
export default useRouter;
