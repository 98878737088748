/** @jsxImportSource theme-ui */
import { Box, Button, Link, Tab, Tabs, Typography } from "@material-ui/core";
import Title from "components/general/Title";
import { useAuth } from "hooks/use-auth";
import useRouter from "hooks/use-router";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Modal from "../general/Modal";
import ClientChangeRequestForm from "./ClientChangeRequestForm";

type Props = {
  className?: string;
  routes: Array<{ title: string; path: string }>;
};

function a11yProps(index: number) {
  return {
    id: `admin-tab-${index}`,
    "aria-controls": `admin-tabpanel-${index}`,
  };
}

const AdminNav: React.FC<Props> = ({ children, routes }) => {
  const router = useRouter();
  const { user } = useAuth();
  const currentAdminSlug = router.location.pathname;
  const handleChange = (event: React.ChangeEvent<unknown>, path: string) => {
    router.replace(path);
  };

  const companyName = user?.company?.name || "Unknown company name";
  const { isAdmin } = useAuth();
  const shouldShowWrapper = React.useMemo((): boolean => {
    return routes.some(({ path }) => path === currentAdminSlug);
  }, [routes, currentAdminSlug]);
  if (!shouldShowWrapper) {
    return <>{children}</>;
  }
  return (
    <Box>
      <Link component={RouterLink} to="/">
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: 14,
            lineHeight: "20px",
          }}
        >
          Back to Dashboard
        </Typography>
      </Link>
      <Title
        sx={{
          mt: 3,
          mb: 3,
        }}
      >
        {companyName}
      </Title>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <Tabs
          value={currentAdminSlug}
          onChange={handleChange}
          aria-label="Admin navigation tabs"
          indicatorColor="primary"
          textColor="primary"
          sx={{ mt: 2 }}
        >
          {routes.map(({ path, title }, index) => {
            return (
              <Tab
                key={path}
                label={title}
                value={path}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
        {!isAdmin && (
          <Modal
            title="Change Request"
            renderButton={({ onClick }) => (
              <Button color="primary" variant="outlined" onClick={onClick}>
                Submit Change Request
              </Button>
            )}
            renderChildren={({ closeModal }) => (
              <ClientChangeRequestForm closeModal={closeModal} />
            )}
          />
        )}
      </Box>
      {children}
    </Box>
  );
};
AdminNav.displayName = "AdminNav";
export default AdminNav;
