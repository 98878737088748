/** @jsxImportSource theme-ui */
import { Typography } from "@material-ui/core";
import * as React from "react";

type Props = {
  className?: string;
};

const Title: React.FC<Props> = (props) => {
  return (
    <Typography
      variant="h4"
      component="h1"
      sx={{ color: "#003E7C" }}
      {...props}
    />
  );
};
Title.displayName = "Title";
export default Title;
