import TagManager from "react-gtm-module";
import env from "./env";

const tagManagerArgs = {
  gtmId: "GTM-PB59M8N",
};
export default function init() {
  if (env("NODE_ENV") === "production") {
    TagManager.initialize(tagManagerArgs);
  }
}
