/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import React from "react";
import { useForm, RegisterOptions } from "react-hook-form";
import { useAuth } from "hooks/use-auth";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import useRouter from "hooks/use-router";
import { authPaths } from "components/router/routes";
import { ToSDialog } from "components/general/ToS";
import fields, { InputField, InputValues } from "./signpupFields";
import ErrorBox from "./ErrorBox";
import ServerError from "./ServerError";
import { PrivacyPolicyDialog } from "../general/PrivacyPolicy";

interface Props {
  onSubmit?: () => void;
}

const Form: React.FC<Props> = () => {
  const { signup } = useAuth();
  const { push } = useRouter();
  const [tosAccepted, setTosAccepted] = React.useState<boolean>(false);
  const [tosDialogOpen, setTosDialogOpen] = React.useState<boolean>(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = React.useState<boolean>(
    false
  );
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState,
  } = useForm<InputValues>({
    mode: `onBlur`,
    defaultValues: fields.reduce(
      (acc, field) => ({ ...acc, [field.props.id]: field.defaultValue }),
      {}
    ),
  });
  const isLoading = signup?.isLoading || signup?.isSuccess;
  const disabled = !tosAccepted || !formState.isValid || isLoading;
  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTosAccepted(event.target.checked);
  };
  const onSubmitHandler = handleSubmit(async (values, event) => {
    event?.preventDefault();
    const { company, email, password, name } = values;

    await signup?.mutateAsync({ company, email, password, name });
    push(`${authPaths.VERIFY}?email=${encodeURIComponent(values.email)}`);
  });

  return (
    <div>
      <form noValidate onSubmit={onSubmitHandler}>
        <Grid item direction="row" container alignItems="center">
          {fields.map((field: InputField) => {
            const {
              id,
              label,
              type,
              validateAgainst,
              validateAgainstMessage,
              minLength,
              pattern,
            } = field.props;
            const error = errors[id];
            const registerOptions: RegisterOptions = {
              required: `You must provide a ${label}`,
              minLength: `${label}`,
            };
            if (validateAgainst) {
              registerOptions.validate = (value) =>
                value === watch(validateAgainst) || validateAgainstMessage;
            }
            if (minLength) {
              registerOptions.minLength = {
                value: minLength,
                message: `${label} must be at least ${minLength} characters`,
              };
            }
            if (pattern) {
              registerOptions.pattern = {
                value: pattern.value,
                message: pattern.message(label),
              };
            }
            return (
              <Grid item xs={12} direction="row" container key={field.props.id}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputRef={register(registerOptions)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={id}
                    label={label}
                    name={id}
                    type={type}
                    sx={{ color: "#90969", mb: 0 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {error && <ErrorBox>{error.message}</ErrorBox>}
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6}>
            <Box mt={2}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Checkbox
                    size="small"
                    checked={tosAccepted}
                    color="primary"
                    onChange={onCheckboxChange}
                  />
                }
                label={
                  <Box sx={{ ml: 2 }}>
                    <Typography>
                      Agree to&nbsp;
                      <Link
                        component="button"
                        variant="body1"
                        onClick={(event: React.MouseEvent) => {
                          event.preventDefault();
                          setTosDialogOpen(true);
                        }}
                      >
                        Terms of Service
                      </Link>
                      &nbsp;and&nbsp;
                      <Link
                        component="button"
                        variant="body1"
                        onClick={(event: React.MouseEvent) => {
                          event.preventDefault();
                          setPrivacyDialogOpen(true);
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={6}>
              <Box mt={3}>
                <Button
                  disabled={disabled}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {isLoading ? (
                    <CircularProgress size={28} color="primary" />
                  ) : (
                    "Create Account"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {signup?.isError && (
        <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <ServerError errors={signup?.error?.graphqlErrors} />
        </Grid>
      )}
      <ToSDialog open={tosDialogOpen} onClose={() => setTosDialogOpen(false)} />
      <PrivacyPolicyDialog
        open={privacyDialogOpen}
        onClose={() => setPrivacyDialogOpen(false)}
      />
    </div>
  );
};
Form.displayName = `Signup Form`;
export default Form;
