/** @jsxImportSource theme-ui */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import numbro from "numbro";
import * as React from "react";
import DashboardCard from "./DashboardCard";

export interface DollarByAssigneeItem {
  assignee: string;
  pending: number;
  scheduled: number;
  rejected: number;
  executed: number;
  total: number;
}

interface Props {
  data?: Array<DollarByAssigneeItem>;
}
const DollarByAssigneeTable: React.FC<Props> = ({ data }) => {
  const tdBodyStyle = {
    fontFamily: "Montserrat",

    fontSize: "16px",
    lineHeight: "22.88px",
    letterSpacing: "0.15px",
    color: "#333",
  };
  const headerTextStyle = {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    color: "#636C74",
  };

  return (
    <DashboardCard title="$ Per Assignee" sx={{ width: "100%" }}>
      <TableContainer
        sx={{
          px: 3,
        }}
      >
        <Table aria-label="Dollars per assignee">
          <TableHead>
            <TableRow sx={{ th: { py: 24 } }}>
              <TableCell
                sx={{
                  width: "16.66%",
                }}
              >
                <Typography sx={headerTextStyle}>Assignee</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={headerTextStyle}>Pending</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={headerTextStyle}>Scheduled</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={headerTextStyle}>Rejected</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={headerTextStyle}>Executed</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={headerTextStyle}>Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index} sx={{ td: { py: 24 } }}>
                <TableCell>
                  <Typography sx={tdBodyStyle}>{row.assignee}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={tdBodyStyle}>
                    {numbro(row.pending).formatCurrency({
                      thousandSeparated: true,
                      mantissa: 2,
                    })}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={tdBodyStyle}>
                    {numbro(row.scheduled).formatCurrency({
                      thousandSeparated: true,
                      mantissa: 2,
                    })}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={tdBodyStyle}>
                    {numbro(row.rejected).formatCurrency({
                      thousandSeparated: true,
                      mantissa: 2,
                    })}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={tdBodyStyle}>
                    {numbro(row.executed).formatCurrency({
                      thousandSeparated: true,
                      mantissa: 2,
                    })}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={tdBodyStyle}>
                    {numbro(row.total).formatCurrency({
                      thousandSeparated: true,
                      mantissa: 2,
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </DashboardCard>
  );
};
DollarByAssigneeTable.displayName = "DollarByAssigneeTable";
export default DollarByAssigneeTable;
