/** @jsxImportSource theme-ui */
import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Icon } from "@material-ui/core";

const ErrorBox: React.FC = ({ children }) => (
  <div
    sx={{
      display: "flex",
      textAlign: "left",
      backgroundColor: "errorBackground",
      color: "linear-gradient(0deg, #F44336, #F44336)",
      py: 3,
      px: 3,
      mt: [1, 3],
      ml: [0, , 2, 3],
      borderRadius: 4,
    }}
  >
    <Icon component={ErrorOutlineIcon} color="error" sx={{ mr: 2 }} />
    {children}
  </div>
);
export default ErrorBox;
