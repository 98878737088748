/** @jsxImportSource theme-ui */
import * as React from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  className?: string;
}
const LinkCell: React.FC<Props> = ({ to, className, children }) => (
  <Link
    to={{ pathname: to }}
    className={className}
    sx={{
      color: "#2F80ED",
      textDecoration: "none",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "16px",
    }}
  >
    {children}
  </Link>
);
export default LinkCell;
