/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import { useDashboardQuery } from "api/jira.service";
import Banner from "components/dashboard/Banner";
import DashboardFilters from "components/dashboard/DashboardFilters";
import DollarByAssigneeTable from "components/dashboard/DollarByAssigneeTable";
import OpportunitiesByAccountTable from "components/dashboard/OpportunitiesByAccountTable";
import OpportunityTable from "components/dashboard/OpportunityTable";
import StatusList from "components/dashboard/status/StatusList";
import Title from "components/general/Title";
import { format } from "date-fns";
import * as React from "react";

const opTableRows = [
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
  {
    opType: "Idle AWS EC2 Instance",
    saving: "$2,636,556.88 (24%)",
    count: "1,556",
  },
];

const dollarsByAssigneeData = [
  {
    assignee: "Andrej Erika",
    pending: 4556,
    scheduled: 0,
    rejected: 0,
    executed: 0,
    total: 4556,
  },
  {
    assignee: "Zakiya Photios",
    pending: 756,
    scheduled: 0,
    rejected: 0,
    executed: 0,
    total: 756,
  },
  {
    assignee: "Ruaraidh Honor",
    pending: 356,
    scheduled: 0,
    rejected: 0,
    executed: 0,
    total: 356,
  },
];

const opportunitiesByAccount = [
  {
    awsAccountId: "123456789012",
    costSavings: 4556,
    issueCount: 1234,
  },
  {
    awsAccountId: "123456789013",
    costSavings: 756,
    issueCount: 12345,
  },
  {
    awsAccountId: "123456789012",
    costSavings: 455,
    issueCount: 123456,
  },
];

const lastUpdateTime = new Date();

const DashboardPage = () => {
  const { data } = useDashboardQuery();
  const onPaginate = (page: number) => null;
  const updateDate = format(lastUpdateTime, "MM/dd/yyyy");
  const updateTime = format(lastUpdateTime, "hh:mm aaa");
  return (
    <Box sx={{ mb: 20, pt: [60, 0] }}>
      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item>
          <Title sx={{ mb: 3 }}>Savings Opportunity</Title>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: 12, letterSpacing: "0.15px" }}>
            Last updated on {updateDate} at {updateTime}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardFilters />
        </Grid>
        <Grid item xs={12}>
          <Banner items={data?.aggregators} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <OpportunityTable
            data={opTableRows}
            loading={false}
            currentPage={1}
            totalPages={2}
            onPaginate={onPaginate}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          {data && (
            <StatusList
              title="Cost Optimization Status"
              items={data?.opCountStatus}
              totalAmount={data?.aggregators[1]?.value as number}
              totalIssues={data?.aggregators[2]?.value as number}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <DollarByAssigneeTable data={dollarsByAssigneeData} />
        </Grid>
        <Grid item xs={12}>
          <OpportunitiesByAccountTable data={opportunitiesByAccount} />
        </Grid>
      </Grid>
    </Box>
  );
};
DashboardPage.displayName = "Dashboard Page";
export default DashboardPage;
