/** @jsxImportSource theme-ui */
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import * as React from "react";
import DashboardCard from "./DashboardCard";

interface Props {
  data?: Array<{ opType: string; saving: string; count: string }>;
  loading?: boolean;
  currentPage: number;
  totalPages: number;
  onPaginate: (page: number) => void;
}
const OpportunityTable: React.FC<Props> = ({
  loading,
  data,
  currentPage,
  totalPages,
  onPaginate,
}) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onPaginate(value);
  };
  const tdBodyStyle = {
    fontFamily: "Montserrat",

    fontSize: "16px",
    lineHeight: "22.88px",
    letterSpacing: "0.15px",
    color: "#1053A4",
  };
  const headerTextStyle = {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    color: "#636C74",
  };

  return (
    <DashboardCard title="Savings Opportunities">
      {loading ? (
        <Box
          justifyContent="center"
          display="flex"
          sx={{ width: "100%", height: "100%", mx: "auto", mt: 4 }}
        >
          <CircularProgress color="primary" variant="indeterminate" />
        </Box>
      ) : (
        <TableContainer
          sx={{
            px: 3,
          }}
        >
          <Table aria-label="ops table">
            <TableHead>
              <TableRow sx={{ th: { py: 24 } }}>
                <TableCell>
                  <Typography sx={headerTextStyle}>Opportunity Type</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={headerTextStyle}>
                    Savings Amount / % of Total
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={headerTextStyle}>Issue Count</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={{
                    td: { py: 24 },
                    "&:hover": {
                      backgroundColor: "#E1F2F9 !important",
                    },
                  }}
                >
                  <TableCell sx={{ pl: 3 }}>
                    <Typography sx={tdBodyStyle}>{row.opType}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography sx={tdBodyStyle}>{row.saving}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography sx={tdBodyStyle}>{row.count}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3} sx={{ borderBottom: "0px" }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{ mt: "9px", ul: { justifyContent: "flex-end" } }}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </DashboardCard>
  );
};
OpportunityTable.displayName = "Ops Table";
export default OpportunityTable;
