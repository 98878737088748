/** @jsxImportSource theme-ui */
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Hero from "components/general/Hero";
import * as React from "react";
import SignInUpWrapper from "components/signinup/SignInUpWrapper";
import ForgotPasswordWrapper from "../components/signinup/ForgotPasswordWrapper";
import useRouter from "../hooks/use-router";

interface SignInUpPageProps {
  variant?: string;
}
const SignInUpPage: React.FC<SignInUpPageProps> = ({ variant }) => {
  const { query } = useRouter();
  const [isForgetPassword, setIsForgetPassword] = React.useState(
    !!query?.["reset-password"]
  );

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(288.06deg, #B5DEEF 1.57%, rgba(27, 112, 187, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Grid
        container
        sx={{ height: [, "100%"] }}
        justify="flex-end"
        alignItems="center"
      >
        <Grid
          item
          sm={4}
          md={5}
          lg={4}
          xl={3}
          sx={{
            pt: [70, 0],
            mb: [35, 0],
          }}
        >
          <Box>
            <Hero
              sx={{ mb: [0, "300px"], height: "100%" }}
              title="You are spending too much in the cloud"
              subtitle="We can help.  Our cloud optimization technology saves companies over 50% on their cloud costs.  Try our free cloud spend diagnostic today to see how much we can save you."
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={7}
          container
          sx={{
            pl: [0, , 4],
          }}
        >
          <Grid
            item
            container
            sx={{
              background: [
                "white",
                "linear-gradient(180deg, #FFFFFF 0%, #E9E9EA 100%)",
              ],
              height: [, 720],
              pt: [36, 80],
              pb: [84, 0],
              px: [36, 0],
            }}
            alignItems="flex-start"
          >
            <Grid item container sx={{ ml: [, 100] }} md={10} lg={9} xl={8}>
              {isForgetPassword ? (
                <ForgotPasswordWrapper
                  onGoBack={() => setIsForgetPassword(false)}
                />
              ) : (
                <SignInUpWrapper
                  variant={variant}
                  handleForgotPassword={() => setIsForgetPassword(true)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
SignInUpPage.displayName = `SignInUpPage`;
export default SignInUpPage;
