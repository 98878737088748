/** @jsxImportSource theme-ui */
import { Box, Button, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as React from "react";

interface ForgotPasswordEmailProps {
  className?: string;
  email: string;
  error?: string;
  handleGoBack: () => void;
  handleGoNext: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
  onChangeEmail: (email: string) => void;
}

const ForgotPasswordEmail: React.FC<ForgotPasswordEmailProps> = ({
  className,
  email,
  error,
  handleGoBack,
  handleGoNext,
  handleSubmit,
  isLoading,
  onChangeEmail,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;
    onChangeEmail(value);
  };
  const sendButtonDisabled = !email || isLoading;
  const hasACodeButtonDisabled = !email;
  return (
    <Grid item sm={6}>
      <Box className={className}>
        <Button
          variant="text"
          color="primary"
          onClick={handleGoBack}
          startIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                fill="#1B70BB"
              />
            </svg>
          }
        >
          Back to ‘Sign In / Sign Up’
        </Button>
        <Typography variant="h4" sx={{ color: "#EF7B2E" }}>
          Forgot Password?
        </Typography>
        <Typography sx={{ mt: 3 }}>
          Enter the email address associated with your account, and we’ll email
          you a code to reset your password.
        </Typography>
        <TextField
          id="forgot-password-form-email"
          name="email"
          label="Email"
          type="email"
          variant="outlined"
          margin="normal"
          fullWidth
          inputProps={{ required: true }}
          value={email}
          onChange={handleChange}
        />
        <Button
          disabled={sendButtonDisabled}
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
        >
          Send Reset Code
        </Button>
        <Button
          disabled={hasACodeButtonDisabled}
          sx={{ mt: 2 }}
          variant="text"
          color="primary"
          onClick={handleGoNext}
          fullWidth
        >
          Already have a code?
        </Button>
        {error && (
          <Box sx={{ backgroundColor: "errorBackground", p: 3 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default ForgotPasswordEmail;
