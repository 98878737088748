/** @jsxImportSource theme-ui */
import DashboardCard from "components/dashboard/DashboardCard";
import * as React from "react";
import { useAuth } from "hooks/use-auth";
import { Button, Grid, Link, Typography } from "@material-ui/core";
import Modal from "components/general/Modal";
import CompanyForm from "components/admin/CompanyForm";

type Props = {
  className?: string;
};

const CompanyDetailsPage: React.FC<Props> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();
  // TODO: take data from company
  const email = "john.doe@umbrellacorp.com";
  const companyName = "Company ABC";
  const items = {
    "Address 1": "1234 Main St.",
    "Address 2": "Suite A",
    City: "Los Angeles",
    State: "California",
    "Zip Code": 90013,
    "Primary Contact": (
      <span>
        John Doe <Link href={`mailto:${email}`}>({email})</Link>
      </span>
    ),
  };
  const itemsEntries = Object.entries(items);
  return (
    <>
      <DashboardCard
        title="Account Information"
        action={
          <Modal
            title={`Add Business Unit to ${companyName}`}
            renderButton={({ onClick }) => (
              <Button
                variant="text"
                color="primary"
                sx={{ height: "100%" }}
                onClick={onClick}
              >
                Edit
              </Button>
            )}
            renderChildren={({ closeModal }) => (
              <CompanyForm company={{}} closeModal={closeModal} />
            )}
          />
        }
      >
        <Grid container spacing={3} sx={{ p: 20 }}>
          {itemsEntries.map(([key, value]) => {
            return (
              <React.Fragment key={key}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ color: "#525252", fontSize: 14 }}>
                    {key}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography sx={{ color: "#161616", fontSize: 14 }}>
                    {value}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </DashboardCard>
    </>
  );
};

CompanyDetailsPage.displayName = "CompanyDetailsPage";
export default CompanyDetailsPage;
