/** @jsxImportSource theme-ui */
import { Grid } from "@material-ui/core";
import { NAVBAR_HEIGHT } from "app-constants";
import NavBar from "components/navigation/NavBar";
import * as React from "react";

const DashboardLayout: React.FC = ({ children }) => {
  return (
    <Grid container direction="column">
      <NavBar theme="dark" hasNav />
      <main sx={{ mt: NAVBAR_HEIGHT + 27, px: 27 }}>{children}</main>
    </Grid>
  );
};
export default DashboardLayout;
