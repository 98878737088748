/** @jsxImportSource theme-ui */
import { Box, Button, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import ErrorBox from "./ErrorBox";

export interface ForgotPasswordNewPasswordProps {
  className?: string;
  error?: string;

  handleChangePassword: (password: string) => void;
  handleGoBack: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
  password: string;
}

const ForgotPasswordNewPassword: React.FC<ForgotPasswordNewPasswordProps> = ({
  className,
  error,
  handleChangePassword,
  handleGoBack,
  handleSubmit,
  isLoading,
  password,
}) => {
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordBlurred, setConfirmPasswordBlurred] = React.useState(
    false
  );

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;
    handleChangePassword(value);
  };
  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    const { value } = event.target;
    setConfirmPassword(value);
  };

  const isSubmitDisabled =
    !password || password !== confirmPassword || isLoading;

  const confirmPasswordError = React.useMemo(() => {
    if (!confirmPasswordBlurred) {
      return null;
    }
    if (password === confirmPassword) {
      return null;
    }
    return "Your passwords must match.";
  }, [password, confirmPassword, confirmPasswordBlurred]);
  return (
    <>
      <Grid item xs={12} sm={6} className={className}>
        <Button
          sx={{ ml: -2 }}
          variant="text"
          color="primary"
          onClick={handleGoBack}
          startIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                fill="#1B70BB"
              />
            </svg>
          }
        >
          Back to ‘Enter code’
        </Button>
        <Typography variant="h4" sx={{ color: "#EF7B2E" }}>
          Reset Password
        </Typography>
        <Typography sx={{ mt: 3, lineHeight: "24px" }}>
          Please enter your new password
        </Typography>
        <TextField
          id="forgot-password-form-new-password"
          name="newPassword"
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          inputProps={{ required: true }}
          value={password}
          onChange={handleChange}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="forgot-password-form-new-password-confirm"
            name="newPasswordConfirm"
            label="Confirm Password"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{ required: true }}
            value={confirmPassword}
            onChange={handleChangeConfirmPassword}
            onBlur={() => setConfirmPasswordBlurred(true)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {confirmPasswordError && <ErrorBox>{confirmPasswordError}</ErrorBox>}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Reset Your Password
        </Button>
        {error && (
          <Box sx={{ backgroundColor: "errorBackground", p: 3, mt: 3 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default ForgotPasswordNewPassword;
