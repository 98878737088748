/** @jsxImportSource theme-ui */
import { Divider, Grid, List, ListItem, Typography } from "@material-ui/core";
import * as React from "react";
import DashboardCard from "../DashboardCard";
import StatusChart from "./StatusChart";
import StatusListItem from "./StatusListItem";

interface ListProps {
  title: string;

  items: StatusItem[] | undefined;
  totalAmount: number;
  totalIssues: number;
}

const StatusList: React.FC<ListProps> = ({
  title,
  items,
  totalAmount,
  totalIssues,
}) => {
  return (
    <DashboardCard title={title}>
      <List aria-label={title}>
        <ListItem>
          <StatusChart
            items={items}
            totalAmount={totalAmount}
            totalIssues={totalIssues}
          />
        </ListItem>
        <ListItem sx={{ my: 2 }}>
          <Grid spacing={1} container justify="space-between">
            <Grid xs={3} item>
              <Typography sx={{ color: "#8A8A8A" }}>Status</Typography>
            </Grid>
            <Grid xs={5} item>
              <Typography align="right" sx={{ color: "#8A8A8A" }}>
                Savings Amount
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography align="right" sx={{ color: "#8A8A8A" }}>
                Issue Count
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Divider sx={{ mx: 2 }} />
        {items?.map((item: StatusItem, index) => (
          <React.Fragment key={`${index}{item.value}`}>
            <StatusListItem {...item} />
            {index < items.length - 1 && <Divider sx={{ mx: 2 }} />}
          </React.Fragment>
        ))}
      </List>
    </DashboardCard>
  );
};
StatusList.displayName = "StatusList";
export default StatusList;
