/** @jsxImportSource theme-ui */
import { Box, Grid } from "@material-ui/core";
import useRouter from "hooks/use-router";
import * as React from "react";

import { onboardingRoutes } from "./onboardingNavigation";

type Props = {
  className?: string;
};

const OnboardingBar: React.FC<Props> = ({ className }) => {
  const { location } = useRouter();
  const currentRouteIndex = React.useMemo(() => {
    return onboardingRoutes.findIndex(({ path }) => {
      return location.pathname === path;
    });
  }, [location.pathname]);
  return (
    <Grid
      container
      className={className}
      sx={{
        mx: "auto",
        maxWidth: 520,
        display: "flex",
        justifyContent: "space-between",
        py: 35,
      }}
    >
      {onboardingRoutes.map(({ path, icon: Icon }, index) => {
        const isCurrentRoute = currentRouteIndex === index;

        const beforeStyle = isCurrentRoute
          ? ({
              content: '""',
              display: "block",
              position: "absolute",
              width: 6,
              height: 6,
              top: -20,
              right: "50%",
              transform: "translateX(50%)",
              background: "#FFFFFF",
              borderRadius: 9999,
            } as const)
          : undefined;
        return (
          <Box
            key={path}
            sx={{
              opacity: isCurrentRoute ? 1 : 0.4,
              position: "relative",
              "::before": beforeStyle,
            }}
            data-testid={`OnboardingIcon-${index}`}
          >
            <Icon />
          </Box>
        );
      })}
    </Grid>
  );
};

OnboardingBar.displayName = "OnboardingBar";
export default OnboardingBar;
