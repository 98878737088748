/** @jsxImportSource theme-ui */
import {
  Container,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Box>
        <Typography variant="h2" sx={{ mb: 4 }}>
          Privacy Statement
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Virtasant, Inc. (the “Company,” “we,” or “us”) respect your privacy.
          This Privacy Policy Statement (“Privacy Policy”) describes the ways we
          collect information from and about you, and what we do with the
          information, so that you may decide whether or not to provide
          information to us. By accessing our website, or purchasing our
          products or services you agree to this Privacy Policy in addition to
          any other agreements we might have with you. In the event that such
          agreements contain terms that conflict with this Privacy Policy, the
          terms of those agreements will prevail. This Privacy Policy does not
          govern the practices of entities that our Company does not own or
          control, or entities that do not own or control our company or people
          that our Company does not employ or manage.
        </Typography>
        <Typography variant="h3" sx={{ mb: 3 }}>
          Company’s Privacy Policy Statement
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          1. Our Collection of Your Personal Information
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          The information we collect may include your name (first and last
          name), position, address, email address, phone number, login
          credentials, human resources data, transaction information, IP
          address, usage data, general demographic information, referring/exit
          pages and URLs, platform types, preferences you submit and preferences
          that are generated based on the data you submit, number of clicks,
          messages, communications, postings, log-ins, video and voice
          recordings, network activity, product and service selections and other
          things that identify you. We collect personal information from you at
          several different points, including but not limited to the following:
        </Typography>
        <ul>
          <li>
            <Typography>
              when we correspond with you as a customer or prospective customer;
            </Typography>
          </li>
          <li>
            <Typography>
              when you visit our website; when you register as an end-user of
              our services and an account is created for you;
            </Typography>
          </li>
          <li>
            <Typography>when you contact us for help;</Typography>
          </li>
          <li>
            <Typography>
              when you attend our customer conferences or webinars; and
            </Typography>
          </li>
          <li>
            <Typography>
              when the site sends us error reports or application analytics
              data.
            </Typography>
          </li>
        </ul>
        <Typography variant="h4" sx={{ mb: 3 }}>
          2. Our Use of Your Personal Information
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Our Company may use information that we collect about you to:
        </Typography>
        <ul>
          <li>
            <Typography>
              deliver the products and services that you have requested;
            </Typography>
          </li>
          <li>
            <Typography>
              manage your customer relationship and provide you with customer
              support;
            </Typography>
          </li>
          <li>
            <Typography>
              help improve our products and services and customize user
              experience;
            </Typography>
          </li>
          <li>
            <Typography>
              perform research and analysis about your use of, or interest in,
              our products, services, or content,
            </Typography>
          </li>
          <li>
            <Typography>
              or products, services or content offered by others;
            </Typography>
          </li>
          <li>
            <Typography>
              communicate with you by e-mail, postal mail, telephone and/or
              mobile devices about products or
            </Typography>
          </li>
          <li>
            <Typography>
              services that may be of interest to you either from us, or other
              third parties;
            </Typography>
          </li>
          <li>
            <Typography>
              develop and display content and advertising tailored to your
              interests on our site and other websites;
            </Typography>
          </li>
          <li>
            <Typography>
              verify your eligibility and deliver prizes in connection with
              promotions, contests and sweepstakes;
            </Typography>
          </li>
          <li>
            <Typography>enforce our terms and conditions;</Typography>
          </li>
          <li>
            <Typography>manage our business; and</Typography>
          </li>
          <li>
            <Typography>
              perform functions as otherwise described to you at the time of
              collection.
            </Typography>
          </li>
        </ul>
        <Typography variant="h4" sx={{ mb: 3 }}>
          3. Our Disclosure of Your Personal Information to Third Parties
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          We may share your personal information with third parties only in the
          ways that are described in this Privacy Policy:
        </Typography>
        <ul>
          <li>
            <Typography>
              we may provide your information to our sub-processors who perform
              functions on our behalf;
            </Typography>
          </li>
          <li>
            <Typography>
              third party contractors may have access to our databases. These
              contractors sign a standard confidentiality agreement;
            </Typography>
          </li>
          <li>
            <Typography>
              we may share your data with any parent company, subsidiaries,
              joint ventures, other entities under a common control or third
              party acquirers. We expect these other entities will honor this
              Privacy Policy;
            </Typography>
          </li>
          <li>
            <Typography>
              we may allow a potential acquirer or merger partner to review our
              databases, although we would restrict their use and disclosure of
              this data during the diligence phase;
            </Typography>
          </li>
          <li>
            <Typography>
              as required by law enforcement, government officials, or other
              third parties pursuant to a subpoena, court order, or other legal
              process or requirement applicable to our Company; or when
            </Typography>
          </li>
          <li>
            <Typography>
              we believe, in our sole discretion, that the disclosure of
              personal information is necessary to prevent physical harm or
              financial loss, to report suspected illegal activity or to
              investigate violations of our agreements or Company policies;
            </Typography>
          </li>
          <li>
            <Typography>
              we may sell or transfer personal information to third parties for
              any legally permissible purpose at our sole discretion; and
            </Typography>
          </li>
          <li>
            <Typography>
              we may share your information with third parties with your consent
              or direction to do so. Please note that these third parties may be
              in other countries where the laws on processing personal
              information may be less stringent than in your country. We reserve
              the right to use, disclose and transfer non-personal information
              to our partners, advertisers and other third parties at our
              discretion.
            </Typography>
          </li>
        </ul>

        <Typography variant="h4" sx={{ mb: 3 }}>
          4. Our Security Measures to Protect Your Personal Information
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Our Company uses industry-standard technologies when transferring and
          receiving data exchanged between our Company and other companies to
          help ensure its security. This site has security measures in place to
          help protect information under our control from the risk of accidental
          or unlawful destruction or accidental loss, alteration or unauthorized
          disclosure or access. However, “perfect security” does not exist on
          the Internet. Also, if this website contains links to other sites, our
          Company is not responsible for the security practices or the content
          of such sites.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          5. Our Use of Cookies, Web Beacons, Web Analytics Services, and Links
        </Typography>
        <ul>
          <li>
            <Typography>
              Cookies. Many of our web pages use “cookies.” Cookies are text
              files we place in your computer’s browser to store your
              preferences. Cookies, by themselves, do not tell us your e-mail
              address or other personally identifiable information unless you
              choose to provide this information to us by, for example,
              registering at one of our sites. However, once you choose to
              furnish the site with personal information, this information may
              be linked to the data stored in the cookie. We use cookies to
              understand site usage and to improve the content and offerings on
              our sites. We also may use cookies to offer you products,
              programs, or services. You have many choices with regards to the
              management of cookies on your computer. All major browsers allow
              you to block or delete cookies from your system. To learn more
              about your ability to manage cookies, please consult the privacy
              features in your browser.
            </Typography>
          </li>
          <li>
            <Typography>
              Web Analytics Services. We use Google Analytics, a service for the
              marketing analysis of the site provided by Google, Inc. Google
              Analytics uses cookies to allow us to see how you use our site so
              we can improve your experience. Google’s ability to use and share
              information collected by Google Analytics about your visits to the
              site is restricted by the Google Analytics Terms of Use available
              at{" "}
              <Link href="https://www.google.com/analytics/terms/us.html">
                https://www.google.com/analytics/terms/us.html
              </Link>{" "}
              and the Google Privacy Policy available at{" "}
              <Link href="https://www.google.com/policies/privacy/">
                https://www.google.com/policies/privacy/
              </Link>
              . You can prevent Google Analytics from recognizing you on return
              visits to the site by disabling cookies in your browser. If you
              prefer to not have data reported by Google Analytics, you can
              install the Google Analytics Opt-out Browser Add-on available at{" "}
              <Link href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </Link>
              .
            </Typography>
          </li>
          <li>
            <Typography>
              Links. We may create links to other web sites. We will make a
              reasonable effort to link only to sites that meet similar
              standards for maintaining each individual’s right to privacy.
              However, many other sites that are not associated with or
              authorized by our Company may have links leading to our site. Our
              Company cannot control these links and we are not responsible for
              any content appearing on these sites. Since this website does not
              control the privacy policies of third parties, you are subject to
              the privacy practices of that third party. We encourage you to ask
              questions before you disclose any personal information to others.
            </Typography>
          </li>
          <li>
            <Typography>
              Other. Our Company websites may use third parties to present or
              serve the advertisements that you may see at its web pages and to
              conduct research about the advertisements and web usage. This
              Privacy Policy does not cover any use of information that such
              third parties may have collected from you or the methods used by
              the third parties to collect that information.
            </Typography>
          </li>
        </ul>

        <Typography variant="h4" sx={{ mb: 3 }}>
          6. Legal Basis for Processing Your Personal data (EEA and Switzerland
          only)
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          With respect to personal data collected from individuals from the
          European Economic Area or Switzerland, our legal basis for collecting
          and using the personal data will depend on the personal data concerned
          and the specific context in which we collect it. Our Company will
          normally collect personal data from you only where: (a) we have your
          consent to do so, (b) where we need the personal data to perform a
          contract with you (e.g. to deliver the services you have requested),
          or (c) where the processing is in our or a third party’s legitimate
          interests (and not overridden by your data protection interests or
          fundamental rights and freedoms).
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          7. Limiting Use, Disclosure, Retention
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Our Company identifies the purposes for which the information is being
          collected before or at the time of collection. The collection of your
          personal data will be limited to that which is needed for the purposes
          identified by our Company. Unless you consent or we are required by
          law, we will only use the personal data for the purposes for which it
          was collected. If our Company will be processing your personal data
          for another purpose later on, our Company will seek your further legal
          permission or consent; except where the other purpose is compatible
          with the original purpose. We will keep your personal data only as
          long as required to serve those purposes. We will also retain and use
          your personal data for as long as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          8. International Transfers of Your Personal data
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          We are a global company. Information collected from you may be stored
          and processed in the European Economic Area, the United States or any
          other country in which our Company or agents or contractors maintain
          facilities, and by accessing our sites and using our services, you
          consent to any such transfer of information outside of your country.
          Such countries may have laws which are different, and potentially not
          as protective, as the laws of your own country. Whenever we share
          personal data originating in the European Economic Area or Switzerland
          we will rely on lawful measures to transfer that data, such as the
          Privacy Shield or the EU standard contractual clauses. If you reside
          in the EEA or other regions with laws governing data collection and
          use, please note that you are agreeing to the transfer of your
          personal data to the United States and other jurisdictions in which we
          operate. By providing your personal data, you consent to any transfer
          and processing in accordance with this Policy.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          9. Accuracy of Personal data
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          We do our best to ensure that the personal data we hold and use is
          accurate. We rely on the customers we do business with to disclose to
          us all relevant information and to inform us of any changes.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          10. Your Access to and Updating of Your Personal data
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Reasonable access to your personal data may be provided upon request
          made to our Company at the contact information provided below. If
          access cannot be provided within that time frame, our Company will
          provide the requesting party a date when the information will be
          provided. If for some reason access is denied, we will provide an
          explanation as to why access has been denied. We may charge a
          reasonable fee in advance for copying and sending the information
          requested.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          If you would like us to delete any personal data held about you, we
          will do so on request unless we need to hold the information as part
          of the provision of products and services to you.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          11. Marketing Communications
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          We offer those who provide personal contact information a means to
          choose how we use the information provided. You may manage your
          receipt of marketing and non- transactional communications by clicking
          on the “unsubscribe” link located on the bottom of our marketing
          emails.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          12. Additional Rights (EEA and Switzerland only)
        </Typography>
        <ul>
          <li>
            <Typography>
              Right of erasure. You may have a broader right to erasure of
              personal data that we hold about you. For example, if it is no
              longer necessary in relation to the purposes for which it was
              originally collected. Please note, however, that we may need to
              retain certain information for record keeping purposes, to
              complete transactions or to comply with our legal obligations,
              among other things.
            </Typography>
          </li>
          <li>
            <Typography>
              Right to object to processing. You may have the right to request
              that we stop processing your personal data and/or to stop sending
              you marketing communications.
            </Typography>
          </li>
          <li>
            <Typography>
              Right to restrict processing. You may have the right to request
              that we restrict processing of your personal data in certain
              circumstances. For example, where you believe that the personal
              data we hold about you is inaccurate or unlawfully held.
            </Typography>
          </li>
          <li>
            <Typography>
              Right to data portability: In certain circumstances, you may have
              the right to be provided with your personal data in a structured,
              machine readable and commonly used format and to request that we
              transfer the personal data to another data controller without
              hindrance.
            </Typography>
          </li>
          <li>
            <Typography>
              If you would like to exercise any of the above rights, please
              contact our support team or contact our Data Protection Officer
              (see our contact details in Section 16 below). We will consider
              your request in accordance with applicable laws. To protect your
              privacy and security, we may take steps to verify your identity
              before complying with the request.
            </Typography>
          </li>
          <li>
            <Typography>
              You also have the right to complain to a data protection authority
              about our collection and use of your personal data. For more
              information, please contact your local data protection authority.
            </Typography>
          </li>
        </ul>

        <Typography variant="h4" sx={{ mb: 3 }}>
          13. Children’s Privacy
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Because of the nature of our business, our services are not designed
          to appeal to minors. We do not knowingly attempt to solicit or receive
          any information from anyone under the age of 13. If you are a parent
          or guardian and you are aware that your child has provided us with
          personal data, please contact us immediately.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          14. Your California Privacy Rights (United States only)
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Our Company does not currently respond to browser “Do Not Track” (DNT)
          signals or other mechanisms. Third parties may collect personal data
          about your online activities over time and across sites when you visit
          the Site or use the Service.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          If you are a California resident, California Civil Code Section
          1798.83 permits you to request certain information regarding our
          disclosure of personal data to third parties for the third parties’
          direct marketing purposes. To make such a request, please contact us
          by sending an e-mail to.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Our site, products, and services are not intended to appeal to minors.
          However, if you are a California resident under the age of 18, and a
          registered user of our Site or Service, California Business and
          Professions Code Section 22581 permits you to request and obtain
          removal of content or information you have publicly posted. To make
          such a request, please send an e-mail with a detailed description of
          the specific content or information to{" "}
          <Link href="mailto:privacy@virtasant.com">privacy@virtasant.com</Link>
          .
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Please be aware that such a request does not ensure complete or
          comprehensive removal of the content or information you have posted
          and that there may be circumstances in which the law does not require
          or allow removal even if requested.
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Under California law, California residents who have an established
          business relationship with us may opt-out of our disclosing personal
          data about them to third parties for their marketing purposes.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          15. Changes to Our Privacy Policy
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          Our Company may amend this Privacy Policy at any time by posting a new
          version. It is your responsibility to review this Privacy Policy
          periodically as your continued use of this website and our products
          and services represents your agreement with the then-current Privacy
          Policy.
        </Typography>
        <Typography variant="h4" sx={{ mb: 3 }}>
          16. Contacting Us
        </Typography>
        <Typography component="p" sx={{ mb: 3 }}>
          We have designated a Data Protection Officer who is responsible for
          monitoring our Company’s ongoing compliance of this Privacy Policy. If
          you have any questions about this Privacy Policy, the practices or
          concerns of this site, please contact us at:{" "}
          <Link href="mailto:privacy@virtasant.com">privacy@virtasant.com</Link>
          .
        </Typography>
        <Typography variant="caption">Last Updated: April 19, 2020.</Typography>
      </Box>
    </Container>
  );
};
export const PrivacyPolicyDialog: React.FC<DialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h5">Privacy Policy</Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mx: -3 }} />
      </DialogTitle>
      <DialogContent>
        <PrivacyPolicy />
      </DialogContent>
    </Dialog>
  );
};
