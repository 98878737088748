/** @jsxImportSource theme-ui */
import { Box, Button, Link, Typography } from "@material-ui/core";
import { GridCellParams, GridColDef } from "@material-ui/x-grid";
import BusinessUnitCreationForm from "components/admin/BusinessUnitCreationForm";
import BusinessUnitItem from "components/admin/BusinessUnitItem";
import { adminPaths } from "components/router/routes";
import { useAuth } from "hooks/use-auth";
import moize from "moize";
import numbro from "numbro";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Modal from "../../components/general/Modal";
import AccountCreationForm from "../../components/admin/AccountCreationForm";

type Props = Record<string, never>;
const rows = [
  {
    id: 1,
    businessUnitId: "bu-1",
    accountId: "acc-1",
    accountName: "AWS name",
    savingsOpportunity: 12_163_685.99,
    pending: 77_890,
    scheduled: 250,
    inProgress: 132,
    executed: 2_345,
    rejected: 8,
    failed: 24,
  },
  {
    id: 2,
    businessUnitId: "bu-1",
    accountId: "acc-1",
    accountName: "AWS name",
    savingsOpportunity: 12_163_685.99,
    pending: 77_890,
    scheduled: 250,
    inProgress: 132,
    executed: 2_345,
    rejected: 8,
    failed: 24,
  },
];

const formatCurrency = moize(
  (value: number) => {
    return numbro(value).formatCurrency({
      thousandSeparated: true,
      mantissa: 2,
    });
  },
  { maxSize: 200 }
);

const renderCurrencyCell = (params: GridCellParams) => {
  return <Typography>{formatCurrency(params.value as number)}</Typography>;
};

const columns: GridColDef[] = [
  {
    field: "accountName",
    headerName: "Account Name",
    flex: 2,
    renderCell: (params: GridCellParams) => {
      const { businessUnitId, accountId } = params.row;

      return (
        <Link
          to={`${adminPaths.BUSINESS_UNITS}/${businessUnitId}/${accountId}`}
          component={RouterLink}
        >
          {params.value}
        </Link>
      );
    },
    headerClassName: "header",
  },
  {
    field: "savingsOpportunity",
    headerName: "Savings Opportunity",
    flex: 1.5,
    renderCell: renderCurrencyCell,
    headerClassName: "header",
  },
  {
    field: "pending",
    headerName: "Pending",
    flex: 1,
    renderCell: renderCurrencyCell,
    headerClassName: "header",
  },
  {
    field: "scheduled",
    headerName: "Scheduled",
    flex: 1,
    renderCell: renderCurrencyCell,
    headerClassName: "header",
  },
  {
    field: "inProgress",
    headerName: "In Progress",
    flex: 1,
    renderCell: renderCurrencyCell,
    headerClassName: "header",
  },
  {
    field: "executed",
    headerName: "Executed",
    flex: 1,
    renderCell: renderCurrencyCell,
    headerClassName: "header",
  },
  {
    field: "rejected",
    headerName: "Rejected",
    flex: 1,
    renderCell: renderCurrencyCell,
    headerClassName: "header",
  },
  {
    field: "failed",
    headerName: "Failed",
    flex: 1,
    renderCell: renderCurrencyCell,
    headerClassName: "header",
  },
];

const businessUnitOptions = [
  {
    name: "Business unit one",
    id: "bu-1",
  },
  {
    name: "Business unit two",
    id: "bu-2",
  },
  {
    name: "Business unit three",
    id: "bu-3",
  },
];
const AdminBusinessUnitPage: React.FC<Props> = () => {
  const { isAdmin } = useAuth();
  const companyName = "Company ABC";
  return (
    <Box sx={{ width: "100%" }}>
      {isAdmin && (
        <Box
          sx={{
            display: [, "flex"],
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Modal
              title={`Add Business Unit to ${companyName}`}
              renderButton={({ onClick }) => (
                <Button variant="contained" color="primary" onClick={onClick}>
                  Add Business Unit
                </Button>
              )}
              renderChildren={({ closeModal }) => (
                <BusinessUnitCreationForm
                  company={{}}
                  closeModal={closeModal}
                />
              )}
            />
            <Modal
              title={`Add Account to ${companyName}`}
              renderButton={({ onClick }) => (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: [, 3] }}
                  onClick={onClick}
                >
                  Add Account
                </Button>
              )}
              renderChildren={({ closeModal }) => (
                <AccountCreationForm
                  businessUnitOptions={businessUnitOptions}
                  closeModal={closeModal}
                />
              )}
            />
          </Box>
          <Box>
            <Button
              variant="text"
              color="primary"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z"
                    fill="#2F80ED"
                  />
                </svg>
              }
            >
              Account Template CSV
            </Button>
            <Button variant="outlined" color="primary" sx={{ ml: [, 3] }}>
              Upload CSV
            </Button>
          </Box>
        </Box>
      )}
      <Box sx={{ pt: 3 }}>
        <BusinessUnitItem
          defaultExpanded
          title="Business Unit One"
          columns={columns}
          rows={rows}
        />
        <BusinessUnitItem
          defaultExpanded={false}
          title="Business Unit Two"
          columns={columns}
          rows={rows}
        />
        <BusinessUnitItem
          defaultExpanded={false}
          title="Business Unit Three"
          columns={columns}
          rows={rows}
        />
      </Box>
    </Box>
  );
};

AdminBusinessUnitPage.displayName = "AdminBusinessUnitPage";
export default AdminBusinessUnitPage;
