import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 960,
      md: 1280,
      lg: 1600,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [`Montserrat`].join(`,`),
    button: {
      fontSize: `1rem`,
      textTransform: `none`,
    },
    // Display 1
    h1: {
      fontWeight: "bold",
      fontSize: "80px",
      lineHeight: 1.1,
      letterSpacing: "-2px",
    },
    // Display 2
    h2: {
      fontWeight: "bold",
      fontSize: "52px",
      lineHeight: 1.15,
    },
    // Headline 2
    h3: {
      fontWeight: "bold",
      fontSize: "32px",
      lineHeight: 1.25,
    },
    // Headline 3
    h4: {
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: 1.14,
    },
    // Subheadline
    subtitle1: {
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: 1.33,
    },
    // Label Large
    subtitle2: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 2,
    },
    // Body Copy
    body1: {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: 1.375,
    },
    // Small Text
    body2: {
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: 1.33,
    },
    // Label
    caption: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: 1.4,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#0B1117",
      },
    },
    MuiLink: {
      root: {
        fontWeight: 600,
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: `#FCFCFC`,
      },
    },
    MuiInputLabel: {
      root: {
        color: `#90969`,
      },
    },
  },
  palette: {
    primary: {
      main: `#2F80ED`,
    },
    secondary: {
      main: `#AE2673`,
    },
    purple: {
      main: "#77327",
      400: "#DFC9E1",
    },
    error: {
      main: red.A400,
      400: `#F8D0C9`,
    },
    background: {
      default: `#fff`,
    },
  },
});
const responsiveTheme = responsiveFontSizes(theme);
export default responsiveTheme;
