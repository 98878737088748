const CATEGORIES_COLORS = [
  "#1053A4",
  "#43AF7B",
  "#6B2C73",
  "#DF4532",
  "#1B70BB",
  "#4FB563",
  "#944A9B",
  "#EF7B2E",
  "#0990CB",
  "#88C432",
  "#C33B90",
  "#D78E11",
  "#088BA4",
  "#C8E68F",
  "#D576B1",
  "#FFD602",
  "#5F9EEE",
  "#CFEDE0",
  "#EDC4DE",
  "#FFE24E",
  "#5FCAD7",
  "#E8F4CF",
  "#FCECE9",
  "#FFF3B3",
];

export default CATEGORIES_COLORS;
