/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import React from "react";
import { useAuth } from "hooks/use-auth";
import ReactHookFormSelect from "../general/ReactHookFormSelect";

const requestTopics = [
  {
    name: "Add / Remove Business Unit",
  },
  {
    name: "Add / Remove User",
  },
  {
    name: "Update / Edit Company Details",
  },
];

interface FormValues {
  name: string;
  email: string;
  requestTopic: string;
  requestDescription: string;
}
interface ClientChangeRequestFormProps {
  // Should be company fragment but it doesn't has required props
  closeModal: () => void;
}
const ClientChangeRequestForm: React.FC<ClientChangeRequestFormProps> = ({
  closeModal,
}) => {
  const { user } = useAuth();
  const { register, handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      name: user?.name || "",
      email: user?.email || "",
      requestTopic: "",
      requestDescription: "",
    },
  });

  const onSubmit = handleSubmit(async (_values) => {
    // call mutation
    closeModal();
  });
  return (
    <Box component="form" onSubmit={onSubmit} sx={{ pb: 3, maxWidth: 550 }}>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <TextField
            id="user-form-name"
            name="name"
            label="Name"
            variant="outlined"
            margin="none"
            fullWidth
            inputProps={{ required: true }}
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="user-form-email"
            name="email"
            label="Email Address"
            variant="outlined"
            margin="none"
            fullWidth
            inputProps={{ required: true }}
            inputRef={register({ required: true })}
            type="email"
          />
        </Grid>

        <Grid item sm={12}>
          <ReactHookFormSelect
            control={control}
            defaultValue=""
            id="user-form-request-topic"
            data-testid="user-form-request-topic"
            name="requestTopic"
            fullWidth
            variant="outlined"
            label="Request Topic"
            required
            inputProps={{ required: true }}
          >
            {requestTopics.map(({ name }) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="user-form-request-description"
            name="request-description"
            label="Request Description"
            variant="outlined"
            margin="none"
            fullWidth
            inputProps={{ required: true }}
            inputRef={register({ required: true })}
            type="text"
            multiline
            rows={12}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 4, mx: -3 }} />
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={closeModal}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

ClientChangeRequestForm.displayName = "ClientChangeRequestForm";
export default ClientChangeRequestForm;
