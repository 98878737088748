/** @jsxImportSource theme-ui */
import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  items: Array<{ key: string; value: string }>;
  keyProps?: any;
  valueProps?: any;
  label?: {
    key: string;
    value: string;
  };
  divider?: boolean;
}
const kvStyle = {
  fontSize: "14px",
  color: "#8A8A8A",
  maxWidth: "250px",
};

const ListBlock: React.FC<Props> = ({
  items,
  keyProps,
  valueProps,
  label,
  divider,
}) => {
  return (
    <Grid container sx={{ mx: 3, py: 3 }} spacing={2}>
      {label ? (
        <>
          <Grid item xs={3} {...keyProps}>
            <Typography sx={kvStyle}>{label.key}</Typography>
          </Grid>
          <Grid item xs={9} {...valueProps}>
            <Typography sx={kvStyle}>{label.value}</Typography>
          </Grid>
          {divider ? (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          ) : null}
        </>
      ) : null}
      {items.map((item) => (
        <React.Fragment key={item.key}>
          <Grid item xs={3} {...keyProps}>
            {item.key}
          </Grid>
          <Grid item xs={9} {...valueProps}>
            {item.value}
          </Grid>
          {divider ? (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          ) : null}
        </React.Fragment>
      ))}
    </Grid>
  );
};
ListBlock.displayName = "ListBlock";
export default ListBlock;
