/** @jsxImportSource theme-ui */
import * as React from "react";
import { Box } from "@material-ui/core";
import {
  XGrid,
  GridCellParams,
  GridColDef,
  GridRowsProp,
} from "@material-ui/x-grid";
import CustomPagination from "components/general/CustomPagination";
import numbro from "numbro";
import { FilterList } from "@material-ui/icons";
import StatusChip from "components/general/StatusChip";
import ColumnMenu from "components/general/ColumnMenu";
import LinkCell from "components/general/LinkCell";

interface Props {
  data?: Array<any>;
  loading?: boolean;
  currentPage: number;
  totalPages: number;
  onPaginate: (page: number) => void;
}

const OpportunitiesDataGrid: React.FC<Props> = ({
  loading,
  data,
  currentPage,
  totalPages,
  onPaginate,
}) => {
  const Pagination = () => (
    <CustomPagination
      onPaginate={(event, newValue) => onPaginate(newValue)}
      page={currentPage}
      total={totalPages}
    />
  );

  const columns: GridColDef[] = [
    {
      field: "jiraSOUTicket",
      headerName: "Key",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <LinkCell to={`/opportunities/${params.row.id}`}>
          {params.value as string}
        </LinkCell>
      ),
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
      renderCell: (params: GridCellParams) => (
        <StatusChip status={params.value as string} />
      ),
    },
    {
      field: "checkName",
      headerName: "Opportunity Type",
      flex: 1,
      headerClassName: "header",
      cellClassName: "cell",
    },
    {
      field: "time",
      headerName: "Created",
      flex: 1,
      headerClassName: "header",
      cellClassName: "cell",
    },
    {
      field: "savings",
      headerName: "Cost Savings",
      flex: 1,
      headerClassName: "header",
      cellClassName: "cell",
      valueFormatter: (params: GridCellParams) =>
        numbro(params.value).formatCurrency({
          thousandSeparated: true,
          mantissa: 2,
        }),
    },
    {
      field: "resourceId",
      headerName: "Resource ID",
      flex: 1,
      headerClassName: "header",
      cellClassName: "cell",
    },
    {
      field: "account",
      headerName: "Account ID",
      flex: 1,
      headerClassName: "header",
      cellClassName: "cell",
    },
  ];
  return (
    <Box>
      <XGrid
        pagination
        paginationMode="server"
        rows={(data as unknown) as GridRowsProp[]}
        loading={loading}
        columns={columns}
        disableSelectionOnClick
        autoHeight
        sx={{
          px: 3,
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-colCellSorted": {
            color: "#1B70BB !important",
            ".MuiDataGrid-iconButtonContainer > div > button": {
              color: "#1B70BB !important",
            },
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#E1F2F9 !important",
          },
          ".MuiBadge-badge": {
            marginTop: "6px",
            height: "14px",
            width: "14px",
            fontSize: "10px",
          },

          ".MuiBadge-root > button": {
            color: "#1B70BB !important",
          },
          "& .MuiBadge-root > span": {
            color: "white",
            backgroundColor: "#DF4532",
          },

          "& .header": {
            fontFamily: "Montserrat",
            color: "#8A8A8A",
            fontSize: "16px",
          },
          "& .cell": {
            fontFamily: "Montserrat",
            color: "#333333",
            fontSize: "16px",
            lineHeight: "22.88px",
          },
        }}
        components={{
          Pagination,
          ColumnMenu,
          ColumnFilteredIcon: () => <FilterList fontSize="default" />,
        }}
      />
    </Box>
  );
};
OpportunitiesDataGrid.displayName = "Ops Table";
export default OpportunitiesDataGrid;
