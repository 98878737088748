/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import OnboardingBar from "components/navigation/OnboardingBar";
import VerifyForm from "components/verify/VerifyForm";
import VerifySendAgain from "components/verify/VerifySendAgain";
import * as React from "react";

const VerifyPage: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        background: `linear-gradient(288.06deg, #B5DEEF 1.57%, rgba(27, 112, 187, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        height: "100%",
        minHeight: "100vh",
        px: 20,
      }}
    >
      <OnboardingBar
        sx={{
          mt: [30, 88],
        }}
      />
      <Grid
        container
        sx={{
          bg: "white",
          borderRadius: 10,
          minHeight: 515,
          maxWidth: 1240,
          mx: "auto",
          px: 30,
        }}
      >
        <Grid item sm={4} md={6} container justify="center" alignItems="center">
          <Grid
            item
            sx={{
              flexBasis: ["320px", , "400px"],
              flexGrow: 0,
              pl: [, 30, 0],
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              sx={{
                color: "#1B70BB",
              }}
            >
              Verify your account
            </Typography>
            <VerifySendAgain />
          </Grid>
        </Grid>
        <Grid item sm={8} md={6}>
          <VerifyForm />
        </Grid>
      </Grid>
    </Box>
  );
};
VerifyPage.displayName = "VerifyPage";
export default VerifyPage;
