export interface InputValues {
  company: string;
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface InputField {
  defaultValue: string;
  props: {
    id: keyof InputValues;
    label: string;
    type?: string;
    validateAgainst?: keyof InputValues;
    validateAgainstMessage?: string;
    minLength?: number;
    pattern?: {
      value: RegExp;
      message: (label: string) => string;
    };
  };
}

const fields: Array<InputField> = [
  {
    defaultValue: ``,
    props: {
      label: "Company Name",
      id: "company",
    },
  },
  {
    defaultValue: ``,
    props: {
      label: "Full Name",
      id: "name",
    },
  },
  {
    defaultValue: ``,
    props: {
      label: "Email",
      id: "email",
    },
  },
  {
    defaultValue: ``,
    props: {
      label: "Password",
      id: "password",
      type: "password",
      minLength: 8,
      pattern: {
        value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
        message: (label) =>
          `${label} must include lowercase uppercase and digit characters`,
      },
    },
  },
  {
    defaultValue: ``,
    props: {
      label: "Confirm Password",
      id: "confirmPassword",
      type: "password",
      validateAgainst: "password",
      validateAgainstMessage: "Confirm password should match password",
    },
  },
];
export default fields;
