/** @jsxImportSource theme-ui */
import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import BarChartComponent, { BarChartData } from "./BarChartComponent";

type Props = {
  className?: string;
  barChartData: BarChartData;
};

const SavingOpportunities: React.FC<Props> = ({ className, barChartData }) => {
  return (
    <Box className={className}>
      <Typography
        variant="h2"
        sx={{
          mb: [, 30],
          color: "#414D56",
          textAlign: "center",
        }}
      >
        Here is how your annual run costs will change after your spend is
        optimized
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mb: [, 30],
          color: "#414D56",
          textAlign: "left",
        }}
      >
        Our analysis is using only a small set of your usage data. With more
        data such as memory and storage analytics, we can generally more than
        double the savings identified.
      </Typography>

      <Box sx={{ mx: [, 40, , 80] }}>
        <BarChartComponent
          data={barChartData}
          sx={{
            mx: "auto",
          }}
        />
      </Box>
      <Box
        sx={{
          borderBottom: `1px solid #BCC0C3`,
          maxWidth: 615,
          mt: 70,
          width: "100%",
          mx: "auto",
        }}
      />
    </Box>
  );
};
SavingOpportunities.displayName = "SavingOpportunities";
export default SavingOpportunities;
