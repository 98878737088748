/** @jsxImportSource theme-ui */
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as IconStateCompleted } from "assets/icons/icon-state-completed.svg";
import { ReactComponent as IconStateFailed } from "assets/icons/icon-state-failed.svg";
import { ReactComponent as IconStateRejected } from "assets/icons/icon-state-rejected.svg";
import React from "react";
import { OpportunityError } from "./opportunityConstants";

type Props = {
  currentStep: number;
  className?: string;
  error?: OpportunityError | null;
};

const steps = [
  { label: "Pending", backgroundColor: "#1AB3C6" },
  { label: "Scheduled", backgroundColor: "#C33B90" },
  { label: "In Progress", backgroundColor: "#0573B8" },
  { label: "Executed", backgroundColor: "#4FB563" },
];

const StepIconCompleted = () => <IconStateCompleted />;
const StepIconFailed = () => <IconStateFailed />;
const StepIconRejected = () => <IconStateRejected />;

const ProgressStepper: React.FC<Props> = ({
  className,
  currentStep,
  error,
}) => {
  const hasError = !!error;
  return (
    <Stepper activeStep={currentStep} className={className}>
      {steps.map(({ label, backgroundColor }, index) => {
        const isCurrentStep = index === currentStep;
        const isCompleted = currentStep > index;
        const isCompletedOrCurrent = isCompleted || isCurrentStep;
        const currentStepHasError = isCurrentStep && hasError;
        const isFutureStep = !isCompletedOrCurrent;
        let stepBackgroundColor = "#BCC0C3";
        let stepTextColor = "#636C74";
        if (isCompletedOrCurrent && !currentStepHasError) {
          stepBackgroundColor = backgroundColor;
          stepTextColor = "white";
        } else if (currentStepHasError && error === OpportunityError.Failed) {
          stepBackgroundColor = "rgba(5, 115, 184, .3)";
          stepTextColor = "#1B70BB";
        }

        let stepOpacity = 1;
        if (isFutureStep) {
          stepOpacity = 0.3;
        }
        let labelToDisplay = label;
        let helpText;
        if (isCompleted) {
          helpText = "Completed";
        } else if (currentStepHasError) {
          if (error === OpportunityError.Rejected) {
            labelToDisplay = "Rejected";
          } else {
            helpText = error;
          }
        }

        let StepIconComponent: React.ElementType<unknown> | undefined;
        if (isCompleted) {
          StepIconComponent = StepIconCompleted;
        } else if (currentStepHasError) {
          if (
            error === OpportunityError.Rejected ||
            error === OpportunityError.Canceled
          ) {
            StepIconComponent = StepIconRejected;
          } else if (error === OpportunityError.Failed) {
            StepIconComponent = StepIconFailed;
          }
        }

        return (
          <Step
            key={label}
            sx={{
              backgroundColor: stepBackgroundColor,
              borderRadius: 100,
              py: "4px",
              width: 150,
              opacity: stepOpacity,
            }}
          >
            <StepLabel
              StepIconComponent={StepIconComponent}
              sx={{
                position: "relative",
                overflow: "visible",
                ".MuiStepLabel-label": {
                  color: stepTextColor,
                },
                ".MuiStepLabel-iconContainer": {
                  pr: 16,
                },
                ".MuiStepIcon-root.MuiStepIcon-active": {
                  color: "rgba(0,0,0,.38)",
                },
              }}
            >
              {labelToDisplay}
              {helpText && (
                <Typography
                  sx={{
                    position: "absolute",
                    top: 30,
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#90969C",
                  }}
                >
                  {helpText}
                </Typography>
              )}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
ProgressStepper.displayName = "ProgressStepper";
export default ProgressStepper;
