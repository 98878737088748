/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import ConnectHero from "components/connect/ConnectHero";
import SelectTemplate from "components/connect/SelectTemplate";
import OnboardingBar from "components/navigation/OnboardingBar";
import * as React from "react";

const ConnectPage: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png)`,
        height: "100%",
        minHeight: "100vh",
        px: 20,
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
        }}
      />
      <Grid
        container
        sx={{
          bg: "white",
          borderRadius: 10,
          minHeight: [, 515],
          maxWidth: ["100%", 1240],
          mx: "auto",
          pt: [20, 0],
        }}
      >
        <Grid item sm={4} md={6} container justify="center" alignItems="center">
          <Grid
            item
            sx={{
              flexBasis: [, "400px"],
              flexGrow: 0,
            }}
          >
            <ConnectHero />
          </Grid>
        </Grid>
        <Grid
          item
          sm={8}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            pl: 3,
            pr: 3,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mt: 4,
            }}
          >
            Please create a cross account role to provide access to diagnostic
            tool. Select the primary region for your AWS account from the
            drop-down below.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#414D56",
              my: 4,
            }}
          >
            Note: Before clicking on the “Run Script” button, please make sure
            in a sepetate browser tab to log into your AWS account. Then click
            “Run Script” to create the cross account role.
          </Typography>
          <Box sx={{ mb: 24 }}>
            <SelectTemplate />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
ConnectPage.displayName = "ConnectPage";
export default ConnectPage;
