import { ResponseError } from "api/errors";
import {
  GetProfileQuery,
  SigninMutation,
  SigninMutationVariables,
  SignoutMutation,
  SignoutMutationVariables,
  SignupMutation,
  SignupMutationVariables,
  useGetProfileQuery,
  UserFFragment,
  useSigninMutation,
  useSignoutMutation,
  useSignupMutation,
} from "generated/graphql";
import useToken from "hooks/use-token";
import React, { createContext, useContext, useState } from "react";
import { UseMutationResult, UseQueryResult } from "react-query";

type Mutation<Response, Input> = UseMutationResult<
  Response,
  ResponseError,
  Input,
  unknown
> | null;

export interface AuthContext {
  token: string | null;
  user: UserFFragment | null;
  isAdmin: boolean;
  signin: Mutation<SigninMutation, SigninMutationVariables>;
  signout: Mutation<SignoutMutation, SignoutMutationVariables>;
  signup: Mutation<SignupMutation, SignupMutationVariables>;
  profileQuery: UseQueryResult<GetProfileQuery, ResponseError> | null;
  isLoading: boolean;
}
const authContext = createContext<AuthContext>({
  token: null,
  user: null,
  isAdmin: false,
  signin: null,
  signout: null,
  signup: null,
  profileQuery: null,
  isLoading: true,
});

const useAuthProvider = () => {
  const [token, setToken] = useToken();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const profileQuery = useGetProfileQuery(
    {},
    {
      enabled: isAuthenticated && !!token,
      retry: (failureCount, error) => {
        if (error?.graphqlErrors?.[0]?.message?.startsWith("Unauthorized")) {
          setToken(null);
          setIsAuthenticated(false);
          window.location.reload();
          return false;
        }
        return failureCount < 3;
      },
    }
  );

  const user = profileQuery.data?.me || null;
  const signin = useSigninMutation({
    onSuccess: (data) => {
      const newToken = data?.Signin?.token;
      if (!newToken) {
        throw new Error(`Signin mutation didn't respond with a token`);
      }
      setToken(newToken);
      setTimeout(() => {
        // fix racing condition, when the token was set, the app tries directly
        // to fetch the profile data directly, and fetch does not have enough
        // time to get the content
        setIsAuthenticated(true);
      }, 500);
      window.location.reload();
    },
  });
  const signup = useSignupMutation();

  const signout = useSignoutMutation({
    onSuccess: () => {
      setToken(null);
      setIsAuthenticated(false);
      window.location.reload();
    },
  });

  return {
    token,
    user,
    isAdmin: !!user,
    signin,
    signout,
    signup,
    profileQuery,

    isLoading: !!token && !user,
  };
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}> {children}</authContext.Provider>;
};

export const useAuth = (): AuthContext => {
  return useContext(authContext);
};
