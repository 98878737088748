/** @jsxImportSource theme-ui */
import { Box, Grid, GridSize, Typography } from "@material-ui/core";
import React from "react";
import numbro from "numbro";

interface Props {
  items?: AggregatedItem[];
  breakpoints?: {
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
  };
}
const Banner: React.FC<Props> = ({
  items,
  breakpoints = { xs: 12, sm: 6, md: 4 },
}) => {
  return (
    <Grid
      container
      sx={{
        textAlign: "center",
      }}
      spacing={3}
    >
      {items?.map((item: AggregatedItem) => {
        return (
          <Grid item {...breakpoints} sx={{}} key={item.label}>
            <Box
              sx={{
                borderRadius: 10,
                backgroundColor: "#E1F2F9",
                py: 4,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, color: "#003E7C" }}
              >
                {item.label}
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontWeight: 600, color: "#AE2673", fontSize: 42 }}
              >
                {numbro(item.value)[
                  item.variant === "money" ? "formatCurrency" : "format"
                ]({
                  thousandSeparated: true,
                })}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
Banner.displayName = "Banner";
export default Banner;
