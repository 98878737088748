/** @jsxImportSource theme-ui */

import { Box, Button, Divider, Grid, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import React from "react";

interface FormValues {
  businessUnitName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}
interface Props {
  // Should be company fragment but it doesn't has required props
  company: any;
  closeModal: () => void;
}
const BusinessUnitCreationForm: React.FC<Props> = ({ company, closeModal }) => {
  const { register, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      businessUnitName: company?.businessUnitName || "",
      address1: company?.address1 || "",
      address2: company?.address2 || "",
      city: company?.city || "",
      state: company?.state || "",
      zip: company?.zip || "",
    },
  });

  // eslint-disable-next-line
  const commonSubmit = async (values: FormValues) => {
    // call mutation
  };
  const onSubmit = handleSubmit(async (values) => {
    await commonSubmit(values);
    closeModal();
  });
  const handleSaveAndCreate = handleSubmit(async (values) => {
    await commonSubmit(values);
    reset();
  });
  return (
    <Box component="form" onSubmit={onSubmit} sx={{ pb: 3 }}>
      <TextField
        id="bu-form-name"
        name="businessUnitName"
        label="Business Unit Name"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />
      <TextField
        id="bu-form-address1"
        name="address1"
        label="Address 1"
        variant="outlined"
        margin="normal"
        fullWidth
        inputRef={register()}
      />
      <TextField
        id="bu-form-address2"
        name="address2"
        label="Address 2"
        variant="outlined"
        margin="normal"
        fullWidth
        inputRef={register()}
      />
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            id="bu-form-city"
            name="city"
            label="City"
            variant="outlined"
            margin="normal"
            fullWidth
            inputRef={register()}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            id="bu-form-state"
            name="state"
            label="State"
            variant="outlined"
            margin="normal"
            fullWidth
            inputRef={register()}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            id="bu-form-zip"
            name="zip"
            label="Zip"
            variant="outlined"
            margin="normal"
            fullWidth
            inputRef={register()}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 4, mx: -3 }} />
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            sx={{ width: 90 }}
          >
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={handleSaveAndCreate}
          >
            Save and Create Another
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="text"
            sx={{ width: 90 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
BusinessUnitCreationForm.displayName = "BusinessUnitCreationForm";
export default BusinessUnitCreationForm;
