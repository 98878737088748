/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import whereIsArnImage from "assets/where-is-arn.jpg";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
}
const WhereToFindARN: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h5">Where do I find my ARN code?</Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mx: -3 }} />
      </DialogTitle>
      <DialogContent>
        <img
          src={whereIsArnImage}
          alt="Where to find the ARN code"
          sx={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </DialogContent>
      <Box>
        <Divider sx={{ mx: 1 }} />
        <Button
          color="primary"
          variant="outlined"
          sx={{ my: 3, mx: 3 }}
          onClick={() => onClose()}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

WhereToFindARN.displayName = "WhereToFindARN";
export default WhereToFindARN;
