/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Alert } from "@material-ui/lab";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import { useAwsRegionsQuery, useSetupRegionsMutation } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import React, { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ color: "primary" }} />;

const SelectRegions = () => {
  const { user, profileQuery } = useAuth();
  const [regions, setRegions] = useState<string[]>([]);
  const regionsQuery = useAwsRegionsQuery();
  const setupRegions = useSetupRegionsMutation();

  const handleChange: (
    event: React.ChangeEvent<unknown>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => void = (event, value) => {
    setRegions(value);
  };

  const handleSubmit = async () => {
    const companyUuid = user?.company?.uuid;
    if (!companyUuid) {
      throw new Error(`CompanyUuid should be defined`);
    }
    if (!regions.length) {
      throw new Error(`region should be set`);
    }
    await setupRegions.mutateAsync({ company: companyUuid, regions });
    await profileQuery?.refetch();
  };

  const availableRegions = (regionsQuery.data?.aws?.regions as string[]) || [];
  const isLoading = setupRegions.isLoading || setupRegions.isSuccess;
  const isNextButtonDisabled = !regions.length || isLoading;

  return (
    <Container maxWidth="md" sx={{ mt: 3, px: 30 }}>
      <FormControl variant="outlined" sx={{ minWidth: ["100%", 395] }}>
        <Autocomplete
          data-testid="region-select"
          multiple
          disableCloseOnSelect
          options={availableRegions}
          getOptionLabel={(option) => option}
          value={regions}
          onChange={handleChange}
          limitTags={2}
          sx={{ width: ["100%", 500], maxWidth: "100%" }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Hosting Regions"
            />
          )}
        />
      </FormControl>
      <Box sx={{ textAlign: "end", mt: 3, maxWidth: 818 }}>
        <Box>
          {setupRegions?.isError &&
            setupRegions?.error?.graphqlErrors?.map((error, index) => {
              return (
                <Alert severity="error" key={`${error.message}-${index}`}>
                  {error.message}
                </Alert>
              );
            })}
        </Box>
        <Button
          disabled={isNextButtonDisabled}
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          sx={{
            px: 4,
            position: [, "absolute"],
            right: [, 20],
            bottom: [, 20],
            mt: [4, 0],
            minWidth: ["100%", 250],
          }}
        >
          {isLoading ? <CircularProgress size={26} color="primary" /> : "Next"}
        </Button>
      </Box>
    </Container>
  );
};
export default SelectRegions;
