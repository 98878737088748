/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTemplateQuery } from "api/aws.service";
import { useAwsRegionsQuery, useSetupScriptMutation } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import useRouter from "hooks/use-router";
import React, { useState } from "react";
import { generateUrl } from "./connect-utils";
import DisplayTemplate from "./Template";

const SelectTemplate = () => {
  const { user, profileQuery } = useAuth();
  const setupScript = useSetupScriptMutation();
  const { push } = useRouter();
  const [region, setRegion] = useState<string>("");
  const templateQuery = useTemplateQuery();
  const regionsQuery = useAwsRegionsQuery();

  const isLoading = setupScript.isLoading || setupScript.isSuccess;
  const disabled = isLoading || !templateQuery.isSuccess || !region;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRegion(event.target.value as string);
  };

  const handleSubmit = async () => {
    const companyUuid = user?.company?.uuid;
    if (!companyUuid) {
      throw new Error(`CompanyUuid should be defined`);
    }
    if (!region) {
      throw new Error(`region should be set`);
    }
    await setupScript?.mutateAsync({ region });
    await profileQuery?.refetch();
    const url = generateUrl(companyUuid, region);
    push("/verify-aws-setup");
    window.open(url, "_blank");
  };
  const availableRegions = (regionsQuery.data?.aws?.regions as string[]) || [];
  return (
    <Box sx={{ marginTop: 3 }}>
      <DisplayTemplate
        template={templateQuery.data}
        isLoading={templateQuery.isLoading}
        error={templateQuery.error}
      />

      <Box sx={{ textAlign: "end", mt: 3 }}>
        <Box>
          {setupScript?.isError &&
            setupScript?.error?.graphqlErrors?.map((error, index) => {
              return (
                <Alert severity="error" key={`${error.message}-${index}`}>
                  {error.message}
                </Alert>
              );
            })}

          {templateQuery?.isError && templateQuery?.error && (
            <Alert severity="error">{templateQuery?.error}</Alert>
          )}
        </Box>
        <Grid container sx={{ mt: 4 }}>
          <Grid item sx={{ width: "100%" }} xs={12} sm={8}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel>Region to Run Cloud Template</InputLabel>
              <Select
                inputProps={{
                  "data-testid": "region-select-input",
                }}
                data-testid="region-select"
                value={region}
                onChange={handleChange}
                label="Region to Run Cloud Template"
              >
                {availableRegions?.map((_region) => (
                  <MenuItem value={_region as string} key={`${_region}`}>
                    {_region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ px: 2 }}>
            <Button
              disabled={disabled}
              variant="contained"
              size="medium"
              color="primary"
              disableElevation
              onClick={handleSubmit}
              sx={{
                mt: 2,
                width: "100%",
              }}
            >
              {isLoading ? (
                <CircularProgress size={26} color="primary" />
              ) : (
                "Run Script"
              )}
            </Button>
          </Grid>
        </Grid>

        <Typography sx={{ mt: 2 }} variant="body2">
          If no window opens, please disable pop up blocker.
        </Typography>
      </Box>
    </Box>
  );
};
export default SelectTemplate;
