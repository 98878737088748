/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useRouter from "hooks/use-router";
import { Alert } from "@material-ui/lab";
import { useVerifyCodeMutation } from "generated/graphql";
import { useSnackbar } from "notistack";

interface QueryString {
  email?: string;
  code?: string;
}
interface VerifyFormValues {
  code: string;
}

const VerifyForm: React.FC = () => {
  const { push, query } = useRouter();
  const verifyCode = useVerifyCodeMutation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
  } = useForm<VerifyFormValues>({
    mode: `onChange`,
    defaultValues: {
      code: "",
    },
  });
  const submit: SubmitHandler<VerifyFormValues> = React.useCallback(
    async ({ code }) => {
      try {
        if (verifyCode.isLoading) {
          return;
        }
        const { email } = query as QueryString;
        if (!email) {
          enqueueSnackbar(
            `This page needs the email in the url. You will be redirected to login in 3 seconds`,
            {
              variant: "error",
            }
          );
          setTimeout(() => {
            push("/");
          }, 3000);
          return;
        }
        await verifyCode.mutateAsync({ email, code });

        enqueueSnackbar(
          `Your email was successfully verified, you will be redirected to sign in 3 seconds.`,
          {
            variant: "success",
          }
        );

        setTimeout(() => {
          push("/");
        }, 3000);
      } catch (e) {
        //
      }
    },

    [enqueueSnackbar, push, query, verifyCode]
  );

  const onSubmitHandler = handleSubmit(submit);
  React.useEffect(() => {
    const code = (query as QueryString)?.code;
    if (code) {
      setValue("code", code);
      submit({ code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  watch("code"); // Trigger render when the code changes
  const isLoading = verifyCode.isLoading || verifyCode.isSuccess;
  const isDisabled = !formState.isValid || isLoading;
  return (
    <Box
      component="form"
      onSubmit={onSubmitHandler}
      sx={{
        height: "100%",
        position: "relative",
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography
        variant="h5"
        sx={{
          maxWidth: 400,
        }}
      >
        Check your inbox for verification code and enter below.
      </Typography>
      <TextField
        inputRef={register({
          required: `You must provide a code`,
        })}
        inputProps={{ required: true }}
        variant="outlined"
        margin="normal"
        fullWidth
        id="verify-code-input"
        label="Enter Code"
        name="code"
        type="number"
        autoFocus
        onFocus={() => {
          verifyCode?.reset();
        }}
        sx={{
          maxWidth: 300,
        }}
      />
      <Box sx={{ position: "relative" }}>
        {verifyCode.isError && (
          <Grid item xs={12} lg={6} sx={{ position: "absolute" }}>
            {verifyCode?.error?.graphqlErrors?.map((error, index) => {
              return (
                <Alert severity="error" key={`${error.message}-${index}`}>
                  {error.message}
                </Alert>
              );
            })}
          </Grid>
        )}
      </Box>
      <Button
        disabled={isDisabled}
        type="submit"
        variant="contained"
        color="primary"
        sx={{
          px: 4,
          position: [, "absolute"],
          right: [, 20],
          bottom: [, 20],
          minWidth: ["100%", 250],
          mt: [16, 0],
        }}
      >
        {isLoading ? (
          <CircularProgress size={26} color="primary" />
        ) : (
          "Connect to your cloud"
        )}
      </Button>
    </Box>
  );
};
VerifyForm.displayName = "VerifyForm";
export default VerifyForm;
