/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { EmailTo, Subject, useSendEmailMutation } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";

export type RenderButtonProps = {
  onClick: () => void;
  success: boolean;
};

export type RenderButton = React.FC<RenderButtonProps>;

type ContactModalProps = {
  renderButton: RenderButton;
};

interface FormValues {
  name: string;
  email: string;
  phone: string;
  company: string;
  subject: Subject | "";
  message: string;
}

const subjectOptions = [
  {
    name: "Information",
    value: Subject.Information,
    defaultMessage: "",
    toEmail: "codiagnostic@virtasant.com",
  },
  {
    name: "Technical Support",
    value: Subject.TechSupport,
    defaultMessage: `*** What step in the process are you having trouble?


*** Please provide your AWS account number.


*** What is the AWS region?


*** Please describe the issue.

`,
  },
  { name: "Sign up for CO", value: Subject.SignUp, defaultMessage: "" },
];

const ContactModal: React.FC<ContactModalProps> = ({
  renderButton: RenderButton,
}) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const sendEmail = useSendEmailMutation();
  const { user } = useAuth();
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: user?.name || "",
      email: user?.email || "",
      company: user?.company?.name || "",
      message: "Diagnostics Dashboard Message",
      phone: "",
    },
  });
  const onSubmit = handleSubmit(async (values) => {
    await sendEmail.mutateAsync({
      message: {
        body: JSON.stringify(values),
        toEmail: EmailTo.Sales,
        phoneNumber: values.phone,
        subject: Subject.Information,
      },
    });
    enqueueSnackbar(
      "Thank you for contacting us, our team will get in touch with you as soon as possible.",
      {
        variant: "success",
      }
    );
    closeModal();
  });
  const { isLoading } = sendEmail;
  const isSendButtonDisabled = isLoading;
  return (
    <>
      <RenderButton onClick={openModal} success={sendEmail.isSuccess} />
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        maxWidth="sm"
        sx={{ ".MuiDialog-paperWidthSm": { width: "600px" } }}
      >
        {" "}
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
              pb: 2,
              px: 2,
              maxWidth: 600,
            }}
          >
            <IconButton onClick={closeModal} sx={{ ml: "auto" }}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" sx={{ color: "#EF7B2E" }}>
              Want to see all of your savings and how to automatically optimize
              your cloud?
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ overflow: "auto", maxWidth: 600 }}>
          <Box component="form" onSubmit={onSubmit} sx={{ px: 2, pb: 3 }}>
            <Typography variant="body1" sx={{ my: 2 }}>
              Complete the form below and a member of our team will contact you
              shortly.
            </Typography>
            <TextField
              id="get-started-form-name"
              name="name"
              label="Full Name"
              variant="outlined"
              margin="normal"
              fullWidth
              inputProps={{ required: true }}
              inputRef={register({ required: true })}
            />
            <TextField
              id="get-started-form-email"
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              margin="normal"
              fullWidth
              inputProps={{ required: true }}
              inputRef={register({ required: true })}
            />
            <TextField
              id="get-started-form-phone"
              name="phone"
              label="Phone"
              type="tel"
              variant="outlined"
              margin="normal"
              fullWidth
              inputRef={register()}
            />
            <TextField
              id="get-started-form-company"
              name="company"
              label="Company"
              variant="outlined"
              margin="normal"
              fullWidth
              inputRef={register()}
            />

            <Box sx={{ mt: 4 }}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSendButtonDisabled}
                size="large"
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Please Contact Me"
                )}
              </Button>
              <Button
                color="primary"
                variant="text"
                sx={{ ml: 3, width: 90 }}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
ContactModal.displayName = "ContactModal";
export default ContactModal;
