/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  capitalize,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { GridCellParams, GridColDef } from "@material-ui/x-grid";
import Banner from "components/dashboard/Banner";
import TableGrid from "components/general/TableGrid";
import Title from "components/general/Title";
import { format } from "date-fns";
import numbro from "numbro";
import * as React from "react";

const generateNumberColumns = (): Array<GridColDef> => {
  return [
    "savings",
    "pending",
    "scheduled",
    "in_progress",
    "executed",
    "rejected",
    "failed",
  ].map((column) => ({
    field: column,
    renderHeader: () => (
      <Box sx={{ textAlign: "end", width: "100%" }}>
        <Typography variant="body1">
          {capitalize(column.replace("_", " "))}
        </Typography>
      </Box>
    ),
    flex: 1,
    align: "right",
    renderCell: (params: GridCellParams) => (
      <Box sx={{ textAlign: "end", width: "100%" }}>
        <Typography variant="body1" color="primary">
          {numbro(params.value).formatCurrency({
            thousandSeparated: true,
            mantissa: 2,
          })}
        </Typography>
      </Box>
    ),
    headerClassName: "header",
  }));
};
const lastUpdateTime = new Date();
const bannerItems = [
  { label: "Number of Clients", value: 13 },
  {
    label: "Available Savings Opportunities",
    value: 448345678.9,
    variant: "money",
  },
  {
    label: "Executed Savings Opportunities",
    value: 18345678.9,
    variant: "money",
  },
  {
    label: "Failed Opportunities",
    value: 45678,
  },
];
const clientRows = [
  {
    id: 1,
    clientName: "Company ABC",
    savings: 12963685.99,
    pending: 38000.9,
    scheduled: 45,
    in_progress: 123,
    executed: 45,
    rejected: 0,
    failed: 0,
  },
  {
    id: 2,
    clientName: "Company DEF",
    savings: 12963685.99,
    pending: 38000.9,
    scheduled: 45,
    in_progress: 123,
    executed: 45,
    rejected: 0,
    failed: 0,
  },
];
const clientColumns: GridColDef[] = [
  {
    field: "clientName",
    headerName: "Client",
    flex: 1,
    renderCell: (params: GridCellParams) => (
      <Typography variant="body1" color="primary">
        {params.value}
      </Typography>
    ),
    headerClassName: "header",
  },
  ...generateNumberColumns(),
];
const ClientManagementPage = () => {
  const onPaginate = (page: number) => null;

  const [byIssue, setByIssue] = React.useState<boolean>(false);
  const updateDate = format(lastUpdateTime, "MM/dd/yyyy");
  const updateTime = format(lastUpdateTime, "hh:mm aaa");
  const handleByIssueSwitch = () => setByIssue(!byIssue);
  return (
    <Box sx={{ mb: 20, pt: [60, 0] }}>
      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        sx={{ mb: 4 }}
      >
        <Grid item>
          <Title sx={{ mb: 3, display: "inline-flex", mr: 4 }}>
            Client Management
          </Title>
          <Typography
            sx={{
              fontSize: 12,
              letterSpacing: "0.15px",
              display: "inline-flex",
            }}
          >
            Last updated on {updateDate} at {updateTime}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary">
            Add New Client
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Banner items={bannerItems} breakpoints={{ xs: 12, sm: 6, md: 3 }} />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }} justify="space-between" spacing={3}>
        <Grid item xs={3}>
          <TextField variant="outlined" label="Search for Company" fullWidth />
        </Grid>
        <Grid item>
          <Typography sx={{ mr: 3, display: "inline-flex" }}>
            By Cost
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={byIssue}
                onChange={handleByIssueSwitch}
                name="byIssue"
              />
            }
            sx={{ display: "inline-flex" }}
            label="By Issue"
          />{" "}
        </Grid>
      </Grid>
      <TableGrid
        columns={clientColumns}
        data={clientRows}
        loading={false}
        currentPage={0}
        totalPages={2}
        onPaginate={onPaginate}
        sx={{ mt: 4 }}
      />
    </Box>
  );
};
ClientManagementPage.displayName = "ClientManagement Page";
export default ClientManagementPage;
