import numbro from "numbro";

export function roundToTheHundredth(num: number) {
  return Math.round(num * 100) / 100;
}

export function nbOr0(numOrZero: unknown): number {
  if (typeof numOrZero === "number") {
    return numOrZero;
  }
  return 0;
}

export function nbToPercent(num: number): string {
  return `${numbro(num * 100).format({
    mantissa: 2,
    optionalMantissa: true,
  })}%`;
}
