/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import numbro from "numbro";
import * as React from "react";

export type TopTicket = {
  title: string;
  description?: string;
  resourceID: string;
  region: string;
  opportunity: number;
};

type Props = {
  className?: string;
  tickets: TopTicket[];
  opCount: number;
};

const Top3Tickets: React.FC<Props> = ({ className, tickets, opCount }) => {
  let mainTitle: string;
  if (tickets.length === 1) {
    mainTitle = "Here is one savings opportunity";
  } else if (opCount > tickets.length) {
    mainTitle = `Here are ${tickets.length} of the ${opCount} savings opportunities`;
  } else {
    mainTitle = `Here are ${tickets.length} savings opportunities`;
  }
  mainTitle += " that we identified through our automated diagnostic";
  return (
    <Box className={className}>
      <Typography
        variant="h2"
        sx={{
          color: "#414D56",
          mb: [20, 55],
          fontSize: [28, 42],
          textAlign: "center",
        }}
      >
        {mainTitle}
      </Typography>
      <Box>
        {tickets.map(
          ({ title, description, resourceID, region, opportunity }, index) => {
            return (
              <Grid
                key={title + index.toString()}
                container
                sx={{
                  mb: 67,
                  flexWrap: "nowrap",
                  width: "100%",
                  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)",
                  py: 24,
                  flexDirection: ["column", "row"],
                }}
              >
                <Grid
                  item
                  sx={{
                    px: 30,
                    flexBasis: [, 380],
                    borderRight: [, "1px dashed #BCC0C3"],
                    borderBottom: ["1px dashed #BCC0C3", "none"],
                    pb: [24, 0],
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: 600,
                      fontSize: 28,
                      lineHeight: "42px",
                      color: "#414D56",
                    }}
                  >
                    {title}
                  </Typography>
                  {description && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        lineHeight: "26px",
                        color: "#727272",
                        mt: 3,
                      }}
                    >
                      {description}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  sx={{ flex: 1, flexDirection: ["column", "row"] }}
                >
                  <Grid item xs={12} sm={6} sx={{ px: 30, py: [24, 0] }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "32px",
                        color: "#8F9193",
                      }}
                    >
                      Resource ID
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: "22px",
                        color: "#414D56",
                        wordWrap: "break-word",
                      }}
                    >
                      {resourceID}
                    </Typography>

                    <Typography
                      variant="h4"
                      sx={{
                        mt: 35,
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "32px",
                        color: "#8F9193",
                      }}
                    >
                      Region
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: "22px",
                        color: "#414D56",
                      }}
                    >
                      {region}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ px: 30 }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "32px",
                        color: "#8F9193",
                      }}
                    >
                      Opportunity
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 42,
                        fontWeight: 600,
                        lineHeight: "48px",
                        color: "#4fb563",
                      }}
                    >
                      {numbro(opportunity).formatCurrency({
                        thousandSeparated: true,
                        mantissa: 2,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        )}
      </Box>
    </Box>
  );
};
Top3Tickets.displayName = "Top3Tickets";
export default Top3Tickets;
