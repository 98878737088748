/** @jsxImportSource theme-ui */
import * as React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DefaultLegendContent } from "recharts/lib/component/DefaultLegendContent";
import numbro from "numbro";

const renderLegendText = (value: string) => {
  return (
    <span
      key={value}
      sx={{
        color: "#414D56",
        fontWeight: 500,
        ml: "3px",
        mr: 15,
        fontFamily: "Montserrat",
      }}
    >
      {value}
    </span>
  );
};

export type BarChartData = Array<{
  name: string;
  a: number;
  b: number;
}>;

const formatCurrency = (value: number) => {
  return numbro(value).formatCurrency({
    average: true,
    spaceSeparated: true,
  });
};

type Props = {
  className?: string;
  data: BarChartData;
};

const BarChartComponent: React.FC<Props> = ({ data, className }) => {
  return (
    <ResponsiveContainer
      className={className}
      width="100%"
      height={200 + data.length * 20}
      sx={{
        ".recharts-cartesian-grid-horizontal line": {
          display: "none",
        },
        ".recharts-cartesian-grid-horizontal line:nth-last-of-type(2)": {
          display: "block",
        },
      }}
    >
      <BarChart
        barSize={35}
        layout="vertical"
        data={data}
        margin={{
          top: 35,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 6" stroke="#414D56" />
        <XAxis
          axisLine={{ stroke: "#C6C6C6" }}
          tick={{ fill: "#414D56" }}
          tickLine={false}
          tickMargin={10}
          tickFormatter={(value: number) =>
            numbro(value).formatCurrency({
              average: true,
              spaceSeparated: true,
            })
          }
          type="number"
        />
        <YAxis
          axisLine={{ stroke: "#C6C6C6" }}
          type="category"
          dataKey="name"
          tick={{ fill: "#414D56" }}
          tickMargin={10}
          tickLine={false}
          width={170}
        />
        <Tooltip
          formatter={(
            value: number,
            name: string,
            props: { dataKey: string; payload: { a: number; b: number } }
          ) => {
            if (props.dataKey === "b") {
              // Display the total of the two bars
              return formatCurrency(value + props.payload.a);
            }
            return formatCurrency(value);
          }}
        />
        <Legend
          formatter={renderLegendText}
          verticalAlign="top"
          wrapperStyle={{ top: 15 }}
          content={(props) => {
            const payload = props.payload?.slice().reverse();
            return <DefaultLegendContent {...props} payload={payload} />;
          }}
        />
        <Bar
          dataKey="a"
          fill="#4FB563"
          name="Your Annualized Spend After Optimization"
          stackId="a"
        />
        <Bar
          dataKey="b"
          fill="#F8D0C9"
          name="Current Annualized Spend"
          stackId="a"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(BarChartComponent);
