import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import env from "utils/env";

export const fetchTemplate = () => {
  return axios
    .get(env("REACT_APP_AWS_SCRIPT_BUCKET"))
    .then(async (response: AxiosResponse) => {
      return JSON.stringify(response.data, null, 2);
    })
    .catch((error) => error.message);
};

export const useTemplateQuery = () =>
  useQuery<string, string>("script", fetchTemplate);
