import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import AppRouter from "components/router/Router";
import { AuthProvider } from "hooks/use-auth";
import NotificationsProvider from "providers/NotificationsProvider";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider as ThemeUiProvider } from "theme-ui";
import { LicenseInfo } from "@material-ui/x-grid";
import env from "utils/env";
import theme from "./theme";
import themeUiTheme from "./themeUiTheme";

LicenseInfo.setLicenseKey(env("REACT_APP_MUI_XGRID_LICENSE"));

const queryClient = new QueryClient();
const App: React.FC = () => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ThemeUiProvider theme={themeUiTheme}>
          <NotificationsProvider>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <CssBaseline />
                <AppRouter />
              </AuthProvider>

              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </NotificationsProvider>
        </ThemeUiProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};
App.displayName = "App";
export default App;
