/** @jsxImportSource theme-ui */

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import React from "react";
import ReactHookFormSelect from "../general/ReactHookFormSelect";

const roles = [
  {
    id: "ro-1",
    name: "User",
  },
  {
    id: "ro-2",
    name: "Administrator",
  },
];

interface FormValues {
  name: string;
  businessUnit: string;
  role: string;
  email: string;
  password: string;
  sendNotificationEmail: boolean;
}
interface Props {
  // Should be company fragment but it doesn't has required props
  businessUnitOptions: Array<{
    id: string;
    name: string;
  }>;
  closeModal: () => void;
}
const UserCreationForm: React.FC<Props> = ({
  businessUnitOptions,
  closeModal,
}) => {
  const { register, handleSubmit, reset, control } = useForm<FormValues>({
    defaultValues: {
      name: "",
      businessUnit: "",
      role: "",
      email: "",
      password: "",
      sendNotificationEmail: false,
    },
  });

  const commonSubmit = async (_values: FormValues) => {
    // call mutation
  };
  const onSubmit = handleSubmit(async (values) => {
    await commonSubmit(values);
    closeModal();
  });
  const handleSaveAndCreate = handleSubmit(async (values) => {
    await commonSubmit(values);
    reset();
  });
  return (
    <Box component="form" onSubmit={onSubmit} sx={{ pb: 3, maxWidth: 550 }}>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <TextField
            id="user-form-first-name"
            name="name"
            label="Name"
            variant="outlined"
            margin="none"
            fullWidth
            inputProps={{ required: true }}
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item sm={6}>
          <ReactHookFormSelect
            control={control}
            defaultValue=""
            id="user-form-bu"
            name="businessUnit"
            fullWidth
            variant="outlined"
            label="Business Unit"
            data-testid="user-form-bu"
            required
            inputProps={{ required: true }}
          >
            {businessUnitOptions.map(({ name, id }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
        <Grid item sm={6}>
          <ReactHookFormSelect
            control={control}
            defaultValue=""
            id="user-form-role"
            data-testid="user-form-role"
            name="role"
            fullWidth
            variant="outlined"
            label="Role"
            required
            inputProps={{ required: true }}
          >
            {roles.map(({ name, id }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="user-form-email"
            name="email"
            label="Email"
            variant="outlined"
            margin="none"
            fullWidth
            inputProps={{ required: true }}
            inputRef={register({ required: true })}
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="user-form-password"
            name="password"
            label="Password"
            variant="outlined"
            margin="none"
            fullWidth
            inputProps={{ required: true }}
            inputRef={register({ required: true })}
            type="password"
          />
          <Typography
            sx={{ fontSize: 14, lineHeight: "24px", color: "#525252", mt: 2 }}
          >
            If you do not enter a password, one will be generated automatically
          </Typography>
        </Grid>
        <Grid item sx={{ mt: -2 }}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                size="small"
                name="sendNotificationEmail"
                color="primary"
                inputRef={register}
              />
            }
            label={
              <Box sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    letterSpacing: "0.15px",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  Send notification email
                </Typography>
              </Box>
            }
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 4, mx: -3 }} />
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            Add User
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={handleSaveAndCreate}
          >
            Save and Create Another
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="text"
            sx={{ width: 90 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

UserCreationForm.displayName = "UserCreationForm";
export default UserCreationForm;
