/** @jsxImportSource theme-ui */
import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Box, Icon } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { jsx } from "theme-ui";

interface Props {
  errors?: Array<{ message: string }>;
}
const ServerError: React.FC<Props> = ({ errors }) => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon color="error" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            display: "flex",
            textAlign: "left",
            backgroundColor: "errorBackground",
            color: "linear-gradient(0deg, #F44336, #F44336)",
          }}
        >
          <Icon component={ErrorOutlineIcon} color="error" sx={{ mr: 2 }} />
          <Typography sx={{ color: "#F44336" }}>
            Server Error Occurred
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "errorBackground" }}>
          <Box sx={{ maxHeight: "100px", overflowY: "auto" }}>
            {!!errors &&
              errors?.map((err, index) => (
                <Box key={`${err.message}-${index}`}>
                  <Typography color="error">{err.message}</Typography>
                </Box>
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

ServerError.displayName = "Server Error";
export default ServerError;
