import React from "react";
import { Grid } from "@material-ui/core";
import NavBar from "components/navigation/NavBar";

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <Grid container direction="column" item xs={12}>
      <NavBar />
      {children}
    </Grid>
  );
};
export default DefaultLayout;
