export interface InputValues {
  confirm: string;
  company: string;
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface InputField {
  defaultValue: string;
  props: {
    id: keyof InputValues;
    label: string;
    type?: string;
    validateAgainst?: keyof InputValues;
    validateAgainstMessage?: string;
  };
}

const fields: Array<InputField> = [
  {
    defaultValue: ``,
    props: {
      label: "Email",
      id: "email",
    },
  },
  {
    defaultValue: ``,
    props: {
      label: "Password",
      id: "password",
      type: "password",
    },
  },
];
export default fields;
