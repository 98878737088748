import { useQuery } from "react-query";
import { useFetchData } from "hooks/use-fetch-data";
import { ResponseError } from "./errors";

export interface DashboardResponse {
  opCountStatus: StatusItem[];
  aggregators: AggregatedItem[];
}
const DashboardDocument = `
      query Dashboard($page: Number!) {
        dashboard(page: $page) {
          aggregators {
            label
            value
            variant
          }
          opCountStatus {
            status
            amount
            count
            ratio
          }
        }
      }
    `;

export const useDashboardQuery = <T, K>(variables?: T, options?: K) =>
  useQuery<DashboardResponse, ResponseError, DashboardResponse>(
    ["DashboardQuery", variables],
    useFetchData<DashboardResponse, T>(DashboardDocument).bind(null, variables),
    options
  );
