// Hook (use-require-auth.js)
import { useEffect } from "react";
import env from "utils/env";
import { getNextOnboardingPath } from "utils/get-next-onboarding-path";
import { useAuth } from "./use-auth";
import useRouter from "./use-router";

const useRequireAuth = () => {
  const auth = useAuth();
  const { push, location } = useRouter();

  useEffect(() => {
    const nextPath = getNextOnboardingPath(location.pathname, auth.user);
    if (nextPath) {
      if (env("NODE_ENV") === "development") {
        // eslint-disable-next-line no-console
        console.log(`redirect to ${nextPath}`, {
          path: location.pathname,
          user: auth.user,
        });
      }

      push(nextPath);
    }
  }, [auth.user, location.pathname, push]);

  return auth;
};
export default useRequireAuth;
