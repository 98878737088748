/** @jsxImportSource theme-ui */
import * as React from "react";

import { Box, Typography } from "@material-ui/core";
import { XGrid, GridRowsProp, GridColDef } from "@material-ui/x-grid";
import CustomPagination from "components/general/CustomPagination";
import { FilterList } from "@material-ui/icons";
import ColumnMenu from "components/general/ColumnMenu";

interface Props {
  className?: string;
  data?: Array<any>;
  loading?: boolean;
  currentPage: number;
  totalPages: number;
  onPaginate: (page: number) => void;
  columns: GridColDef[];
  hasItemCount?: boolean;
}

const TableGrid: React.FC<Props> = ({
  className,
  loading,
  data,
  columns,
  currentPage,
  totalPages,
  onPaginate,
  hasItemCount,
}) => {
  const Footer = () => (
    <Box
      sx={{
        py: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {hasItemCount ? (
        <Typography sx={{ ml: 1, fontSize: 14 }}>
          {`Displaying ${data?.length} to ${data?.length} of ${data?.length}`}
        </Typography>
      ) : (
        <div />
      )}
      <CustomPagination
        onPaginate={(event, newValue) => onPaginate(newValue)}
        page={currentPage}
        total={totalPages}
      />
    </Box>
  );

  return (
    <Box className={className} sx={{ width: "100%", display: "flex" }}>
      <Box sx={{ flexGrow: 1 }}>
        <XGrid
          columnBuffer={columns.length}
          pagination
          paginationMode="server"
          rows={(data as unknown) as GridRowsProp[]}
          loading={loading}
          columns={columns}
          disableSelectionOnClick
          autoHeight
          sx={{
            px: 3,
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-colCellSorted": {
              color: "#1B70BB !important",
              ".MuiDataGrid-iconButtonContainer > div > button": {
                color: "#1B70BB !important",
              },
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#E1F2F9 !important",
            },
            ".MuiBadge-badge": {
              marginTop: "6px",
              height: "14px",
              width: "14px",
              fontSize: "10px",
            },

            ".MuiBadge-root > button": {
              color: "#1B70BB !important",
            },
            "& .MuiBadge-root > span": {
              color: "white",
              backgroundColor: "#DF4532",
            },

            "& .header": {
              fontFamily: "Montserrat",
              color: "#8A8A8A",
              fontSize: "16px",
            },
            "& .cell": {
              fontFamily: "Montserrat",
              color: "#333333",
              fontSize: "16px",
              lineHeight: "22.88px",
            },
          }}
          components={{
            Footer,
            ColumnMenu,
            ColumnFilteredIcon: () => <FilterList fontSize="default" />,
          }}
        />
      </Box>
    </Box>
  );
};
TableGrid.displayName = "TableGrid";
export default TableGrid;
