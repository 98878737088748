/** @jsxImportSource theme-ui */

import React from "react";

import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";

export interface HeroProps {
  className?: string;
  title: string;
  subtitle: string;
}
const Hero: React.FC<HeroProps> = ({ className, title, subtitle }) => {
  return (
    <Container maxWidth="sm" className={className}>
      <Typography component="h1" variant="h2" sx={{ color: "#B5DEEF" }}>
        {title}
      </Typography>
      <Typography sx={{ mt: 3, color: "#FFFFFF", lineHeight: "32px" }}>
        {subtitle}
      </Typography>
    </Container>
  );
};
Hero.displayName = "Hero";
export default Hero;
