/** @jsxImportSource theme-ui */
import { Box, Button, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as React from "react";

export interface ForgotPasswordCodeProps {
  className?: string;
  code: string;
  error?: string;
  handleGoBack: () => void;
  handleResend: () => void;
  handleSubmit: () => void;
  isResendDisabled: boolean;
  onChangeCode: (code: string) => void;
}

const ForgotPasswordCode: React.FC<ForgotPasswordCodeProps> = ({
  className,
  code,
  handleGoBack,
  handleResend,
  handleSubmit,
  isResendDisabled,
  error,
  onChangeCode,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;
    onChangeCode(value);
  };
  const isSubmitDisabled = !code;
  return (
    <Grid item sm={10}>
      <Box className={className}>
        <Button
          sx={{ ml: -2 }}
          variant="text"
          color="primary"
          onClick={handleGoBack}
          startIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                fill="#1B70BB"
              />
            </svg>
          }
        >
          Back to ‘Forgot Password?’
        </Button>
        <Typography variant="h4" sx={{ color: "#EF7B2E" }}>
          Authentication required
        </Typography>
        <Typography sx={{ mt: 3, lineHeight: "24px" }}>
          Please enter your 6 digit code to validate. This can be found in your
          inbox. <i>(Note: Check your spam folder in your inbox)</i>
        </Typography>
        <Typography sx={{ mt: 3, lineHeight: "24px" }}>
          Click ‘Resend Code’ if you do not have a 6 digit code.
        </Typography>
        <Box sx={{ maxWidth: 300 }}>
          <TextField
            id="forgot-password-form-code"
            name="code"
            label="Enter Code"
            type="number"
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{ required: true }}
            value={code}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Submit Code
          </Button>
          <Button
            variant="text"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleResend}
            disabled={isResendDisabled}
          >
            Resend Code
          </Button>
          {error && (
            <Box sx={{ backgroundColor: "errorBackground", p: 3, mt: 3 }}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ForgotPasswordCode;
