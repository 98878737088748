/** @jsxImportSource theme-ui */
import * as React from "react";
import { useSnackbar } from "notistack";
import {
  useChangePasswordMutation,
  useForgotPasswordMutation,
} from "generated/graphql";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import ForgotPasswordCode from "./ForgotPasswordCode";
import ForgotPasswordNewPassword from "./ForgotPasswordNewPassword";
import useRouter from "../../hooks/use-router";

enum Views {
  "ForgotPasswordEmail" = "ForgotPasswordEmail",
  "ForgotPasswordCode" = "ForgotPasswordCode",
  "ForgotPasswordNewPassword" = "ForgotPasswordNewPassword",
}

interface ForgotPasswordWrapperProps {
  onGoBack: () => void;
}
const ForgotPasswordWrapper: React.FC<ForgotPasswordWrapperProps> = ({
  onGoBack,
}) => {
  const { query } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const forgotPassword = useForgotPasswordMutation();
  const changePassword = useChangePasswordMutation();
  const [currentView, setCurrentView] = React.useState<Views>(
    query?.code ? Views.ForgotPasswordNewPassword : Views.ForgotPasswordEmail
  );
  const [email, setEmail] = React.useState<string>(
    (query?.email as string) || ""
  );
  const [code, setCode] = React.useState<string>((query?.code as string) || "");
  const [password, setPassword] = React.useState("");
  const [isResendDisabled, setIsResendDisabled] = React.useState(false);
  const sendCode = async () => {
    try {
      await forgotPassword.mutateAsync({ email });
      setCurrentView(Views.ForgotPasswordCode);
    } catch {}
  };

  const resendCode = async () => {
    try {
      await forgotPassword.mutateAsync({ email });
      setIsResendDisabled(true);
      enqueueSnackbar("A new code has been sent to your email", {
        variant: "success",
      });
    } catch {}
  };

  const handleNewPasswordSubmit = async () => {
    try {
      await changePassword.mutateAsync({
        code,
        email,
        password,
      });
      enqueueSnackbar("You successfully changed your password", {
        variant: "success",
      });
      onGoBack();
    } catch {}
  };
  return (
    <>
      {currentView === Views.ForgotPasswordEmail && (
        <ForgotPasswordEmail
          email={email}
          handleGoBack={onGoBack}
          handleGoNext={() => setCurrentView(Views.ForgotPasswordCode)}
          onChangeEmail={setEmail}
          handleSubmit={sendCode}
          isLoading={forgotPassword.isLoading}
          error={forgotPassword?.error?.message}
        />
      )}
      {currentView === Views.ForgotPasswordCode && (
        <ForgotPasswordCode
          code={code}
          handleGoBack={() => setCurrentView(Views.ForgotPasswordEmail)}
          handleSubmit={() => setCurrentView(Views.ForgotPasswordNewPassword)}
          handleResend={resendCode}
          isResendDisabled={isResendDisabled}
          onChangeCode={setCode}
          error={forgotPassword?.error?.message}
        />
      )}
      {currentView === Views.ForgotPasswordNewPassword && (
        <ForgotPasswordNewPassword
          error={changePassword.error?.message}
          handleChangePassword={setPassword}
          handleGoBack={() => setCurrentView(Views.ForgotPasswordCode)}
          handleSubmit={handleNewPasswordSubmit}
          isLoading={changePassword.isLoading}
          password={password}
        />
      )}
    </>
  );
};
ForgotPasswordWrapper.displayName = `ForgotPasswordWrapper`;
export default ForgotPasswordWrapper;
