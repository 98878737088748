/** @jsxImportSource theme-ui */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import numbro from "numbro";
import * as React from "react";
import DashboardCard from "./DashboardCard";

export interface DollarByAssigneeItem {
  awsAccountId: string;
  costSavings: number;
  issueCount: number;
}

interface Props {
  data?: Array<DollarByAssigneeItem>;
}
const OpportunitiesByAccountTable: React.FC<Props> = ({ data }) => {
  const tdBodyStyle = {
    fontFamily: "Montserrat",

    fontSize: "16px",
    lineHeight: "22.88px",
    letterSpacing: "0.15px",
    color: "#333",
  };
  const headerTextStyle = {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    color: "#636C74",
  };

  return (
    <DashboardCard title="Opportunities by Account" sx={{ width: "100%" }}>
      <TableContainer
        sx={{
          px: 3,
        }}
      >
        <Table aria-label="Dollars per assignee">
          <TableHead>
            <TableRow sx={{ th: { py: 24 } }}>
              <TableCell
                sx={{
                  width: "20%",
                }}
              >
                <Typography sx={headerTextStyle}>AWS Account ID</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={headerTextStyle}>Cost Savings</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={headerTextStyle}>Issue Count</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index} sx={{ td: { py: 24 } }}>
                <TableCell>
                  <Typography sx={tdBodyStyle}>{row.awsAccountId}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={tdBodyStyle}>
                    {numbro(row.costSavings).formatCurrency({
                      thousandSeparated: true,
                      mantissa: 2,
                    })}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={tdBodyStyle}>
                    {numbro(row.issueCount).format({
                      thousandSeparated: true,
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </DashboardCard>
  );
};
OpportunitiesByAccountTable.displayName = "OpportunitiesByAccountTable";
export default OpportunitiesByAccountTable;
