import { authPaths } from "components/router/routes";
import { ReactComponent as IconAwsArn } from "assets/icons/icon-aws-arn.svg";
import { ReactComponent as IconAwsProcessing } from "assets/icons/icon-aws-processing.svg";
import { ReactComponent as IconConnectAws } from "assets/icons/icon-connect-aws.svg";
import { ReactComponent as IconEmailVerify } from "assets/icons/icon-email-verify.svg";
import { ReactComponent as IconSelectRegions } from "assets/icons/icon-select-regions.svg";

export const onboardingRoutes = [
  {
    path: authPaths.VERIFY,
    icon: IconEmailVerify,
  },
  {
    path: authPaths.CONNECT,
    icon: IconConnectAws,
  },
  {
    path: authPaths.VERIFY_AWS_SETUP,
    icon: IconAwsArn,
  },
  {
    path: authPaths.CHOOSE_REGIONS,
    icon: IconSelectRegions,
  },
  {
    path: authPaths.AWS_RESULTS_PROCESSING,
    icon: IconAwsProcessing,
  },
];
