/** @jsxImportSource theme-ui */
import { Button, Grid, Snackbar, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useInterval from "@use-it/interval";
import addMinutes from "date-fns/addMinutes";
import compareAsc from "date-fns/compareAsc";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { useVerifyResendMutation } from "generated/graphql";
import useRouter from "hooks/use-router";
import * as React from "react";

export const TIMEOUT_MINUTES = 5;

interface QueryString {
  email?: string;
}

const VerifySendAgain: React.FC = () => {
  const { query } = useRouter();
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [nextSendAgain, setNextSendAgain] = React.useState<Date | null>(null);
  const [, triggerRender] = React.useState(0);
  const verifyResend = useVerifyResendMutation();
  const handleSendAgain = async () => {
    const now = new Date();
    if (!nextSendAgain || compareAsc(now, nextSendAgain) >= 0) {
      // It is the first time or timeout elapsed
      const { email } = query as QueryString;
      if (!email) {
        throw new Error("Should have email in the address");
      }
      await verifyResend.mutateAsync({
        email,
      });
      setNextSendAgain(addMinutes(now, TIMEOUT_MINUTES));
      setIsAlertOpen(true);
    } else {
      // timeout is not elapsed
      setIsAlertOpen(true);
    }
  };
  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  let secondsLeft = 0;
  if (nextSendAgain) {
    secondsLeft = differenceInSeconds(nextSendAgain, new Date());
  }
  if (secondsLeft < 0) {
    secondsLeft = 0;
  }
  React.useEffect(() => {
    if (secondsLeft === 0) {
      setNextSendAgain(null);
    }
  }, [secondsLeft]);

  useInterval(
    () => triggerRender((prev) => prev + 1),
    isAlertOpen && nextSendAgain ? 1000 : null
  );

  const minutesOnly = Math.floor(secondsLeft / 60);
  const secondsOnly = secondsLeft % 60;

  return (
    <>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleCloseAlert}>
          Email has been sent. You can send again{" "}
          {secondsLeft > 0
            ? `in ${minutesOnly} minutes ${secondsOnly} seconds`
            : "now"}
          .
        </Alert>
      </Snackbar>
      <Typography
        sx={{
          mt: 2,
          fontSize: 14,
          display: "flex",
          alignItems: "baseline",
          flexWrap: "wrap",
        }}
      >
        Can’t find the email?
        <span
          sx={{
            display: "flex",
            alignItems: "baseline",
            flexWrap: "wrap",
            ml: ["-8px", , 0],
          }}
        >
          <Button
            onClick={handleSendAgain}
            color="primary"
            sx={{ fontSize: 14 }}
          >
            Send Again
          </Button>
          {" or "}
          <Button
            href="mailto:codiagnostic@virtasant.com"
            rel="noopener noreferrer"
            target="_blank"
            color="primary"
            sx={{ fontSize: 14 }}
          >
            Contact Support
          </Button>
        </span>
      </Typography>
      {verifyResend?.isError && (
        <Grid item xs={12} lg={6}>
          {verifyResend?.error?.graphqlErrors?.map((error, index) => {
            return (
              <Alert severity="error" key={`${error.message}-${index}`}>
                {error.message}
              </Alert>
            );
          })}
        </Grid>
      )}
    </>
  );
};
VerifySendAgain.displayName = "VerifySendAgain";
export default VerifySendAgain;
