/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Global } from "@emotion/react";
import * as React from "react";
import { OpportunityError, OpportunityStatus } from "./opportunityConstants";
import ProgressStepper from "./ProgressStepper";
import AcceptModal from "./AcceptModal";

type Props = {
  className?: string;
  error: OpportunityError | undefined | null;
  isAdmin: boolean;
  status: OpportunityStatus;
};

const ProgressStepperWrapper: React.FC<Props> = ({
  className,
  error: initialError,
  isAdmin,
  status,
}) => {
  const [currentStep, setCurrentStep] = React.useState<OpportunityStatus>(
    status
  );
  const [error, setError] = React.useState<OpportunityError | undefined | null>(
    initialError
  );
  const [acceptModalOpen, setAcceptModalOpen] = React.useState<boolean>(false);
  const handleAcceptApproval = () => {
    setCurrentStep(OpportunityStatus.Scheduled);
    setAcceptModalOpen(false);
  };

  const isApproveButtonDisabled = !!error;
  let shouldDisplayRevertPendingButton =
    currentStep === OpportunityStatus.Scheduled;
  if (isAdmin && error === OpportunityError.Failed) {
    shouldDisplayRevertPendingButton = true;
  }
  const handleApprove = () => {
    setAcceptModalOpen(true);
  };
  const handleRevertPending = () => {
    setError(undefined);
    setCurrentStep(OpportunityStatus.Pending);
  };

  const moreOptions: string[] = [];
  if (isAdmin) {
    let middleAdminAction = "Reject";
    if (currentStep === OpportunityStatus["In Progress"] && !error) {
      middleAdminAction = "Revert to Pending";
    }
    if (
      error === OpportunityError.Rejected ||
      error === OpportunityError.Canceled
    ) {
      middleAdminAction = `Revert to ${OpportunityStatus[currentStep]}`;
    }
    if (error !== OpportunityError.Canceled) {
      moreOptions.push("Cancel");
    }
    moreOptions.push(...[middleAdminAction, "Manually Execute"]);
  } else {
    // Normal user
    if (error === OpportunityError.Rejected) {
      moreOptions.push(`Revert to ${OpportunityStatus[currentStep]}`);
    }
    if (
      currentStep !== OpportunityStatus["In Progress"] &&
      currentStep !== OpportunityStatus.Executed
    ) {
      moreOptions.push("Manually Execute");
    }
  }
  const isMoreOptionsDisabled =
    currentStep === OpportunityStatus.Executed ||
    (error === OpportunityError.Canceled && !isAdmin);
  const handleMoreOptionsSelected = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const { value } = event.target;
    if (typeof value !== "string") {
      throw new Error("MoreOptionsSelect value should be a string");
    }

    if (value.includes(`Revert to`)) {
      const targetStatus = value.replace("Revert to ", "");
      if (!(targetStatus in OpportunityStatus)) {
        throw new Error(`Unknown status ${targetStatus}`);
      }
      setError(undefined);
      setCurrentStep(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        OpportunityStatus[(targetStatus as unknown) as OpportunityStatus]
      );
    } else {
      switch (value) {
        case "Cancel":
          setError(OpportunityError.Canceled);
          setCurrentStep(OpportunityStatus.Pending);
          break;
        case "Reject":
          setError(OpportunityError.Rejected);
          break;
        case "Manually Execute":
          setCurrentStep(OpportunityStatus["In Progress"]);
          setError(undefined);
          break;
        default:
          throw new Error(`Action not supported ${value}`);
      }
    }
  };
  return (
    <Box className={className}>
      <AcceptModal
        open={acceptModalOpen}
        onClose={() => setAcceptModalOpen(false)}
        onApprove={handleAcceptApproval}
      />
      <Box
        sx={{
          backgroundColor: "#EDF7FB",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          pt: 20,
          borderRadius: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            color: "#1053A4",
            textAlign: "center",
          }}
        >
          Status
        </Typography>
        <ProgressStepper
          currentStep={currentStep}
          error={error}
          sx={{
            backgroundColor: "transparent",
            pt: 16,
            pb: 32,
            px: [50, , 110],
          }}
        />
      </Box>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        {currentStep === OpportunityStatus.Pending && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: 40 }}
              onClick={handleApprove}
              disabled={isApproveButtonDisabled}
            >
              Approve
            </Button>
          </Grid>
        )}
        {shouldDisplayRevertPendingButton && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: 40 }}
              onClick={handleRevertPending}
            >
              Revert Pending
            </Button>
          </Grid>
        )}
        {moreOptions.length > 0 && (
          <Grid item>
            <Global
              styles={() => ({
                ".admin-menu-list .MuiListItem-root:hover": {
                  backgroundColor: "rgba(225, 242, 249, .5)",
                },
              })}
            />
            <Select
              displayEmpty
              disabled={isMoreOptionsDisabled}
              value=""
              onChange={handleMoreOptionsSelected}
              variant="outlined"
              margin="dense"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                MenuListProps: {
                  style: {
                    color: "#1B70BB",
                  },
                  className: "admin-menu-list",
                },
              }}
              sx={{
                color: "#1B70BB",
                ".MuiListItem-button:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              <MenuItem value="" disabled sx={{ display: "none" }}>
                More Options
              </MenuItem>
              {moreOptions.map((actionName) => (
                <MenuItem key={actionName} value={actionName}>
                  {actionName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
ProgressStepperWrapper.displayName = "ProgressStepperWrapper";
export default ProgressStepperWrapper;
