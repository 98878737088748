export enum OpportunityStatus {
  "Pending" = 0,
  "Scheduled" = 1,
  "In Progress" = 2,
  "Executed" = 3,
}

export enum OpportunityError {
  "Rejected" = "Rejected",
  "Failed" = "Failed",
  "Canceled" = "Canceled",
}
