/** @jsxImportSource theme-ui */
import { Button } from "@material-ui/core";
import { SnackbarKey, SnackbarProvider } from "notistack";
import * as React from "react";

const NotificationsProvider: React.FC = ({ children }) => {
  const snackProviderRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    snackProviderRef.current?.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={snackProviderRef}
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        flexWrap: "nowrap",
      }}
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 48 48"
          >
            <title>Close</title>
            <g fill="#fff">
              <path d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z" />
            </g>
          </svg>
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
NotificationsProvider.displayName = "NotificationsProvider";
export default NotificationsProvider;
