/** @jsxImportSource theme-ui */
import {
  Box,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { GridColDef } from "@material-ui/x-grid";
import Block from "components/general/Block";
import TableGrid from "components/general/TableGrid";
import * as React from "react";

type Props = {
  className?: string;
  columns: GridColDef[];
  defaultExpanded?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: any[];
  title: string;
};

const AdminBusinessUnitItem: React.FC<Props> = ({
  className,
  defaultExpanded,
  title,
  columns,
  rows,
}) => {
  const onPaginate = (page: number) => null;
  const [byIssue, setByIssue] = React.useState(false);
  const handleByIssueSwitch = () => {
    setByIssue((prev) => !prev);
  };
  return (
    <Block
      defaultExpanded={defaultExpanded}
      title={title}
      accordion
      sx={{ mb: "32px !important" }}
      className={className}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            my: 2,
          }}
        >
          <Typography sx={{ mr: 3 }}>By Cost</Typography>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={byIssue}
                onChange={handleByIssueSwitch}
                name="byIssue"
              />
            }
            label="By Issue"
          />
        </Box>
        <Divider sx={{ mt: 3, mx: 3 }} />
        <TableGrid
          sx={{
            width: "100%",
            ".MuiDataGrid-root": { border: "none", py: 0 },
          }}
          columns={columns}
          data={rows}
          loading={false}
          currentPage={0}
          totalPages={2}
          onPaginate={onPaginate}
        />
      </Box>
    </Block>
  );
};

AdminBusinessUnitItem.displayName = "AdminBusinessUnitItem";
export default AdminBusinessUnitItem;
