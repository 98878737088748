/** @jsxImportSource theme-ui */
import { Box, Button, MenuItem } from "@material-ui/core";
import ReactHookFormSelect from "components/general/ReactHookFormSelect";
import { adminPaths } from "components/router/routes";
import useRouter from "hooks/use-router";
import * as React from "react";
import { useForm } from "react-hook-form";

type Props = {
  className?: string;
};

const businessUnits = [
  { name: "Business Unit One", id: "bu-1" },
  { name: "Business Unit Two", id: "bu-2" },
  { name: "Business Unit Three", id: "bu-3" },
  { name: "Business Unit Four", id: "bu-4" },
];

const accounts = [
  { name: "Account One", id: "acc-1" },
  { name: "Account Two", id: "acc-2" },
  { name: "Account Three", id: "acc-3" },
  { name: "Account Four", id: "acc-4" },
];

interface AccountNavigationFormValues {
  businessUnit: string;
  account: string;
}

const AccountNavigationForm: React.FC<Props> = () => {
  const { push } = useRouter();
  const { control, handleSubmit } = useForm<AccountNavigationFormValues>();
  const onSubmit = handleSubmit(({ businessUnit, account }) => {
    push(`${adminPaths.BUSINESS_UNITS}/${businessUnit}/${account}`);
  });
  return (
    <Box
      onSubmit={onSubmit}
      component="form"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <ReactHookFormSelect
        size="small"
        control={control}
        defaultValue=""
        sx={{ width: 250, mr: 3 }}
        id="account-nav-bu"
        name="businessUnit"
        fullWidth
        variant="outlined"
        label="Choose Bussiness Unit"
        data-testid="account-nav-bu-select"
        required
      >
        {businessUnits.map(({ name, id }) => (
          <MenuItem value={id} key={id}>
            {name}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
      <ReactHookFormSelect
        size="small"
        control={control}
        defaultValue=""
        sx={{ width: 250, mr: 3 }}
        id="account-nav-acc"
        name="account"
        fullWidth
        variant="outlined"
        label="Choose an Account"
        data-testid="account-nav-acc-select"
        required
      >
        {accounts.map(({ name, id }) => (
          <MenuItem value={id} key={id}>
            {name}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
      <Button variant="outlined" color="primary" type="submit">
        Go
      </Button>
    </Box>
  );
};
AccountNavigationForm.displayName = "AccountNavigationForm";
export default AccountNavigationForm;
